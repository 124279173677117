import React from 'react';
import { Flex, View } from '@adobe/react-spectrum';
import {ProgressCircle} from "@react-spectrum/progress";

const Loader = ()=> {
  return (
    <>
      <Flex
        position="fixed"
        top="size-0"
        bottom="size-0"
        left="size-0"
        right="size-0"
        alignContent="center"
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <View right="size-0" left="size-0" top="size-0" bottom="0">
          <View height="size-800" width="size-800" alignSelf="center">
          <ProgressCircle
            aria-label="Loading…"
            size="L"
            isIndeterminate
            value={60} />
          </View>
        </View>
      </Flex>
    </>
  );
};

export default Loader;
