import React from 'react';
import './assets/patchDetails.css';

const HowToApply = ({patch}) => {
  return (patch.status !== 'Applied' && patch.type === 'Optional' && patch.status.indexOf('Version') === -1 &&
    <div><a href="https://devdocs.magento.com/cloud/project/project-patch.html#apply-a-patch-in-a-cloud-environment" target="_blank">How to apply the patch</a></div>
  );
};

const PatchDetails = ({patch, mqpVersion}) => {
  return (patch && <div className="spectrum-Table--quiet">
    <div className="spectrum-Table-body react-spectrum-TableView-body patchDetails" style={{borderBottom: "none", borderLeft: "none", borderRight: "none"}}>
      <div className="spectrum-Table-headCell">Patch Id:</div>
      <div>{patch.id}</div>
      <div className="spectrum-Table-headCell">Origin:</div>
      <div>{patch.origin}</div>
      <div className="spectrum-Table-headCell">Categories:</div>
      <div>{patch.categories.join(', ')}</div>
      <div className="spectrum-Table-headCell">Description:</div>
      <div>{patch.description}</div>
        {patch.article_link && <div><a href={patch.article_link} target="_blank">Steps to reproduce and additional details</a></div>}
      <div className="spectrum-Table-headCell">Status:</div>
      <div>
        {patch.status.indexOf('Version') !== -1 &&
        <div>
          Patch requires <a href="https://support.magento.com/hc/en-us/articles/360047139492" target="_blank">magento/quality-patches</a> v.{mqpVersion.latest} (latest)<br />
          To apply the patch:<br />
          <ol>
            <li><a href="https://devdocs.magento.com/cloud/project/ece-tools-update.html" target="_blank">Update magento/ece-tools with dependencies</a> composer package;</li>
            <li><a href="https://devdocs.magento.com/cloud/project/project-patch.html#apply-a-patch-in-a-cloud-environment" target="_blank">Apply the patch</a>;</li>
          </ol>
        </div>
        }
        {patch.status.indexOf('Version') === -1 && <>{patch.status}</>}
        {patch.status === 'N/A' && <> - the status of the patch cannot be defined due to conflicts.</>}
      </div>
      <HowToApply patch={patch} />
      <div className="spectrum-Table-headCell">Type:</div>
      <div>
        {patch.type}
        {patch.type === 'Custom' && <> - patch from the ./m2-hotfixes directory.</>}
        {patch.type === 'Optional' && <> - patch with quality fix provided by <a href="https://devdocs.magento.com/quality-patches/release-notes.html" target="_blank">QPT</a>. Installation is optional.</>}
        {patch.type === 'Required' && <> - patch with critical fix. Installation can't be skipped.</>}
      </div>
      {patch.details !== '' &&
      <>
        <div className="spectrum-Table-headCell">Details:</div>
        <div dangerouslySetInnerHTML={{
          __html: patch.details.replace(/\n/g, '<br />')
        }}/>
      </>
      }
      {patch.recommendation_reason !== '' &&
      <>
        <div className="spectrum-Table-headCell">Recommendation reason:</div>
        <div dangerouslySetInnerHTML={{
          __html: patch.recommendation_reason
        }}/>
      </>
      }
    </div>
  </div>);
};

export default PatchDetails;
