import React from "react";
import {Flex, View} from "@adobe/react-spectrum";
import {TableView} from "@react/react-spectrum/TableView";
import ListDataSource from "@react/react-spectrum/ListDataSource";
import {
  Text,
  Heading,
  Divider,
  Content,
  DialogTrigger,
  Dialog,
  ActionButton,
  ButtonGroup,
  Button
} from "@adobe/react-spectrum";
import "./assets/generalTable.css";
import styled from "styled-components";
import {GetUiId} from "../../../base/components/jsUtilities";

const Field = styled.div`
  font-family: adobe-clean-light, Times, serif !important;;
  color: #505050;
  font-size: 14px;
  font-weight: 300;
`;

const generalTable = ({item}) => {

  let tableData = [];

  class TableDataSource extends ListDataSource {
    load(sortDescriptor) {
      if (sortDescriptor && Array.isArray(tableData)) {
        tableData.sort(
          (a, b) => (a[sortDescriptor.column.key] > b[sortDescriptor.column.key])
            ? sortDescriptor.direction : -sortDescriptor.direction
        );
      }

      return tableData;
    };
  }

  const renderCell = (column, data) => {
    let dataTypeMultipleColumn = Array.isArray(data) || typeof data === 'object' && data !== null
    let text = dataTypeMultipleColumn ? data[column.key] : data;
    let cssClass = "";

    let minWidth = (text.length * 10) + "px";

    return <View style={{minWidth: minWidth}}>
      <span className={cssClass}> </span>
      <span style={{fontSize: "14px"}}>{text}</span>
    </View>;
  };

  const tableDataSource = new TableDataSource();

  const tableView = item => {
    let data = [];
    let parsedJson = null
    try {
      parsedJson = JSON.parse(item.data);
    } catch (e) {
      console.info(e)
    }
    if (parsedJson) {
      data = Object.values(parsedJson);
    }
    let columns = [];
    tableData = data;
    let columnData = data?.[0];
    let dataTypeMultipleColumn = typeof columnData === 'object' &&
      !Array.isArray(columnData) &&
      columnData !== null
    if (Array.isArray(data) && dataTypeMultipleColumn && data.length > 0) {
      Object.keys(columnData).forEach(column => {
        let text = columnData[column];
        let minWidthV = Math.max((text.length * 8), column.length * 12);
        columns.push({
          title: column,
          key: column,
          minWidth: minWidthV,
          align: "left",
          sortable: true,
          divider: false
        });
      });
    } else if (Array.isArray(data)  && data.length > 0) {
      let text = "Data";
      let minWidthV = Math.max((text.length * 8), columnData.length * 12);
      columns.push({
        title: text,
        key: 0,
        minWidth: minWidthV,
        align: "left",
        sortable: true,
        divider: false
      });
    }

    if (!columns.length) {
      return;
    }

    return <TableView
      columns={columns}
      dataSource={tableDataSource}
      renderCell={renderCell}
      defaultSortDescriptor={{column: columns[0], direction: TableView.SORT_ASCENDING}}
      allowsSelection={false}
      className="general-table"
      rowHeight={14}
      quiet={true}
    />;
  }


  return <>
    <DialogTrigger type="fullscreen">
      <ActionButton data-ui-id={GetUiId(item.title) + "-see-details-dialog"}>See Details</ActionButton>
      {(close) => (
        <Dialog>
          <Heading>{item.title}</Heading>
          <Divider />
          <Content>
            <Text>
              <Flex direction="row" height="size-4600">
                <View width="100%" flexBasis="100%">{tableView(item)}</View>
              </Flex>
            </Text>
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>Close</Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  </>
};
export default generalTable;
