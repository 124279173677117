import {onException} from "../../httpStatusHandler";
import {useStateValue} from "../../index";
import {useState} from "react";
import {setGainsightLoaded} from "../actions";

function until(conditionFunction) {
  const poll = resolve => {
    if (conditionFunction()) {
      resolve();
    } else {
      setTimeout(_ => poll(resolve), 400);
    }
  }

  return new Promise(poll);
}

export const useGainsight = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [{ dashboard }] = useStateValue();

  const request = async (callback) => {
    if (isLoading || state.base.gainsightLoaded) {
      return [state.base.gainsightLoaded, isLoading, request];
    }
    setIsLoading(true);

    until(_ => {
      return window._satellite && window.aptrinsic && dashboard?.user?.emailHash
    })
      .then(() => {
        callback();
        dispatch(setGainsightLoaded({gainsightLoaded: true}));
        setIsLoading(false);
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
  };
  return [state.base.gainsightLoaded, isLoading, request];
};
