import React, {useState, useEffect} from 'react';
import {TableView} from '@react/react-spectrum/TableView';
import ListDataSource from "@react/react-spectrum/ListDataSource";
import {Flex, ProgressCircle, View} from "@adobe/react-spectrum";
import PatchDetails from "./patchDetails";
import {IndexPath} from "@react/collection-view";
import usePatches from "state/patches/hooks/usePatches";
import FilterPatchesButton from './filterPatchesButton';
import './assets/patchesGrid.css';
import useDashboard from "state/dashboard/hooks/useDashboard";

class PatchesDataSource extends ListDataSource {
  items = [];
  initialItems = [];
  async load() {
    if (!this.initialItems.length) {
      this.initialItems = this.items
    }

    return this.items;
  }

  getItems() {
    return this.items;
  }

  searchIndex(patch) {
    if (patch) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id + this.items[i].description === patch.id + patch.description) {
          return i;
        }
      }
    }
    return 0;
  }

  filterPatches(selectedFilters) {
    let filters = [];
    filters['origin'] = [];
    filters['status'] = [];
    filters['categories'] = [];
    filters['is_recommended'] = [];
    selectedFilters.forEach(filter => {
      const f = filter.split("|");
      filters[f[0]].push(f[1]);
    });
    if (!this.initialItems.length) {
      this.initialItems = this.recommendations
    }
    this.items = this.initialItems.filter(function (item) {
      const hasCategories = filters['categories'].filter(x => item.categories.includes(x)).length > 0
        || filters['categories'].includes('all')
        || filters['categories'].length === 0;
      const hasOrigin = filters['origin'].includes(item.origin.toLowerCase())
        || filters['origin'].includes('all')
        || filters['origin'].length === 0;
      const hasStatus = filters['status'].includes(item.status.toLowerCase())
        || filters['status'].includes('all')
        || filters['status'].length === 0;
      const isRecommended = filters['is_recommended'].includes(item.is_recommended)
        || filters['is_recommended'].includes('all')
        || filters['is_recommended'].length === 0;
      return (hasOrigin && hasStatus && hasCategories && isRecommended);
    });
    if (this.setFilteredItems && this.items && this.items.length > 0) {
      this.setFilteredItems({items: this.items, selectedPatch: this.items[0]});
    }
    this.reloadData();
    this.filterCallback(this.items.length);
  }
}

const columns = [
  {
    title: 'Patch Id',
    key: 'id',
    minWidth: 100,
    sortable: true,
    divider: false
  },
  {
    title: 'Description',
    key: 'description',
    minWidth: 500,
    sortable: false,
    divider: false
  },
  {
    title: 'Categories',
    key: 'categories',
    minWidth: 100,
    sortable: true,
    divider: false
  },
  {
    title: 'Status',
    key: 'status',
    minWidth: 100,
    sortable: true,
    divider: false
  },
  {
    title: 'Recommended',
    key: 'is_recommended',
    minWidth: 130,
    sortable: true,
    divider: false
  }
];

const patchesDataSource = new PatchesDataSource();

const PatchesGrid = () => {
  const [patches, isMqpInstalled, mqpVersion, isLoading, request] = usePatches();
  const [filteredItems, setFilteredItems] = useState(null);
  const selectedPatch = !filteredItems ? false : filteredItems.selectedPatch;
  const [patchCount, setPatchCount] = useState(0);
  const [totalPatchCount, setTotalPatchCount] = useState(0);

  const [dashboard,,] = useDashboard();
  useEffect(() => {
    request();
  }, [dashboard.client]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
  }

  patchesDataSource.items = !filteredItems ? patches : filteredItems.items;
  patchesDataSource.selectedPatch = selectedPatch;
  patchesDataSource.setFilteredItems = setFilteredItems;
  patchesDataSource.load().then(results => {
    if (results.length && selectedPatch === false) {
      setTotalPatchCount(results.length);
      patchesDataSource.filterCallback = setPatchCount;
    }
  })

  const renderCell = (column, data)  => {
    let text = typeof data[column.key] !== 'undefined' ? data[column.key] : '';
    let cssStatusClass = "";
    let cssTextClass = "";
    let paddingRight = "rem";
    let maxDescriptionLength = 150;

    if (data['status'].indexOf('Version') !== -1) {
      cssTextClass = "text-disabled"
    }

    if (column.key === "status") {
      if (text === 'Applied') {
        cssStatusClass = "status-applied";
      } else if (text === 'Not applied' || text.indexOf('Version') !== -1) {
        cssStatusClass = "status-not-applied";
      } else if (text === 'N/A') {
        cssStatusClass = "status-not-available";
      }
    } else if (column.key === 'description') {
      paddingRight = "1rem";
      if (text.length > maxDescriptionLength) {
        text = text.slice(0, maxDescriptionLength) + '...';
      }
    } else if (column.key === 'categories') {
      text = text.join(', ');
    }

    return <View paddingRight={paddingRight}>
      <span>
      {cssStatusClass && <span className={cssStatusClass}> </span>}
        <span className={cssTextClass}>{text}</span>
      </span>
    </View>;
  };

  const onCellClick = (patchesDataSource)  => {
    return (column, rowIndex) => {
      setFilteredItems({items: patchesDataSource.getItems(), selectedPatch: patchesDataSource.getItems()[rowIndex]})
    }
  };

  const selectedIndex = patchesDataSource.searchIndex(selectedPatch);

  const tableView = <TableView
    allowsSelection={true}
    allowsMultipleSelection={false}
    className="patches-grid"
    rowHeight={14}
    columns={columns}
    defaultSortDescriptor={{column: columns[0], direction: TableView.SORT_ASCENDING}}
    onCellClick={onCellClick(patchesDataSource)}
    onSortChange={function (sortDescriptor) {
      if (sortDescriptor && sortDescriptor.column) {
        function stableSortingComparator(a, b) {
          if (a[sortDescriptor.column.key] === b[sortDescriptor.column.key]) {
            return 0;
          }

          return (a[sortDescriptor.column.key] > b[sortDescriptor.column.key]) ? sortDescriptor.direction : -sortDescriptor.direction;
        }
        patchesDataSource.items.sort(stableSortingComparator)
      }
      if (patchesDataSource.items && patchesDataSource.items.length) {
        setFilteredItems({items: patchesDataSource.items, selectedPatch: patchesDataSource.items[0]});
      }
    }}
    dataSource={patchesDataSource}
    renderCell={renderCell}
    selectedIndexPaths={[new IndexPath(0, selectedIndex)]}
    style={{borderBottom: "none"}}
    quiet={true}
  />;

  const ShowCallouts = ({isMqpInstalled, mqpVersion}) => {
    return <>
      {!isMqpInstalled && (<div className="patches-callout patches-callout-negative">
        <a href="https://support.magento.com/hc/en-us/articles/360047139492" target="_blank">Quality Patches Tool</a> package is not installed. Please <a href="https://devdocs.magento.com/cloud/project/ece-tools-update.html" target="_blank">update magento/ece-tools</a> composer package and get the latest quality patches recommended by Adobe Commerce Support. magento/ece-tools minimum required version is 2002.1.2.
      </div>)}
      {isMqpInstalled && mqpVersion.patchesCount === 0 && (<div className="patches-callout patches-callout-warning">
        QPT was installed but is not working due to having an error.
        Please go to Adobe Commerce root directory and run command: vendor/bin/magento-patches status
      </div>)}
      {isMqpInstalled && mqpVersion.isOutdated && (<div className="patches-callout patches-callout-warning">
        Installed <a href="https://support.magento.com/hc/en-us/articles/360047139492" target="_blank">magento/quality-patches</a> version is <u>{mqpVersion.current}</u> and behind the latest <a href="https://devdocs.magento.com/quality-patches/release-notes.html" target="_blank">{mqpVersion.latest}</a>.
        We recommend to <a href="https://devdocs.magento.com/cloud/project/ece-tools-update.html" target="_blank">update magento/ece-tools composer package with dependencies</a> and get the latest quality patches along with other useful improvements.
      </div>)}
    </>
  };

  return <>
    <div>Patches are provided by <a href="https://support.magento.com/hc/en-us/articles/360047139492" target="_blank">Quality Patches Tool</a>{isMqpInstalled && (<> (v.{mqpVersion.current})</>)}. All patches are compatible with current Adobe Commerce instance.</div>
    <ShowCallouts isMqpInstalled={isMqpInstalled} mqpVersion={mqpVersion} patchesCount={patches.length} />
    {isMqpInstalled && patches &&
    (patches.length !== 0 ?
        <View>
          <FilterPatchesButton patchCount={patchCount} setPatchCount={setPatchCount} totalPatchCount={totalPatchCount} />
          <Flex direction="row" gap="size-300" minHeight="700px" style={{justifyContent: "center"}}>
            <View flex flexBasis="60%">{tableView}</View>
            <View flexBasis="40%"><PatchDetails patch={selectedPatch} mqpVersion={mqpVersion}/></View>
          </Flex>
        </View>:
        <View><div className="patches-callout patches-callout-info">No patches available</div></View>
    )}
  </>
};

export default PatchesGrid;
export {patchesDataSource};
