import React from 'react';
import Widget from "./widget";
import { VegaDonut } from '@quarry/react-vega';
import styled from 'styled-components';
import useDashboard from "../../../state/dashboard/hooks/useDashboard";
import {Link, Flex} from '@adobe/react-spectrum';
import Extension from '@spectrum-icons/workflow/Extension';

const VegaGaugeDiv = styled.div`
  height: 100% !important;
  min-width: 100% !important;
  position: 'relative';
`
const Description = styled.div`
  color: #6E6E6E;
  text-transform: capitalize;
`;

const ExtensionsWidget = () => {
  const [dashboard,,] = useDashboard();
  if (dashboard.counts.extensions !== 0) {
    return <Widget
      title="Extensions"
      headerContent={
        <Link variant={'primary'} isQuiet>
          <a href="/tab/extensions" target="_self" data-ui-id="extensions-widget-view-all">
            View All
          </a>
        </Link>
      }>
      <VegaGaugeDiv>
        <VegaDonut
          data={{
            types: {extensions: 'string', value: 'number'},
            data: [
              {extensions: 'Old version', value: dashboard.widget.extensions.outdated},
              {extensions: 'Current version', value: dashboard.widget.extensions.upToDate},
              {extensions: 'Disabled', value: dashboard.widget.extensions.disabled},
              {extensions: 'Unknown', value: dashboard.widget.extensions.unknown},
            ],
          }}
          height={'auto'}
          width={'auto'}
          sort={false}
          angle={"value"}
          angleTitle={"Extensions"}
          titleNumberFormat=".0s"
          tooltipNumberFormat=",.0s"
          spec={{
            scales: {
              name: "color",
              type: "ordinal",
              domain: {
                data: "table",
                field: "extensions"
              },
              range: ["#C9252D", "#26C0C7", "#E68623", "#5151D3"]
            },
            marks: [
              {
                type: "arc",
                from: {
                  data: "table"
                },
                encode: {
                  enter: {
                    fill: {
                      scale: "color",
                      field: "extensions"
                    },
                    x: {
                      signal: "width / 2"
                    },
                    y: {
                      signal: "height / 2"
                    },
                    tooltip: {
                      signal: "{title: datum.extensions, 'Extensions': format(datum.value, ',.0f')}"
                    }
                  },
                  update: {
                    startAngle: {
                      field: "startAngle"
                    },
                    endAngle: {
                      field: "endAngle"
                    },
                    padAngle: {
                      value: 0.015
                    },
                    innerRadius: {
                      signal: "if(width >= height, height, width) / 2 * 0.85"
                    },
                    outerRadius: {
                      signal: "if(width >= height, height, width) / 2"
                    },
                    fillOpacity: {
                      signal: "hovered === null ? 1 : hovered === datum.extensions ? 1 : 0.25"
                    }
                  }
                }
              },
              {
                type: "group",
                marks: [
                  {
                    type: "text",
                    interactive: false,
                    from: {
                      data: "aggregate"
                    },
                    encode: {
                      enter: {
                        x: {
                          signal: "width / 2"
                        },
                        y: {
                          signal: "height / 2 + if(width >= height, height, width) / 30",
                          round: true
                        },
                        dy: {
                          signal: "if(width >= height, height, width) / 75"
                        },
                        text: {
                          field: "sum"
                        },
                        fontSize: {
                          signal: "if(width >= height, height, width) / 4",
                          round: true
                        },
                        fontWeight: {
                          value: 300
                        },
                        align: {
                          value: "center"
                        },
                        baseline: {
                          value: "bottom"
                        }
                      }
                    }
                  },
                  {
                    type: "text",
                    interactive: false,
                    encode: {
                      enter: {
                        x: {
                          signal: "width / 2"
                        },
                        y: {
                          signal: "height / 2 + 10"
                        },
                        text: {
                          value: "Extensions"
                        },
                        fontSize: {
                          signal: "if(width >= height, height, width) / 13",
                          round: true
                        },
                        fontWeight: {
                          value: "bold"
                        },
                        align: {
                          value: "center"
                        },
                        baseline: {
                          value: "top"
                        }
                      }
                    }
                  }
                ]
              }
            ],
            legends: [
              {
                fill: "color",
                gridAlign: "all",
                labelLimit: {
                  signal: "width * 0.4"
                },
                orient: "left",
                direction: "vertical",
                columns: 1,
                title: "Extension Version",
                offset: 50,
                symbolSize: "250"
              }
            ],
            signals: [
              {
                name: "hovered",
                value: null,
                on: [
                  {
                    events: ":mouseover",
                    update: "datum.extensions"
                  },
                  {
                    events: ":mouseout",
                    update: "null"
                  }
                ]
              },
              {
                name: "click",
                value: null,
                on: [
                  {
                    events: [
                      {
                        type: "click",
                        consume: true,
                        source: "view"
                      }
                    ],
                    update: "datum.extensions"
                  }
                ]
              }
            ]
          }}
        />
      </VegaGaugeDiv>
    </Widget>
  }
  //Return No Recommendations state if there is no recommendations in DB.
  return <Widget title="Extensions">
    <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height="100%" width={'100%'}>
      <Extension size={'XL'} marginBottom={'10px'} />
      <Description>No Extensions Installed</Description>
      <Link isQuiet>
        <a target="_blank" href={'https://business.adobe.com/products/magento/marketplace.html'} data-ui-id="discover-extensions-marketplace-widget-link">
          Discover the Adobe Commerce Extensions Marketplace
        </a>
      </Link>
    </Flex>
  </Widget>
};

export default ExtensionsWidget;
