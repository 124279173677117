import React from "react";
import { useState, useEffect } from "react";
import ImplementationTypeField from "./dynamicFields/implementationTypeField";
import EnvironmentField from "./dynamicFields/environmentField";
import AdobeCommerceVersionField from "./dynamicFields/adobeCommerceVersionField";
import ContactReasonField from "./dynamicFields/contactReasonField";
import DiskStorageIncreaseField from "./dynamicFields/diskStorageIncreaseField";
import SurgeTemporaryCapacityRequestField from "./dynamicFields/surgeTemporaryCapacityRequestField";
import InfrastructureOrApplicationField from "./dynamicFields/infrastructureOrApplicationField";
import { ProgressCircle } from "@adobe/react-spectrum";

function DynamicFields({ control, errors, setValue, watch, fields }) {
  const [loading, setLoading] = useState(true);
  const contactReason = watch(fields.contactReason.name);
  const infrastructureContactReason = watch(
    fields.commerceInfrastructureContactReason.name
  );
  const watchImplementationType = watch(fields.implementationType.name);
  const watchIsSiteLive = watch(fields.isSiteLive.name);

  useEffect(() => {
    if (fields) {
      setLoading(false);
    }
  }, [fields]);

  if (loading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate />;
  }

  return (
    <>
      <ContactReasonField
        control={control}
        errors={errors}
        setValue={setValue}
        fields={fields}
      />
      <ImplementationTypeField
        control={control}
        errors={errors}
        setValue={setValue}
        watchImplementationType={watchImplementationType}
        watchIsSiteLive={watchIsSiteLive}
        fields={fields}
      />
      <EnvironmentField
        control={control}
        errors={errors}
        setValue={setValue}
        fields={fields}
      />
      <AdobeCommerceVersionField
        control={control}
        errors={errors}
        setValue={setValue}
        fields={fields}
      />
      <InfrastructureOrApplicationField
        contactReason={contactReason}
        control={control}
        errors={errors}
        setValue={setValue}
        fields={fields}
      />
    </>
  );
}

export default DynamicFields;
