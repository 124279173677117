import React from 'react';
import {preLaunchChecksEmailSummaryAction} from "state/cloudReports/hooks/preLaunchChecksEmailSummary";
import {checks} from "./checks";

import {
  Button,
  ActionButton, ButtonGroup, Content, Dialog, DialogTrigger, Divider, Header, Heading, Text
} from '@adobe/react-spectrum';

import Email from '@spectrum-icons/workflow/Email';

const EmailSummary = props => {
  const sendSummary = (close) => {
    preLaunchChecksEmailSummaryAction(checks).then().catch();
    close();
  }

  return (
    <div className="quick-action email-summary" id={props.id}>
      <DialogTrigger>
        <div><ActionButton className="action-button"><Email /> Send Email Summary &nbsp;</ActionButton></div>
        {(close) => (
          <Dialog>
            <Heading>Did you SAVE your progress?</Heading>
            <Header>Send me a Email Summary</Header>
            <Divider />
            <Content>
              <Text>
                Have you forgot to SAVE the latest progress? <br/>
              </Text>
              <Text>
                The email summary will use the latest state saved in the database for the Email Summary.
              </Text>
            </Content>
            <ButtonGroup>
              <Button variant="secondary" onPress={close}>Cancel</Button>
              <Button variant="accent" onPress={() => sendSummary(close)}>Confirm</Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogTrigger>
    </div>
  )
}

export default EmailSummary;
