import React from 'react';
import Widget from "./widget";
import {
  TableView,
  TableHeader,
  Column,
  Row,
  TableBody,
  Cell,
  Link,
  Flex,
} from '@adobe/react-spectrum';
import {GetUiId} from "../../base/components/jsUtilities";

let columns = [
  {name: 'Resource Links'},
  {name: 'Description'},
];

let rows = [
  {id: 1, name: 'Help Center', description: 'Searchable knowledge base of help articles.', url:'https://support.magento.com/'},
  {id: 2, name: 'DevDocs', description: 'Adobe Commerce developer guide.', url:'https://devdocs.magento.com/'},
  {id: 3, name: 'Quality Patches', description: 'Quality patches tool', url:'https://devdocs.magento.com/quality-patches/tool.html#patch-grid'},
  {id: 4, name: 'Security Center', description: 'Security patches, updates, and best practices.', url:'https://magento.com/security'},
  {id: 5, name: 'OAC', description: 'Observation for Adobe Commerce', url:'https://support.magento.com/hc/en-us/articles/4402379845901-Use-Observation-for-Adobe-Commerce'}
];

const ExternalResourcesWidget = (layoutItem) => {
    return <Widget layoutItem={layoutItem} title="External Resources">
      <Flex width={'100%'} direction="column" UNSAFE_style={{overflow: 'hidden'}}>
        <TableView flexGrow isQuiet UNSAFE_style={{margin: '0 -1rem' ,border:'none'}}>
          <TableHeader>
            <Column align={'start'} width={'30%'}>
              {columns[0].name}
            </Column>
            <Column align={'start'} width={'70%'}>
              {columns[1].name}
            </Column>
          </TableHeader>
            <TableBody items={rows} UNSAFE_stye={{border:'none'}} >
              {item => (
                <Row>
                  <Cell>
                    <Link isQuiet>
                      <a href={item.url} target={'_blank'} data-ui-id={GetUiId(item.name) + "-widget-link"}>{item.name}</a>
                    </Link>
                  </Cell>
                  <Cell>
                    {item.description}
                  </Cell>
                </Row>
              )}
            </TableBody>
        </TableView>
      </Flex>
    </Widget>;
};

export default ExternalResourcesWidget;

