export const SET_ALERT_ITEMS = 'alerts/SET_ALERT_ITEMS';
export const SET_OAC_LINK = 'alerts/SET_OAC_LINK';

export const setAlertItems = payload => ({
  type: SET_ALERT_ITEMS,
  payload,
});
export const setOACLink = payload => ({
  type: SET_OAC_LINK,
  payload,
});
