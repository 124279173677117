import axios from 'axios';

const getAdditionalParams = () => {
  let params = {}
  if (sessionStorage.getItem('context')) {
    params = {context: sessionStorage.getItem('context')};
  }
  return params;
}
export const getData = (url, config) => {
  return axios
    .get(`${url}`, {params: getAdditionalParams(), withCredentials: true, ...config});
};

export const postData = (url, data, config) => {
  config['params'] = getAdditionalParams();
  config['withCredentials'] = true;
  return axios
    .post(`${url}`, data, config);
};

export const putData = (url, data, config) => {
  config['params'] = getAdditionalParams();
  config['withCredentials'] = true;
  return axios
    .put(`${url}`, data, config);
};

export const getDataWithParams = (url, params, cancelToken, config = {}) => {
  return axios
    .get(`${url}`, {params: {...params, ...getAdditionalParams()}, withCredentials: true, ...config, cancelToken});
}

