import React from 'react';
import CheckSection from "./CheckSection";
import {
  TableView,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell,
  Button
} from '@adobe/react-spectrum';

class CurrentConfigurations extends CheckSection {

  getRightSection() {
    return (
      <div>
        <div>
          <h2>Disk Volumes (Database and Shared Files)</h2>
          <pre className="text-monospace">
            {this.getInfoValue('cliDiskUsage') ? (
              this.getInfoValue('cliDiskUsage')
            ) : (
              "No data available"
            )}
          </pre>

        </div>
        <h2>The recommended configurations are here as a soft guard rail only.</h2>
        <TableView aria-label="Catalog Management Best Practices" labelStyle={{ zIndex: 3 }}>
          <TableHeader>
            <Column>Catalog Management Best Practices</Column>
            <Column/>
            <Column/>
          </TableHeader>
          <TableBody>
            <Row>
              <Cell># Websites</Cell>
              <Cell>{this.getInfoValue('sitesCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/sites-stores-store-views.html?lang=en")}>Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Stores</Cell>
              <Cell>{this.getInfoValue('storesCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/sites-stores-store-views.html?lang=en")}>Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Store Views</Cell>
              <Cell>{this.getInfoValue('storeViewCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/sites-stores-store-views.html?lang=en")}>Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Customer Groups</Cell>
              <Cell>{this.getInfoValue('customerGroupCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/sites-stores-store-views.html?lang=en")}>Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Total SKUs</Cell>
              <Cell>{this.getInfoValue('totalSkusCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management.html?lang=en")}>Catalog Management Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Effective SKUs</Cell>
              <Cell>{this.getInfoValue('effectiveSkusCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management.html?lang=en")}>Catalog Management Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Categories</Cell>
              <Cell>{this.getInfoValue('categoriesCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management.html?lang=en")}>Catalog Management Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Sales Rules</Cell>
              <Cell>{this.getInfoValue('salesRuleCount')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management.html?lang=en")}>Catalog Management Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Coupons</Cell>
              <Cell>{JSON.stringify(this.getInfoArray('general-cart-price-rules-coupon-count'))}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management.html?lang=en")}>Catalog Management Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Product Attributes</Cell>
              <Cell>{this.getInfoValue('general-product-attributes-count')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management.html?lang=en")}>Catalog Management Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Product Attribute Option</Cell>
              <Cell></Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management.html?lang=en")}>Catalog Management Best Practices</Button></Cell>
            </Row>
            <Row>
              <Cell># Main Domain</Cell>
              <Cell>{this.getInfoValue('mainurl')}</Cell>
              <Cell></Cell>
            </Row>
          </TableBody>
        </TableView>
      </div>
    );
  }

  getLeftSection() {
    return (
      <div>
        <h2>Please review the configurations in the table below.</h2>
        <TableView aria-label="Current Configurations">
          <TableHeader>
            <Column>Item</Column>
            <Column>Current</Column>
            <Column>Status/Current</Column>
          </TableHeader>
          <TableBody>
            <Row>
              <Cell>Adobe Commerce Version</Cell>
              <Cell>{this.getInfoValue('magentoVersion')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/en/docs/commerce-operations/release/notes/overview")}>Release Notes</Button></Cell>
            </Row>
            <Row>
              <Cell>ECE Tools Version</Cell>
              <Cell>{this.getInfoValue('eceToolsVersion')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/release-notes/ece-tools-package")}>Release Notes</Button></Cell>
            </Row>
            <Row>
              <Cell>Magento Cloud Patches</Cell>
              <Cell>{this.getInfoValue('cloudPatchesVersion')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/release-notes/cloud-patches")}>Release Notes</Button></Cell>
            </Row>
            <Row href="https://experienceleague.adobe.com/docs/commerce-operations/tools/quality-patches-tool/release-notes.html" target="_blank">
              <Cell>Magento Quality Patch Version</Cell>
              <Cell>{this.getInfoValue('qualityPatchesVersion')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/tools/quality-patches-tool/release-notes.html")}>Release Notes</Button></Cell>
            </Row>
            <Row href="https://github.com/fastly/fastly-magento2/blob/master/Release-Notes.md" target="_blank">
              <Cell>Fastly Module Version</Cell>
              <Cell>{this.getInfoValue('fastlyModuleVersion')}</Cell>
              <Cell><Button role="link" onClick={() => this.openInNewTab("https://github.com/fastly/fastly-magento2/blob/master/Release-Notes.md")}>Release Notes</Button></Cell>
            </Row>
          </TableBody>
        </TableView>

        <h2>The following table provides the current service versions configured. You may need to create a support ticket to get them updated.</h2>
        <TableView>
          <TableHeader>
            <Column>Service Name</Column>
            <Column>Version</Column>
            <Column>System Requirements</Column>
          </TableHeader>
          <TableBody>
            {this.getInfoArray('servicesAndComponents').map(( listValue, index ) => {
              return (
                <Row key={index}>
                  <Cell>{listValue['Name']}</Cell>
                  <Cell>{listValue['Installed Ver.']}</Cell>
                  <Cell><Button role="link" onClick={() => this.openInNewTab("https://experienceleague.adobe.com/docs/commerce-operations/installation-guide/system-requirements.html")}>System Requirements</Button></Cell>
                </Row>
              );
            })}
          </TableBody>
        </TableView>
      </div>
    )
  }
}
export default CurrentConfigurations;
