import {useStateValue} from 'state/index';
import {onException} from "state/httpStatusHandler";
import {loadRecommendedPatches} from "../queries";

const useRecommendedPatches = (sourceName, action) => {
  const [state, dispatch] = useStateValue();
  return async (callback) => {
    await loadRecommendedPatches(sourceName, action)
      .then(() => callback())
      .catch(errorObject => onException(errorObject, dispatch, state));
  };
}

export default useRecommendedPatches;
