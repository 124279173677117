import React from 'react';
import CheckSection from "./CheckSection";
import {
  Heading,
  TableView,
  TableBody,
  Column,
  Content,
  TableHeader,
  Cell,
  Row
} from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';


class DNS extends CheckSection {

  getCertSans(type) {
    let data = this.getInfoValue('cert');
    let finalArr = [];
    let issuerDn = [];

    if (!data) {
      return [];
    }

    if (Object.keys(data).length === 0) {
      return ['error occurred 001'];
    }
    if (data.rescode !== 0) {
      return ['error occurred 002'];
    }
    if (typeof data.data.chain === 'undefined') {
      return ['error occurred 003'];
    }

    data.data.chain.map((chainObj) => {
      issuerDn.push(chainObj.issuer_dn);
      chainObj.sans.map((san) => {
        finalArr.push(san);
      })
    });

    if (type === 'san') {
      return finalArr;
    } else if (type === 'issuer_dn') {
      return issuerDn;
    } else {
      return {"san": finalArr, "issuer_dn":issuerDn};
    }
  }

  getSendGridRecords () {
    let finalDomains = [];
    this.getInfoArray('sendgridDKIMCname').map(( listValue ) => {
      if ('data' in listValue) {
        return listValue['data'].map(( d ) => {
          finalDomains.push(d);
        });
      }
    });
    return finalDomains;
  }

  getRightSection() {
    return (
      <div>
        <Heading level={2} marginTop={30}>Domains Configured With Adobe Commerce</Heading>
        <TableView>
          <TableHeader>
            <Column maxWidth={80}>Status</Column>
            <Column>Domain</Column>
            <Column>Configured in Fastly</Column>
            <Column>Configured in Nginx</Column>
          </TableHeader>
          <TableBody>
            {this.getInfoArray('domainsAndAcmeChallenges').map(( listValue, index ) => {
              return (
                <Row key={index}>
                  <Cell width={50}>
                    {listValue['nginx'] &&  listValue['fastly'] ?
                      <CheckmarkCircle aria-label="Positive Check" color="positive" />
                      : <Alert aria-label="Negative Alert" color="negative" />}

                  </Cell>
                  <Cell>{listValue['domain']}</Cell>
                  <Cell>{listValue['fastly'] ? 'Yes': 'No'}</Cell>
                  <Cell>{listValue['nginx'] ? 'Yes': 'No'}</Cell>
                </Row>
              );
            })}
            {this.getInfoArray('domainsAndAcmeChallenges').length === 0 && (
              <Row>
                <Cell>No data available</Cell>
                <Cell></Cell>
                <Cell></Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
        <Heading level={2} marginTop={30}>TLS/SSL Certificates</Heading>
        <TableView overflowMode="wrap" >
          <TableHeader>
            <Column key="status" allowsResizing maxWidth={80}>Status</Column>
            <Column key="cname" allowsResizing>CNAME</Column>
            <Column key="val" allowsResizing minWidth={200}>Value</Column>
          </TableHeader>
          <TableBody>
            {this.getInfoArray('domainsAndAcmeChallenges').map(( listValue, index ) => {
              return (
                <Row key={index}>
                  <Cell width={50}>
                    {listValue['acme_status'] === 'Found' ?
                      <CheckmarkCircle aria-label="Positive Check" color="positive" />
                      : <Alert aria-label="Negative Alert" color="negative" />}
                  </Cell>
                  <Cell>{listValue['cname']}</Cell>
                  <Cell>{listValue['acme']}</Cell>
                </Row>
              );
            })}
            {this.getInfoArray('domainsAndAcmeChallenges').length === 0 && (
              <Row>
                <Cell>No data available</Cell>
                <Cell></Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
        <Content>
          <Heading level={2} marginTop={30}>The current SSL Certificate:</Heading>
          Cert Checker: <a href={this.getInfoValue('platformShCertCheckURL')} target="_blank">
            {this.getInfoValue('platformShCertCheckURL')}
          </a>
          <ol>
            {this.getCertSans('issuer_dn').map(( listValue, index ) => {
              return (
                <li key={index}>{listValue}</li>
              );
            })}
          </ol>
          The currently issued SSL (TLS) certificate is valid for the following domains
          <ol>
            {this.getCertSans('san').map(( listValue, index ) => {
              return (
                <li key={index}>{listValue}</li>
              );
            })}
          </ol>
        </Content>

        <Heading level={2} marginTop={30}>Sendgrid DKIM Records</Heading>
        <TableView>
          <TableHeader>
            <Column maxWidth={50}>Status</Column>
            <Column naxWidth={80}>Label</Column>
            <Column>CNAME</Column>
            <Column>Value</Column>
          </TableHeader>
          <TableBody>
            {this.getSendGridRecords().map(( sendgridRecord, index ) => {
              return (
                <Row key={index}>
                  <Cell width={50}>
                    {sendgridRecord['valid'] ?
                      <CheckmarkCircle aria-label="Positive Check" color="positive" />
                      : <Alert aria-label="Negative Alert" color="negative" />}
                  </Cell>
                  <Cell maxWidth={80}>{sendgridRecord['label']}</Cell>
                  <Cell>{sendgridRecord['host']}</Cell>
                  <Cell>{sendgridRecord['data']}</Cell>
                </Row>
              );
            })}
            {this.getSendGridRecords().length === 0 && (
              <Row>
                <Cell>No data available</Cell>
                <Cell></Cell>
                <Cell></Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      </div>
    );
  }
}
export default DNS;
