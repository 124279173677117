import React, { useEffect } from "react";
import {
  Flex,
  ProgressCircle,
  View,
} from "@adobe/react-spectrum";
import { useCaseDeflection } from "../../../state/supportCasesTab/hooks/useCasedeflection";
import useSupportCasesTab from "../../../state/supportCasesTab/hooks/useSupportCasesTab";
import RecommendationCard from "./caseDeflection/recommendationCard";

function CaseDeflection({ issueDescription, issueTicket }) {
  const [ recommendations, loadCaseDeflection] = useCaseDeflection();
  const [formFields, , , request] = useSupportCasesTab();

  useEffect(() => {
    loadCaseDeflection(issueDescription, issueTicket);
  }, []);

  return (
    <Flex direction={"row"} justifyContent={"center"}>
      {recommendations !== null && recommendations !== undefined ? (
        <View width={"700px"}>
          <RecommendationCard recommendations={recommendations} />
        </View>
      ) : (
        <ProgressCircle aria-label="Loading…" size={"L"} isIndeterminate />
      )}
    </Flex>
  );
}

export default CaseDeflection;
