import {View, Heading, Divider, Text, ProgressCircle, Flex} from '@adobe/react-spectrum';
import CloudError from '@spectrum-icons/workflow/CloudError';
import React from "react";

import OutageLinks from "./outageInfo/outageLinks";
import MostActiveIps from "./outageInfo/mostActiveIps";
import RequestsToOrigin from "./outageInfo/requestsToOrigin";

const OutageDetails = ({incidentInfo, outageIssues, informationLinks, outageIncidents}) => {

  if(incidentInfo[0] === undefined) {
    return (
      <View width={'50%'}>
        <Heading>Outage Details</Heading>
        <Divider size={'M'} marginBottom={'size-50'}/>
        <Text>No incident selected</Text>
      </View>
    )
  }

  if (outageIncidents[incidentInfo[0]] === undefined || outageIncidents[incidentInfo[0]]['status'] === 'failed') {
    return (
      <Flex direction={'row'} gap={'size-100'}>
        <CloudError/>
        <Text> SWAT was unable to collect information regarding this outage.</Text>
      </Flex>
    )
  }

  if (outageIncidents[incidentInfo[0]]['status'] === 'in_progress') {
    return (
      <View>
        <ProgressCircle aria-label="Loading…" isIndeterminate/>
        <Text>Data is currently being retrieved from the client's environment, try refreshing the page in 1 minute.</Text>
      </View>
    )
  }

  return (
    <View width={'50%'}>
      <OutageLinks informationLinks = {informationLinks} outageIssues={outageIssues} incidentInfo={incidentInfo} />
      <MostActiveIps incidentInfo={incidentInfo} outageIssues={outageIssues} />
      <RequestsToOrigin incidentInfo={incidentInfo} outageIssues={outageIssues} />
    </View>
  );
};

export default OutageDetails;
