import React, {useState, useEffect} from 'react';
import {
  ActionButton,
  ProgressCircle,
  Text,
  Image,
  Button,
  View,
  Flex,
} from '@adobe/react-spectrum';
import { error } from "@react/react-spectrum/Toast";
import styled from 'styled-components';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import useDashboard from "state/dashboard/hooks/useDashboard";
import useInterval from "../../hooks/useInterval";
import useUctReport from "state/upgradeWidget/hooks/useUctReport";
import useUctScan from "state/upgradeWidget/hooks/useUctScan";
import {VegaDonut} from '@quarry/react-vega';
import Widget from "./widget";
import Uct from './uctWidget/img/UCT.png';
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";

const Title = styled.div`
  font-family: 'adobe-clean-bold' !important;
  font-size: 13.5px;
  color: #4B4B4B;
  margin-bottom: 0.5rem
  margin-top: -5px
`;
const Label = styled.div`
  font-family: 'adobe-clean' !important;
  font-size: 14px;
  color: #6E6E6E;
  margin-bottom: 10px
`;
const Value = styled.div`
  font-family: 'adobe-clean' !important;
  font-size: 14px;
  color: #323232;
  margin-bottom: 10px;
  text-align:end;
`;
const Description = styled.div`
  color: #6E6E6E;
  text-transform: capitalize;
`;

const UctWidget = (layoutItem) => {
  const [dashboard, isDashboardReloading, reLoadDashboard] = useDashboard();
  const [,downloadReport,] = useUctReport();
  const runScan = useUctScan();

  // set default polling value from the local storage
  const [polling, setPolling] = useState(localStorage.getItem('polling') === 'true');

  //once local polling state changed - save it to the local storage
  useEffect(() => {
    localStorage.setItem('polling', polling);
  }, [polling]);


  const scanUct = () => {
    if (!isDashboardReloading) {
      runScan(() => reLoadDashboard('?isAjax=true'));
      setPolling(true);
      localStorage.setItem('isErrorRendered', false)
    }
  };

  useInterval(() => {
    if (polling && dashboard.widget.uct.status === 'in_progress') {
      reLoadDashboard('?isAjax=true')
      setPolling(dashboard.widget.uct.status === 'in_progress');
    }
  }, 3000);

  if (dashboard.widget.uct.status === 'error'
    && dashboard.widget.uct.isDisplayedError === false
    && localStorage.getItem('isErrorRendered') === 'false'
  ) {
    error("Could Not Generate Report For Upgrade Compatibility Tool. Try Again Later." , {timeout: 60000})
    localStorage.setItem('isErrorRendered', true);
  }

  if (dashboard.widget.uct.uctAvailable !== true) {
    return <Widget layoutItem={layoutItem} title="Upgrade Compatibility">
      <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height="100%" width={'100%'}>
        <CheckmarkCircle color={'positive'} size={'XL'} marginBottom={'10px'} />
        <Description>No Upgrade Recommendations</Description>
      </Flex>
    </Widget>
  }

  if (dashboard.widget.uct.status === 'in_progress') {
    return <Widget layoutItem={layoutItem} title={"Upgrade Compatibility"}>
      <Flex direction={"column"} justifyContent={"center"} width="100%">
        <View alignSelf={"center"} marginBottom={"16px"}>
          <Text UNSAFE_className={"dashboardWidget-loading-text"}>Scan in progress. May take 60 minutes or more to complete.</Text>
        </View>
        <View alignSelf={"center"}>
          <ProgressCircle size="L" aria-label="Loading…" isIndeterminate/>
        </View>
      </Flex>
    </Widget>
  }
  if (dashboard.widget.uct.status === ''
    || (dashboard.widget.uct.status === 'error' && dashboard.widget.uct.report === '')
  ) {
    return <Widget layoutItem={layoutItem}
                   title={"Upgrade Compatibility"}
                   headerContent={
                     <Button className={'widgetSubmitButton'}
                             variant={'cta'}
                             type={'submit'}
                             onClick={scanUct}
                             isDisabled={isDashboardReloading}
                             data-ui-id="uct-widget-run-upgrade-scan-button"
                     >Run Upgrade Scan</Button>
                   }>
      <Flex direction={"row"} justifyContent={"space-between"}>
        <View UNSAFE_style={{color: "#747474"}} width={400}>
            <Text>The Upgrade Compatibility Tool identifies potential problems that must be fixed in your code before attempting to upgrade to a newer version of Adobe Commerce.
              <br />
              <br />
              Run a scan now to get a full report of all upgrade compatibility issues.</Text>
        </View>
        <View overflow={"hidden"} UNSAFE_style={{color: "black"}}>
          <Image src={Uct} alt="uct" objectFit="cover"/>
        </View>
      </Flex>
    </Widget>
  }

  const data = {
    types: { issues: 'string', value: 'string' },
    data: [
      { issues: 'Critical', value: dashboard.widget.uct.issues.critical },
      { issues: 'Errors', value: dashboard.widget.uct.issues.error },
      { issues: 'Warnings', value: dashboard.widget.uct.issues.warning }
    ],
  };

  return <Widget
    className={'uctReportReady'}
    layoutItem={layoutItem}
    headerContent={
      <Button variant={'primary'}
              onClick={scanUct}
              type={'upgradeScan'}
              isDisabled={isDashboardReloading}
              data-ui-id="uct-widget-run-again-upgrade-scan-button"
      >Run Upgrade Scan</Button>
    }
    title={"Upgrade Compatibility"}
    >
      <Flex direction={"row"} justifyContent={"space-between"} gap={'size-200'} width={'100%'}>
        <View height={'100%'} width={'55%'}>
          <VegaDonut
            data={data}
            height={'auto'}
            width={'auto'}
            color={"issues"}
            angle={"value"}
            angleTitle={"Issues"}
            legendOrientation={'left'}
            sort={false}
            titleNumberFormat = ".0s"
            tooltipNumberFormat = ",.0s"
            spec={{
              scales: {
                name: "color",
                type: "ordinal",
                domain: {
                  data: "table",
                  field: "issues"
                },
                range: ["#C9252D", "#E68623","#26C0C7"]
              },
              marks: [
                {
                  type: "arc",
                  from: {
                    data: "table"
                  },
                  encode: {
                    enter: {
                      fill: {
                        scale: "color",
                        field: "issues"
                      },
                      x: {
                        signal: "width / 2"
                      },
                      y: {
                        signal: "height / 2"
                      },
                      tooltip: {
                        signal: "{title: datum.issues, 'Issues': format(datum.value, ',.0f')}"
                      }
                    },
                    update: {
                      startAngle: {
                        field: "startAngle"
                      },
                      endAngle: {
                        field: "endAngle"
                      },
                      padAngle: {
                        value: 0.015
                      },
                      innerRadius: {
                        signal: "if(width >= height, height, width) / 2 * 0.85"
                      },
                      outerRadius: {
                        signal: "if(width >= height, height, width) / 2"
                      },
                      fillOpacity: {
                        signal: "hovered === null ? 1 : hovered === datum.issues ? 1 : 0.25"
                      }
                    }
                  }
                },
                {
                  type: "group",
                  marks: [
                    {
                      type: "text",
                      interactive: false,
                      from: {
                        data: "aggregate"
                      },
                      encode: {
                        enter: {
                          x: {
                            signal: "width / 2"
                          },
                          y: {
                            signal: "height / 2 + if(width >= height, height, width) / 30",
                            round: true
                          },
                          dy: {
                            signal: "if(width >= height, height, width) / 75"
                          },
                          text: {
                            field: "sum"
                          },
                          fontSize: {
                            signal: "if(width >= height, height, width) / 4",
                            round: true
                          },
                          fontWeight: {
                            value: 300
                          },
                          align: {
                            value: "center"
                          },
                          baseline: {
                            value: "bottom"
                          }
                        }
                      }
                    },
                    {
                      type: "text",
                      interactive: false,
                      encode: {
                        enter: {
                          x: {
                            signal: "width / 2"
                          },
                          y: {
                            signal: "height / 2 + 10"
                          },
                          text: {
                            value: "Total Issues"
                          },
                          fontSize: {
                            signal: "if(width >= height, height, width) / 13",
                            round: true
                          },
                          fontWeight: {
                            value: "bold"
                          },
                          align: {
                            value: "center"
                          },
                          baseline: {
                            value: "top"
                          }
                        }
                      }
                    }
                  ]
                }
              ],
              legends: [
                {
                  fill: "color",
                  gridAlign: "all",
                  labelLimit: {
                    signal: "width * 0.4"
                  },
                  orient: "left",
                  direction: "vertical",
                  columns: 1,
                  title: "Issues By Severity",
                  offset: -30,
                  symbolSize: "250"
                }
              ],
              signals: [
                {
                  name: "hovered",
                  value: null,
                  on: [
                    {
                      events: ":mouseover",
                      update: "datum.issues"
                    },
                    {
                      events: ":mouseout",
                      update: "null"
                    }
                  ]
                },
                {
                  name: "click",
                  value: null,
                  on: [
                    {
                      events: [
                        {
                          type: "click",
                          consume: true,
                          source: "view"
                        }
                      ],
                      update: "datum.issues"
                    }
                  ]
                }
              ]
            }}
          />
        </View>
        <View width={'45%'}>
          <Flex direction={'column'} justifyContent={'space-between'} height={'100%'}>
            <div>
              <Title>Versioning</Title>
              {dashboard.widget.uct.list.map(item =>
                <Flex direction="row" justifyContent={'space-between'} gap={'size-50'} key={item.label}>
                  <Label>{item.label}</Label>
                  <Value>{item.value}</Value>
                </Flex>
              )}
            </div>
            <ActionButton
              onClick={downloadReport}
              alignSelf={'end'}
              type={'view_report'}
              data-ui-id="uct-widget-download-report-button"
            >
              <DownloadIcon />
              <Text>Download Report</Text>
            </ActionButton>
          </Flex>
        </View>
     </Flex>
    </Widget>
};
export default UctWidget;
