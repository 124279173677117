import { error } from "@react/react-spectrum/Toast";
import {displayErrorPage} from "./dashboard/actions";

export const onException = (errorObject, dispatch, state) => {
  if (errorObject.response.status === 503) {
    dispatch(displayErrorPage({ displayMaintenancePage: true }));
    return;
  }
  if (errorObject.response.status === 403 || errorObject.response.status === 401) {
    dispatch(displayErrorPage({ displayUnauthorizedPage: true }));
    return;
  }
  if (state.dashboard.displaySplashPage) {
    return
  }
  if (errorObject.response.hasOwnProperty('data')) {
    if (errorObject.response.data.hasOwnProperty('error')) {
      return error(errorObject.response.data.error , {timeout: 60000})
    }
  }
  error('Something went wrong. Please try later.' , {timeout: 60000})
};
