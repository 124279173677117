import {SET_INFO_TABS, SET_INFO_TAB_SECTIONS} from './actions';

export const INITIAL_STATE = {
  infoTabs: []
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_INFO_TABS:
      return [
        ...Object.values(action.payload).sort((a, b) => (b['position'] < a['position']) ? 1 : -1)
      ];
    default:
      return state;
  }
};
