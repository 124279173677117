import React from 'react';
import ExceptionsGrid from "./components/exceptionsGrid";
import {View} from "@adobe/react-spectrum";

const ExceptionsTab = () => {
  return (
    <>
      <View marginBottom={"size-150"}>Exceptions are abnormal conditions that do not have a known solution. Exceptions thrown by your server are listed here along with count, or number of occurrences are included.</View>
      <ExceptionsGrid/>
    </>
  );
};

export default ExceptionsTab;
