import React from 'react';
import Collapsible from '../collapsible';
import ViolationRuleTextFieldValue from '../violationRuleTextFieldValue';
import {Flex, View} from "@adobe/react-spectrum";
import styled from "styled-components";

const Field = styled.div`
  font-family: adobe-clean-light, Times, serif !important;;
  color: #505050;
  font-size: 14px;
  font-weight: 300;
`;
const FieldValue = styled.div`
  font-family: adobe-clean, Times, serif !important;;
  color: #2C2C2C;
  font-size: 14px;
`;

const renderFieldValue = item => {
  if (!item.frontend_component
    || item.frontend_component.type === 'text'
  ) {
    return <ViolationRuleTextFieldValue item={item}/>
  }
  if (item.frontend_component.type === 'url') {
    switch (item.frontend_component.label_source) {
      case 'data':
        return <a target="_blank" href={item.data}>{item.data}</a>
      case 'parse_data':
        let chunks = item.data.split('|');
        return <a target="_blank" href={chunks.shift()}>{chunks.join('|')}</a>
      case 'label':
        return <a target="_blank" href={item.data}>{item.frontend_component.label_text}</a>
    }
  }
  if (item.frontend_component.type === 'custom') {
    const Component = require('../customField/' + item.frontend_component.custom_component).default;
    return <Component item={item}/>
  }
}

const renderFieldColumnHeader = item => {
  if (item.frontend_component.hide_header_column !== undefined && item.frontend_component.hide_header_column === true) {
    return;
  }
  return <View width="30%">
    <Field>{item.title}</Field>
  </View>;
}

const renderFieldColumnValue = item => {
  return <View>
    <FieldValue>{renderFieldValue(item)}</FieldValue>
  </View>;
}

const renderField = (field, index) => {
  return <Flex key={index} direction="row" width="100%" UNSAFE_style={{
    paddingTop: '5px',
    paddingBottom: '8px',
    borderBottom: '1px solid #e1e1e1'
  }}>
    {renderFieldColumnHeader(field)}
    {renderFieldColumnValue(field)}
  </Flex>;
};

const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const renderSectionItem = (item, index) => {
  if (item.frontend_component.type === 'separate-fields' && item.data) {
    let data = [];
    if (isJsonString(item.data)) {
      data = JSON.parse(item.data);
    }
    if (!Array.isArray(data) && typeof data === 'object') {
      data = Object.values(data);
    }

    return data.map((field, fieldIndex) => renderField(field, fieldIndex))
  }
  return renderField(item, index)
}

const defaultType = ({section, sectionIndex, minHeight = "0"}) => {
  if (section.title === "General information") {
    minHeight = '224px'
  }
  return <div key={sectionIndex} >
    <Collapsible title={section.title}  tooltipMessage={section.contextualHelp} isOpen minHeight={minHeight}>
      {section.items.map((item, index) => renderSectionItem(item, index))}
    </Collapsible>
  </div>;
};

export default defaultType;
