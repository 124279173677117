import {preLaunchChecksDocGen} from "../docAction";
import { error, success } from "@react/react-spectrum/Toast";
const preLaunchChecksDocGenData = async (data) => {
  await preLaunchChecksDocGen(data)
    .then(response => {
      success('Template Document triggered (Please allow few minutes).');
    })
    .catch(errorObject => {
      if (errorObject.response.hasOwnProperty('data')) {
        if (errorObject.response.data.hasOwnProperty('error')) {
          return error(errorObject.response.data.error , {timeout: 60000})
        }
      }
      error('Something went wrong. Please try later.' , {timeout: 60000})
    });
};


export const preLaunchChecksDocGenAction = (data) => {
  return preLaunchChecksDocGenData({data});
};

export default preLaunchChecksDocGenAction;
