import React from 'react';
import {
  Cell,
  Column,
  Row,
  TableView,
  TableBody,
  TableHeader,
  Divider,
  Heading,
  Flex,
  Text,
  View,
  Link,
} from '@adobe/react-spectrum';

const PastOutages = ({stateChanger, outageIncidents,...rest}) => {
  let outageList = [];

  Object.keys(outageIncidents).forEach(function(key){
    outageList.push(
      <Row key={key} UNSAFE_stye={{border:'none'}}>
        <Cell>
          <Link isQuiet>
            <a href={'https://magento.zendesk.com/agent/tickets/'+outageIncidents[key]['outage_id']} target={'_blank'}>{outageIncidents[key]['outage_id']}</a>
          </Link>
        </Cell>
        <Cell>{outageIncidents[key]['created_at']}</Cell>
      </Row>
    )
  })

  const selectionChange = (key) => {
    let incidentId = parseInt(key);
    let outageIncident = outageIncidents[key];
    stateChanger([incidentId, outageIncident['created_at']]);
  }

  if (outageList.length > 0) {
    return <Flex direction={'column'} >
      <Heading level={3}>PAST OUTAGES</Heading>
      <Divider size={'M'} marginBottom={'size-100'}/>
      <TableView
        selectionMode="single"
        selectionStyle="highlight"
        onSelectionChange={([...keys]) => selectionChange(keys[0])}
        disallowEmptySelection
        isQuiet
        height={'75vh'}
        UNSAFE_style={{margin: '0 -1rem', overflowX: 'hidden'}}
      >
        <TableHeader>
          <Column width={'49%'}>Zendesk Ticket Id</Column>
          <Column width={'50%'}>Date (UTC)</Column>
        </TableHeader>
        <TableBody>
          {outageList.reverse()}
        </TableBody>
      </TableView>
    </Flex>
  }

  return (
    <View width={'100%'}>
      <Heading>Past Outages</Heading>
      <Divider size={'M'} marginBottom={'size-50'}/>
      <Text>No Outages in Database</Text>
    </View>
  );
};

export default PastOutages;
