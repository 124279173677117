import React from 'react';
import Heading from '@react/react-spectrum/Heading';

const ExtensionDetail = ({extension}) => {
  const subtitleStyles = {
    textTransform: "capitalize"
  };
  return (extension && <div className="spectrum-Table--quiet" style={{ width: "100%", alignItems: 'center', fontSize: "14px"}}>
    <div role="rowgroup" className="react-spectrum-TableView-row spectrum-Table-head">
      <div className="spectrum-Table-headCell" style={{verticalAlign: 'middle'}}>Detail: {extension.module_name}</div>
    </div>
    <div className="spectrum-Table-body react-spectrum-TableView-body recommendationDetail" style={{borderBottom: "none", borderLeft: "none", borderRight: "none"}}>
      <div style={{border: 'none', borderBottom: 'none'}}>
        <div className="spectrum-Table-cell react-spectrum-Table-cell">
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Extension</Heading>
            <span>{extension.extension}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Source</Heading>
            <span>{extension.source}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Type</Heading>
            <span>{extension.type}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Vendor Bundle Extensions</Heading>
            <span>{extension.vbe}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Vendor</Heading>
            <span>{extension.vendor}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Version</Heading>
            <span>{extension.installed_version}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Latest</Heading>
            <span>{extension.latest_version}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Compatibility</Heading>
            <span>{extension.latest_version_compatibility}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Marketplace</Heading>
            <span>{extension.on_marketplace}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Installed</Heading>
            <span>{extension.installed}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Enabled</Heading>
            <span>{extension.enabled}</span>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Updated</Heading>
            <span>{extension.updated}</span>
          </div>
        </div>
      </div>
    </div>
  </div>);
};

export default ExtensionDetail;
