import React, { createContext, useContext, useState, useEffect } from 'react';
import {checks} from "../Checklist/checks";
import usePreLaunchChecks from "state/cloudReports/hooks/usePreLaunchChecks";
const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  let initialBlockersCount = 0;
  let initialCheckBoxes = [];
  let totalIdealScore = 0;

  const [preLaunchChecks] = usePreLaunchChecks();

  for (const check in checks) {
    checks[check].checkboxList.map((checkbox, index) => {
      initialCheckBoxes.push(
        {
          check: checkbox,
          id: `check:${checkbox.name}`,
          checked: false
        }
      );

      totalIdealScore += checkbox.score;

      if (checkbox.severity === 1) {
        initialBlockersCount++;
      }
    });
  }

  const [globalState, setGlobalState] = useState({
    // Define your initial global state here
    counter: 0,
    score: null,
    checkboxes: initialCheckBoxes,
    blockers: initialBlockersCount,
    totalIdealScore: totalIdealScore,
    textAreas: []
  });

  const incrementCounter = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      counter: prevState.counter + 1,
    }));
  };

  const setScore = (score) => {
    setGlobalState((prevState) => ({
      ...prevState,
      score,
    }));
  };

  const setBlockers = (blockers) => {
    setGlobalState((prevState) => ({
      ...prevState,
      blockers,
    }));
  };

  const setCheckboxes = (checkboxes) => {
    setGlobalState((prevState) => ({
      ...prevState,
      checkboxes,
    }));
  };

  const setTextAreas = (textAreas) => {
    setGlobalState((prevState) => ({
      ...prevState,
      textAreas,
    }));
  };

  useEffect(() => {
    let textAreas = [];
    if (globalState.checkboxes.length > 0) {
      if ('saved' in preLaunchChecks) {
        let savedChecks = preLaunchChecks['saved'];
        let previousScore = 0;
        let totalBlockersCompleted = 0;
        const updatedCheckboxes = globalState.checkboxes.map((checkbox) => {
          if (checkbox.id in savedChecks && savedChecks[checkbox.id] === checkbox.id) {
            checkbox.checked = true;
            previousScore += 'score' in checkbox.check ? checkbox.check.score : 0 ;
            if ('severity' in checkbox.check && checkbox.check.severity === 1) {
              totalBlockersCompleted++;
            }
            return { ...checkbox, checked: true }
          } else {
            return checkbox
          }
        });


        previousScore = previousScore < 0 ? 0 : previousScore;
        let blockersCount = globalState.blockers - totalBlockersCompleted;
        blockersCount = blockersCount < 0 ? 0 : blockersCount;

        setCheckboxes(updatedCheckboxes);
        setScore(previousScore);
        setBlockers(blockersCount);
      }
    }

    if ('saved' in preLaunchChecks) {
      let savedChecks = preLaunchChecks['saved'];
      if (savedChecks) {
        for (const [key, value] of Object.entries(savedChecks)) {
          if (key.includes("textarea:")) {
            textAreas.push({'id': key, 'content': value});
          }
        }
      }
      setTextAreas(textAreas);
    }
  }, [preLaunchChecks]); // Empty dependency array ensures this effect runs only once


  return (
    <AppContext.Provider value={{ globalState, incrementCounter, setScore,  setBlockers, setCheckboxes, setTextAreas}}>
      {children}
    </AppContext.Provider>
  );
};
