import {getConfig} from 'config';
import {getData} from '../dataProvider';

export const loadDashboard = queryString => {
  return getData(`${getConfig('dashboardUrl')}${queryString}`);
};

export const loadClients = (filterText, cursor, signal) => {
  if (cursor) {
    cursor = cursor.replace(/^http:\/\//i, 'https://');
  }
  const filter = filterText ? '?search=' + filterText : '';
  let url = cursor || `${getConfig('clientsUrl')}${filter}`
  return fetch(url, { signal, credentials: 'include'});
}
