import React from "react";
import PickerController from "../inputFields/picker";

function AdobeCommerceVersionField({ control, setValue, errors, fields }) {
  const adobeCommerceVersion = fields.adobeCommerceVersion;

  return (
    <PickerController
      required={true}
      label={adobeCommerceVersion.label}
      validationState={errors[adobeCommerceVersion.name] ? "invalid" : null}
      name={adobeCommerceVersion.name}
      items={adobeCommerceVersion.items}
      control={control}
      setValue={setValue}
      defaultValue={adobeCommerceVersion.value}
    />
  );
}

export default AdobeCommerceVersionField;
