import React from "react";
import {useState} from "react";

import { getRecommendations } from "../queries";


export const useCaseDeflection = () => {
  const [recommendations, setRecommendations] = useState(null);

  const loadCaseDeflection = async (issueDescription, issueTicket) => {
    try {
      getRecommendations({issueDescription, issueTicket}).then((response) => {
        setRecommendations(response.data);
      });
    } catch (error) {
    }
  }
  return [recommendations, loadCaseDeflection];
}
