import { useState } from "react";
import { useStateValue } from "state/index";
import { loadSupportCasesTab } from "../queries";
import { setFormFields } from "../actions";
import { onException } from "../../httpStatusHandler";

export const useSupportCasesTab = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (issueDescription) => {
    dispatch(setFormFields([]));
    setIsLoading(true);
    try {
      const response = await loadSupportCasesTab(
        { issueDescription }
      );
      dispatch(setFormFields(response.data));
    } catch (error) {
      setError(error);
      onException(error, dispatch, state);
    } finally {
      setIsLoading(false);
    }
  };

  return [state.supportCases.formFields, isLoading, error, request];
};

export default useSupportCasesTab;
