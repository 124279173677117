import React from 'react';
import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
  Text
} from '@adobe/react-spectrum';

import { useLocation, useHistory } from "react-router-dom";

import styled from 'styled-components';
import { useSplash }  from 'state/splash/hooks/useSplash';
import {useStateValue} from "state";
import {displaySplashPage} from "state/dashboard/actions";
import useDashboard from "state/dashboard/hooks/useDashboard";

const H3 = styled.span`
  text-align: left;
  font-size: 20px;
  color: #323232;
  text-transform: none;
`;

const RegularText = styled.span`
  text-align: left;
  font-size: 18px;
  color: #2c2c2c;
  opacity: 1;
  margin: 0 0 20px 0;
  display: block;
`;
const H4 = styled.span`
  text-align: left;
  display: block;
  font-size: 16px;
  font-style: italic;
  color: #2c2c2c;
  margin: 10px 0 10px 0;
`;
const P = styled.span`
  text-align: left;
  font-size: 14px;
  font-style: italic;
  display:block;
  color: #505050;
  margin: 0 0 5px 0;
`;

const SplashPage = () => {
  const location = useLocation();
  let history = useHistory();
  const [, dispatch] = useStateValue();
  const [acceptSplash, cancelSplash] = useSplash();
  const [dashboard, isLoading, getDashboardData] = useDashboard();

  const actionAccept = () => {
    acceptSplash(() => dispatch(displaySplashPage(false)));
  };

  const actionCancel = () => {
    cancelSplash(() => {window.close();});
  };
  return (
    <>
      <DialogTrigger defaultOpen={true}>
        <ActionButton isHidden="true" id="dialogButton">Register</ActionButton>
        <Dialog size="L">
          <Heading><H3>Access the Site-Wide Analysis Tool</H3></Heading>
          <Divider />
          <Content>
            <Text>
              <RegularText>Read and accept the terms below to access the Site-Wide Analysis Tool.</RegularText>
              <P>This Site-Wide Analysis Tool Addendum ("Addendum") governs your use of the Site-Wide Analysis Tool and related integrated services (collectively, the "Service"). This Addendum is entered between Customer (“You”) and Adobe, Inc. and/or its Affiliates, including but not limited to X.Commerce, Inc. d/b/a Magento, Inc. (collectively, “Adobe”), and supplements and forms a part of the applicable license agreement, subscription agreement, and/or Terms of Service (the "Agreement") governing your use of Adobe products and services, as well as any tools, applications, servers or programs which support functionality of the Adobe Commerce platform (collectively, "Adobe Commerce Software"). In the event of a conflict between the Agreement and this Addendum, this Addendum shall control to the extent necessary to resolve such conflict.</P>
              <H4>Use of Service</H4>
              <P>BY CLICKING ON THE “ACCEPT” BUTTON, YOU AGREE (1) THAT YOU HAVE THE AUTHORITY AND ARE AUTHORIZED TO ACT ON BEHALF OF THE CUSTOMER TO ENTER IN TO AND BE BOUND BY THIS ADDENDUM, INCLUDING ANY FUTURE REVISIONS POSTED ON ADOBE’S WEBSITE, AND (2) TO USE THE SERVICE IN COMPLIANCE WITH APPLICABLE LAW AND ANY REQUIREMENTS OR POLICIES PROVIDED BY ADOBE AS MAY BE UPDATED FROM TIME TO TIME. IF YOU DO NOT AGREE TO THE TERMS OF THIS ADDENDUM, DO NOT CLICK THE “ACCEPT” BUTTON OR USE THE SERVICE.</P>
              <H4>Limitations on Use; Access by Adobe</H4>
              <P>You understand and agree that this Service is intended exclusively for providing you details of Your website(s) that use Adobe Commerce Software, including for the purposes of scanning and reporting potential or suspected vulnerabilities and/or malware that may affect the performance, operation, and/or security of such website(s), subject to the following conditions:</P>
              <P>Use of the Service and acceptance of this Addendum does not, nor is it intended to, replace or limit the following: 1) Your use of other website security and monitoring tools, controls, and/or safeguards that may provide additional or other relevant information to facilitate the security of Your site(s), including all content or data it may collect, disclose and/or process; 2) Your full compliance with applicable data security and/or privacy laws and regulations; 3) the need for You to independently utilize or engage professional support from qualified experts to continually monitor, manage, and secure your network, platform, and systems linked or accessible from the internet; and 4) the scope of Your responsibility, indemnity and liability to Adobe or any other third parties by virtue of agreeing to this Addendum and Your use of the Service.</P>
              <P>You acknowledge and agree that Adobe may access, use, copy, and store Customer Data, Customer Content, Customer Customizations, User interactions, and system performance to analyze and test Customer’s implementation of the Adobe Commerce platform and derive information to provide the Service and which may be used to develop, build, modify, improve, support, and operate the Customer’s implementation of the Adobe Commerce platform.</P>
              <P>By agreeing to the terms of this Addendum and using the Service, you understand and agree that Adobe may initiate the Service to scan your website(s) with Adobe Commerce Software and/or access the results of any scan initiated by You when using the Service. Adobe will perform such scans or access scan results for purposes of facilitating the identification and remediation of potential security concerns that may present an ongoing risk to You and/or other participating Customers.</P>
              <H4>Restrictions</H4>
              <P>Your use of the Service is subject to the following restrictions: (a) You may use the Service only to scan site(s) that You are authorized to manage, monitor or administer to an extent consistent with Your use of the Service, and (b) You may only use the Service for Your own internal business purposes, in accordance with the Agreement and this Addendum. Should you fail to abide by any restrictions set forth in the Agreement or this Addendum, you agree that Adobe, in its sole discretion, may exercise its right to suspend or terminate your access to the Service.</P>
              <H4>Disclaimer</H4>
              <P>Adobe disclaims any and all liability for damages, claims, causes of action, fines or penalties incurred by You in relation to use of the Service. Adobe is not responsible for the accuracy of the results of any scan performed by the Service or any act or omission by You based on scan results from use of the Service. Your use of the Service in no way modifies, nor is intended to modify, the scope of Your responsibility to at all times ensure the security, performance, operation, content and management of Your Customer Content and the Adobe Commerce Software.</P>
              <H4>Indemnification</H4>
              <P>You will defend, indemnify, and hold harmless Adobe, its Affiliates and licensors, and each of their respective employees, officers, directors, and representatives from and against any Losses arising out of or relating to any third-party claim concerning Your use of the Service in contravention of this Addendum.</P>
              <H4>No Warranty</H4>
              <P>YOU AGREE THAT YOUR USE OF THE SERVICE, PROVIDED WITHOUT CHARGE, IS SOLELY AT YOUR OWN RISK. YOU FURTHER ACKNOWLEDGE AND AGREE THAT THE SCANNING OF SITES MAY EXPOSE MALWARE AND OTHER VULNERABILITIES, AND IN SOME CIRCUMSTANCES COULD RESULT IN THE DISRUPTION OF SERVICES TO YOUR SITE(S). THE SERVICE AND ANY UPDATES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND WHATSOEVER, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. ADOBE SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. Without limiting the foregoing, Adobe makes no warranty that the Service will be provided error-free, complete, free from interruption or failure, or secure from any unauthorized access, or that the Service will detect all forms of malware or every vulnerability or threat to your site(s).</P>
              <H4>Limitation of Liability</H4>
              <P>THE TOTAL LIABILITY OF ADOBE ARISING OUT OF OR RELATED TO THIS ADDENDUM AND USE OF THE SERVICE SHALL NOT EXCEED $100. IN NO EVENT SHALL ADOBE HAVE LIABILITY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, OR LOSS OF BUSINESS INFORMATION), HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, EVEN IF ADOBE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</P>
              <H4>Cancellation</H4>
              <P>Adobe may modify or cancel the Service at any time, for any reason, with or without notice.</P>
              <H4>Collaborative Effort</H4>
              <P>The Service is intended as part of a collaborative effort between Adobe and each Customer (including without limitation contractors, agents, and/or permitted Affiliates of Customer having access to Customer’s Account) to improve the security and operability of the Adobe Commerce platform. If the Customer requires further explanation of any Service data, the Customer must open an <a target="_blank" href={'https://experienceleague.adobe.com/docs/commerce-knowledge-base/kb/help-center-guide/magento-help-center-user-guide.html?lang=en#submit-ticket'}>Adobe Commerce Support ticket</a>, referencing the relevant details, such as, the title and date of the Site-Wide Analysis Tool Report or other applicable report to which the question relates (as depicted on the title page of the report), or screenshot(s) of the applicable dashboard page showing the finding(s) as applicable and any other details that may help Support personnel identify the information in question. Please be sure to link the new Adobe Commerce Support ticket to any related existing Support requests to expedite a response. Customers who fail to follow this process correctly may be asked to provide additional information and/or be subject to other delays before Support is able to provide a response.</P>
              <P>Any technical analyses, methodologies, processes or findings disclosed by Adobe to the Customer in connection with information provided by the Service are not intended to modify, clarify, or increase Adobe’s limited responsibilities to maintain, monitor and secure the websites or custom deployments of its Customers who use the Adobe Commerce platform beyond the obligations it has expressly agreed to in the license or subscription terms (or any similar agreement); documentation published online detailing each party's respective responsibilities to secure, manage and operate the platform and related services; or as may be required by law. Adobe makes no representations or express warranties and disclaims all implied warranties with respect to any information made available through the use of the Service to the extent permitted by law.</P>
              <H4>Shared Responsibility</H4>
              <P>The use of and reliance on the Service and any Service reports are subject to the parties’ respective obligations and responsibilities outlined in Adobe Commerce’s <a  target="_blank" href={"https://www.adobe.com/content/dam/cc/en/security/pdfs/MagentoSharedResponsibilityGuide.pdf"}>Shared Responsibility Model whitepaper</a>. This includes, but is not limited to the Customer's sole and exclusive responsibility to make its own independent determination as to the validity and reliability of any Service data and report findings; conducting its own assessment of the severity and scope of any steps needed to remediate potential or suspected risks; and making any necessary modifications to the Customer's website, its deployment of Adobe Commerce services and software, third party add-on features and integrations, and any other customizations configured, determined, defined or otherwise under the Customer's control.</P>
            </Text>
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={actionCancel} data-ui-id="swat-terms-cancel-button">
              Cancel
            </Button>
            <Button variant="cta" onPress={actionAccept} data-ui-id="swat-terms-accept-button">
              Accept
            </Button>
          </ButtonGroup>
        </Dialog>
      </DialogTrigger>
    </>
  );
};
export default SplashPage;
