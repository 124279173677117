import React from "react";
import CaseDeflection from "../caseDeflection";
import { Button, Flex, Heading, Divider } from "@adobe/react-spectrum";
import useSolutionFound from "../../../../state/supportCasesTab/hooks/useSolutionFound";

function StepTwo({issueDescription, setIssueDescription, issueTicket, setIssueTicket, setPage}) {

  const sendSolutionFound = () => {
    useSolutionFound({issueTicket});
    setIssueTicket("");
    setIssueDescription("");
  };

  return (
    <Flex direction={"column"} gap={"size-100"} alignItems={"center"}>
      <Heading level={3}>
        These might help solve your issue.
      </Heading>
      <Divider size={"M"} marginBottom={"size-100"} />
      <CaseDeflection issueDescription={issueDescription} issueTicket={issueTicket} />
      <Flex
        marginTop={"size-500"}
        direction={"row"}
        justifyContent={"center"}
        gap={"size-300"}
      >
        <Button
          variant={"secondary"}
          style={"fill"}
          type={"submit"}
          onPress={() => setPage(1)}
        >
          Go Back
        </Button>
        {/*
        <Button
          variant={"secondary"}
          style={"fill"}
          type={"submit"}
          onPress={() => setPage(3)}
        >
          Continue to Case Submission
        </Button>
        */}
        <Button
          variant={"cta"}
          style={"fill"}
          type={"submit"}
          onPress={() => {
            sendSolutionFound();
            setPage(1);
          }}
          data-ui-id={"case-submission-solution-found"}
        >
          Solution Found
        </Button>
      </Flex>
    </Flex>
  );
}

export default StepTwo;
