import React from "react";

const defaultValues = {
  issues: [
    {
      issues: "Severe",
      value: 0,
    },
    {
      issues: "High",
      value: 0,
    },
    {
      issues: "Elevated",
      value: 0,
    },
    {
      issues: "Medium",
      value: 0,
    },
  ],
  list: [
    {
      label: "Scan last run",
      value: "Unknown",
    },
    {
      label: "End of life services",
      value: 0,
    },
    {
      label: "Security Bulletin",
      value: 0,
    },
    {
      label: "Recommendations",
      value: 0,
    },
    {
      label: "Security Scan",
      value: 0,
    },
  ],
  status: 0,
};

function mergeObjects(obj1, obj2) {
  obj2.issues.forEach(issue2 => {
    const issue1 = obj1.issues.find(issue => issue.issues === issue2.issues);
    if (issue1) {
      issue1.value = issue2.value;
    } else {
      obj1.issues.push(issue2);
    }
  });

  obj2.list.forEach(listItem2 => {
    const listItem1 = obj1.list.find(listItem => listItem.label === listItem2.label);
    if (listItem1) {
      listItem1.value = listItem2.value;
    } else {
      obj1.list.push(listItem2);
    }
  });

  if (obj2.hasOwnProperty('status')) {
    obj1.status = obj2.status;
  }

  return obj1;
}


function defaultValueMerge(payloadValues) {
  return mergeObjects(defaultValues, payloadValues);
}

export default defaultValueMerge;
