import React, { useEffect, useState } from "react";
import { View, Flex, ProgressCircle} from "@adobe/react-spectrum";
import PastOutages from "./components/pastOutages";
import OutageDetails from "./components/outageDetails";
import { useOutagesTab } from "state/outages/hooks/useOutagesTab";
import useDashboard from "../../state/dashboard/hooks/useDashboard";
import InternalOnlyMessage from "../sharedComponents/internalOnlyMessage";

const OutagesTab = () => {
  const [outageIncidents, outageIssues, informationLinks, isLoading, request] =
    useOutagesTab();
  // Incident Info is an array that contains the incident ID in the first index and the incident timestamp in the second index
  const [incidentInfo, setIncidentInfo] = useState([]);
  const [dashboard, ,] = useDashboard();
  useEffect(() => {
    request();
  }, [dashboard.client]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate />;
  }

  return (
    <>
      <InternalOnlyMessage />
      <Flex gap={"size-250"}>
        <View width={"50%"}>
          <PastOutages
            stateChanger={setIncidentInfo}
            outageIncidents={outageIncidents}
          />
        </View>
        <OutageDetails
          outageIncidents={outageIncidents}
          incidentInfo={incidentInfo}
          outageIssues={outageIssues}
          informationLinks={informationLinks}
        />
      </Flex>
    </>
  );
};

export default OutagesTab;
