import React, {useState} from "react";
import ExtensionsGrid from "./components/extensionsGrid";
import {View} from "@adobe/react-spectrum";
import FilterExtensionsButton from "./components/filterExtensionsButton";
import Provider from "@react/react-spectrum/Provider"

const ExtensionsTab = () => {
  const [extensionsCount, setExtensionCount] = useState(0);
  const [totalExtensionsCount, setTotalExtensionsCount] = useState(0);
  return (
    <div className={'extensionsTab'}>

      <View marginBottom={"size-150"}>These extensions are installed on your Adobe Commerce instance. Adobe Commerce Marketplace information provided where available for extensions listed there.</View>
      <Provider>
        <FilterExtensionsButton extensionsCount={extensionsCount} totalExtensionsCount={totalExtensionsCount} />
        <ExtensionsGrid setExtensionCount={setExtensionCount} setTotalExtensionsCount={setTotalExtensionsCount} />
      </Provider>
    </div>
  );
};

export default ExtensionsTab;
