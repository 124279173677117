import React from 'react';

function InternalOnlyMessage() {
  return (
    <div
      className="patches-callout patches-callout-warning"
      style={{ margin: "0 0 20px 0" }}
    >
      This page is only visible for Adobe Employees.
    </div>
  )
}

export default InternalOnlyMessage;

