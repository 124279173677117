import { useState } from 'react';

import { useStateValue } from 'state/index';
import { loadDashboard } from '../queries';
import {setDashboard, displaySplashPage, displayImsErrorPage} from '../actions';
import { onException } from "state/httpStatusHandler";
import {useHistory} from "react-router-dom";

const useDashboard = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  const request = async (queryString, callback) => {
    if (isLoading) {
      return [state.dashboard, isLoading, request];
    }
    setIsLoading(true);

    await loadDashboard(queryString, callback)
      .then((response) => {
        if (response && response.hasOwnProperty('data') && response.data.hasOwnProperty('redirect')) {
          localStorage.setItem('redirectToAfterAuth', history.location.pathname);
          window.location = response.data.redirect;
        }
        if (response && response.hasOwnProperty('data') && response.data.hasOwnProperty('imsErrorCode')) {
          dispatch(displayImsErrorPage(response.data.imsErrorCode))
        }
        if (response && response.hasOwnProperty('data')
          && !response.data.hasOwnProperty('showSplashPageFlag')
        ) {
          dispatch(displaySplashPage(false));
        }
        if (response && response.hasOwnProperty('data')) {
          dispatch(setDashboard(response.data));
        }
        if (callback) {
          callback()
        }
      })
      .catch(errorObject => onException(errorObject, dispatch, state));

    setIsLoading(false);
  };

  return [state.dashboard, isLoading, request];
};

export default useDashboard;
