import { useState } from 'react';
import { useStateValue } from 'state/index';
import { loadPreLaunchChecks } from '../queries';
import {setPreLaunchData} from '../actions';
import { onException } from "state/httpStatusHandler";

export const usePreLaunchChecks = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const request = async () => {
    setIsLoading(true);

    await loadPreLaunchChecks()
      .then(response => {
        let setPreLaunchDataStatus = setPreLaunchData(response.data);
        dispatch(setPreLaunchDataStatus);
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
      setIsLoading(false);
  };

  return [state.preLaunchChecks, isLoading, request];
};

export default usePreLaunchChecks;
