import React from 'react';
import {IntlProvider} from "@quarry/intl";
import {Dashboard} from '@quarry/dashboard';

import UctWidget from "./components/uctWidget";
import ExternalResourcesWidget from "./components/externalResourcesWidget";
import RecommendedPatchesWidget from "./components/recommendedPatchesWidget";
import ExtensionsWidget from './components/extensionsWidget'
import RecommendationWidget from "./components/recommendationWidget";
import AlertWidget from "./components/alertWidget";
import RecommendedVersionsWidget from "./components/recommendedVersionsWidget";
import ApplicationInfoWidget from "./components/applicationInfoWidget";
import useClientAccess from "state/dashboard/hooks/useClientAccess";
import SecurityCenterWidget from "./components/securityCenterWidget";

let numberOfCloudWidgets = 8
let numberOfOnPremWidgets = 6

let cloudWidgets = [
  <RecommendationWidget/>,
  <UctWidget/>,
  <SecurityCenterWidget/>,
  <RecommendedVersionsWidget/>,
  <RecommendedPatchesWidget/>,
  <ApplicationInfoWidget/>,
  <ExtensionsWidget/>,
  <AlertWidget/>,
  <ExternalResourcesWidget/>,
]
let onPremWidgets = [
  <RecommendationWidget/>,
  <UctWidget/>,
  <SecurityCenterWidget/>,
  <RecommendedVersionsWidget/>,
  <ApplicationInfoWidget/>,
  <ExternalResourcesWidget/>,
  <ExtensionsWidget/>,
]

const getWidgetComponent = (id, isCloud) => {
  if (isCloud) {
    return cloudWidgets[id];
  } else {
    return onPremWidgets[id];
  }
}

const setDefaultLayout = numberOfWidgets => {
  let defaultLayout = []
  let x = 0
  let y = 0
  for(let id = 0; id < numberOfWidgets; id++) {
    if (id % 3) {
      x = x + 4
    } else {
      x = 0
      y = y + 5
    }
    let widgetLayout = {"id":id.toString(), "x": x, "y": y, "w":4, "h": 5}
    defaultLayout.push(widgetLayout)
  }
  return defaultLayout
}

const WidgetTab = () => {
  const hasClientAccess = useClientAccess();
  let numberOfWidgets = hasClientAccess('On-Prem') ? numberOfOnPremWidgets : numberOfCloudWidgets;

  return <IntlProvider
    defaultFormats={{}}
    defaultLocale="en"
    formats={{}}
    locale="en"
    messages={{}}
    onError={function noRefCheck(){}}
    textComponent={Symbol('react.fragment')}
    timeZone={undefined}
  >
    <Dashboard
      isResizable={false}
      layout={setDefaultLayout(numberOfWidgets)}
      onLayoutChange={() => {}}
      dashboardActionsClassName={'dashboardActions'}
      showAddWidget={false}
      showActions={false}
      renderWidget={layoutItem => getWidgetComponent(
        parseInt(layoutItem.id),
        hasClientAccess('magento-commerce-cloud')
      )}/>
  </IntlProvider>
};

export default WidgetTab;
