import React from "react";
import { useState, useEffect } from "react";
import {
  TextField,
  TextArea,
  Button,
  Flex,
  Heading,
  Divider,
} from "@adobe/react-spectrum";

function StepOne({
  setPage,
  issueTicket,
  setIssueTicket,
  issueDescription,
  setIssueDescription,
}) {
  let [buttonDisabled, setButtonDisabled] = useState(true);

  const DescriptionWithHelp = () => {
    return <span>
      Description (do not enter personal identifiable information and read <a href="https://inside.corp.adobe.com/content/dam/corporate-policies/documents/LGL-SOP-01-005_Employee_Privacy_Policy.pdf">Adobe Employee Privacy Policy</a>)
    </span>
  };

  useEffect(() => {
    if (issueDescription) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [issueDescription]);

  return (
    <Flex direction={"column"} gap={"size-100"} alignItems={"center"}>
      <Heading level={3}>Describe Your Adobe Commerce Issue</Heading>
      <Divider size={"M"} marginBottom={"size-100"} />
      <TextField
        label="Ticket ID (Zendesk or Jira)"
        width="550px"
        value={issueTicket}
        onChange={setIssueTicket}
        maxWidth="100%"
      />
      <TextArea
        id={"issueDescription"}
        label={<DescriptionWithHelp/>}
        value={issueDescription}
        onChange={setIssueDescription}
        width="550px"
        maxWidth="100%"
      />
      <Button
        marginTop={"size-250"}
        variant={"cta"}
        style={"fill"}
        type={"submit"}
        isDisabled={buttonDisabled}
        onPress={() => setPage(2)}
      >
        Next
      </Button>
    </Flex>
  );
}

export default StepOne;
