import {SET_EXCEPTIONS} from './actions';

export const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXCEPTIONS:
      return action.payload;
    default:
      return state;
  }
};
