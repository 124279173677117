import { useState } from 'react';
import { useStateValue } from 'state/index';
import { sendToZD } from '../queries';
import { onException } from "state/httpStatusHandler";
import { success } from "@react/react-spectrum/Toast";

const useZenDesk = () => {
  const [state, dispatch] = useStateValue();
  const [isSending, setIsSending] = useState(false);

  const send = async (hash, filters, callback) => {
    setIsSending(true);

    await sendToZD(hash, filters)
      .then (response => {
        success(response.data.text, {timeout: 15000});
        callback();
      })
      .catch(errorObject => onException(errorObject, dispatch, state));

    setIsSending(false);
  };

  return [isSending, send];
};

export default useZenDesk;
