import React, {useEffect, useState} from 'react';
import useRecommendations from "state/recommendations/hooks/useRecommendations";
import {TableView} from '@react/react-spectrum/TableView';
import ListDataSource from "@react/react-spectrum/ListDataSource";
import {Flex, ProgressCircle, View} from "@adobe/react-spectrum";
import RecommendationDetail from "./recommendationDetail";
import {IndexPath} from "@react/collection-view";
import './recommendationsGrid.css';
import p0 from './img/priority-critical.png';
import p1 from './img/priority-urgent.png';
import p2 from './img/priority-high.png';
import p3 from './img/priority-medium.png';
import p4 from './img/priority-low.png';
import useDashboard from "state/dashboard/hooks/useDashboard";

class RecommendationsDataSource extends ListDataSource {
  recommendationsData = [];
  recommendations = [];
  async load() {
    return this.recommendations;
  }

  getRecommendations() {
    return this.recommendations;
  }

  searchIndex(recommendation) {
    if (recommendation) {
      for (let i = 0; i < this.recommendations.length; i++) {
        if (this.recommendations[i].issue_code === recommendation.issue_code) {
          return i;
        }
      }
    }
    return 0;
  }

  filterRecs(selectedFilters) {
    let filters = [];
    filters['type'] = [];
    filters['priority'] = [];
    filters['status'] = [];
    selectedFilters.forEach(filter => {
      const f = filter.split("_");
      filters[f[0]].push(f[1]);
    })
    this.recommendations = this.recommendationsData.filter(function (rec) {
      const hasType = filters['type'].includes(rec.issue_type.toLowerCase())
        || filters['type'].includes('all')
        || filters['type'].length === 0;
      const hasPriority = filters['priority'].includes(rec.issue_priority)
        || filters['priority'].includes('all')
        || filters['priority'].length === 0;

      // If there are no filters applied, then we want to show all recommendations
      // Otherwise, we want to show only the recommendations that match the filters
      const hasStatus = filters['status'].length ? filters['status'].includes(String(rec.recommendation_status ?? 1)) : true
      return (hasType && hasPriority && hasStatus);
    });
    this.reloadData();
    this.setSelectedRecommendation(this.recommendations[0])
    this.filterCallback(this.recommendations.length);
  }
}
const columns = [
  {
    title: 'Recommendation Name',
    key: 'title',
    minWidth: 300,
    sortable: false,
    divider: false
  },
  {
    title: 'Type',
    key: 'issue_type',
    minWidth: 150,
    sortable: false,
    divider: false
  },
  {
    title: 'Risk Level',
    key: 'issue_priority',
    minWidth: 150,
    sortable: true,
    divider: false
  },
  {
    title: 'Status',
    key: 'status',
    minWidth: 120,
    sortable: false,
    divider: false
  },
  {
    title: 'Check Last Run',
    key: 'last_check_date',
    minWidth: 150,
    sortable: false,
    divider: false
  }
];

const recommendationsDataSource = new RecommendationsDataSource();

const RecommendationsGrid = props => {
  const [recommendations, isLoading, request] = useRecommendations();
  const [selectedRecommendation, setSelectedRecommendation] = useState({});
  const setGridLoaded = props.setGridLoaded;
  const [dashboard,,] = useDashboard();

  recommendationsDataSource.filterCallback = props.setRecommendationCount;
  recommendationsDataSource.setSelectedRecommendation = setSelectedRecommendation;
  useEffect(() => {
    request();

  }, [dashboard.client.hash]);
  useEffect(() => {
    if (!recommendationsDataSource.recommendationsData.length) {
      recommendationsDataSource.recommendationsData = recommendations
    }
    recommendationsDataSource.recommendations = recommendations
    setSelectedRecommendation(recommendationsDataSource.recommendations[0])
    props.setTotalRecommendationCount(recommendations.length);
    props.setRecommendationCount(recommendationsDataSource.recommendations.length);
    setGridLoaded(true);
  }, [recommendations])



  const renderCell = (column, data)  => {
    let text = data[column.key]
    let picture = false;
    if (column.key === 'issue_priority') {
      if (text === 'P0') {
        picture = p0;
        text = 'Severe';
      } else if (text === 'P1') {
        picture = p1;
        text = 'High';
      } else if (text === 'P2') {
        picture = p2;
        text = 'Elevated';
      } else if (text === 'P3') {
        picture = p3;
        text = 'Medium';
      } else {
        picture = p4;
        text = 'Low';
      }
    }

    // Status: 0 = not detected, 1 = detected, 2 = dismissed
    const status = data.recommendation_status ?? 1;
    const opacity = status === 1 ? 1 : .5;

    if (column.key === 'status') {
      switch (status) {
        case 0: text = 'Not Detected'; break;
        case 1: text = 'Detected'; break;
        case 2: text = 'Dismissed'; break;
      }
    }
    if (column.key === 'last_check_date') {
      const localTime = new Date(data.last_check_date_pure).toLocaleString();
      text = <time dateTime={data.last_check_date_pure} title={localTime}>{text}</time>;
    }

    return <span style={{opacity}}>
      {picture && <img src={picture} alt={text} style={{marginRight: "4px"}} />}<span style={{fontSize: "14px"}}>{text}</span>
    </span>;
  };

  const onCellClick = recommendationsDataSource  => {
    return (column, rowIndex) => {
      setSelectedRecommendation(recommendationsDataSource.getRecommendations()[rowIndex])
      if (window.aptrinsic) {
        window.aptrinsic('track', 'RecommendationOpened', {"issue_code":selectedRecommendation.issue_code, "issue_data": selectedRecommendation.issue_data});
      }

      if (window._satellite) {
        if (typeof(window.digitalData !== "undefined")){
          if (!(window.digitalData.event instanceof Array)){
            window.digitalData.event = [];
          }
          window.digitalData.event.push({
            element: selectedRecommendation.issue_code,
            type: "grid row",
            action: "click",
            widget: {
              name: selectedRecommendation.issue_code,
              type: "grid"
            },
            feature: "Recommendations"
          });
        }
        window._satellite.track("event");
      }
    }
  };
  const tableView = <TableView
    allowsSelection={true}
    allowsMultipleSelection={false}
    className="recommendationGrid"
    rowHeight={14}
    columns={columns}
    isQuiet
    defaultSortDescriptor={{column: columns[2], direction: TableView.SORT_ASCENDING}}
    onCellClick={onCellClick(recommendationsDataSource)}
    onSortChange={function (sortDescriptor) {
      if (sortDescriptor && sortDescriptor.column) {
        function stableSortingComparator(a, b) {
          if (a[sortDescriptor.column.key] === b[sortDescriptor.column.key]) {
            return 0;
          }

          return (a[sortDescriptor.column.key] > b[sortDescriptor.column.key]) ? sortDescriptor.direction : -sortDescriptor.direction;
        }
        recommendationsDataSource.recommendations.sort(stableSortingComparator)
      }
      if (recommendationsDataSource.recommendations && recommendationsDataSource.recommendations.length) {
        setSelectedRecommendation(recommendationsDataSource.recommendations[0])
      }
    }}
    dataSource={recommendationsDataSource}
    renderCell={renderCell}
    selectedIndexPaths={[new IndexPath(0, recommendationsDataSource.searchIndex(selectedRecommendation))]}
    style={{borderBottom: "none"}}
    quiet={true}
  />;
  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
  }
  return (
      <Flex direction="row" gap="size-200" minHeight="700px" style={{justifyContent: "center"}}>
        <View flexBasis="50%">{tableView}</View>
        {
          selectedRecommendation && <View flexBasis="50%">
            <RecommendationDetail recommendation={selectedRecommendation}/>
          </View>
        }
      </Flex>
  );
};

export default RecommendationsGrid;
export {recommendationsDataSource};
