import React, {useEffect, useState} from "react";
import {Flex, ProgressCircle, View} from "@adobe/react-spectrum";
import useExtensions from "state/extensions/hooks/useExtensions";
import ListDataSource from "@react/react-spectrum/ListDataSource";
import {TableView} from "@react/react-spectrum/TableView";
import ExtensionDetail from "./extensionDetail";
import "./assets/extensionsGrid.css";
import useDashboard from "../../../state/dashboard/hooks/useDashboard";

class ExtensionsDataSource extends ListDataSource {
  extensionsData = [];
  extensions = [];
  async load(sortDescriptor) {
    if (!this.extensionsData.length) {
      this.extensionsData = this.extensions
    }
    if (sortDescriptor) {
      this.extensions.sort(
        (a, b) => (a[sortDescriptor.column.key] > b[sortDescriptor.column.key])
          ? sortDescriptor.direction : -sortDescriptor.direction
      );
    }
    return this.extensions;
  }

  filter(selectedFilters) {
    let filters = {
      type: [],
      marketplace: [],
      vbe: [],
      enabled: []
    };
    selectedFilters.forEach(filter => {
      const f = filter.split('_');
      filters[f.shift()].push(f.join('_'));
    });

    if (!this.extensionsData.length) {
      this.extensionsData = this.extensions
    }
    this.extensions = this.extensionsData.filter(function (rec) {
      const hasType = filters['type'].includes(rec.type.toLowerCase().replace(/ /g,"_"))
        || filters['type'].includes('all')
        || filters['type'].length === 0;
      const hasOnMarketplace = filters['marketplace'].includes(rec.on_marketplace.toLowerCase())
        || filters['marketplace'].length === 0;
      const hasVbe = filters['vbe'].includes(rec.vbe.toLowerCase())
        || filters['vbe'].length === 0;

      let isEnabled = filters['enabled'].length === 0;
      filters['enabled'].forEach(item => {
        isEnabled = isEnabled || rec.enabled.toLowerCase().indexOf(item) !== -1
      });
      return (hasType && hasOnMarketplace && hasVbe && isEnabled);
    });
    this.reloadData();
    this.filterCallback(this.extensions.length);
  }
}

const extensionsDataSource = new ExtensionsDataSource();

const ExtensionsGrid = props => {
  const [extensions, isLoading, request] = useExtensions();
  const [chosenExtension, setChosenExtension] = useState(false);
  const [dashboard,,] = useDashboard();
  useEffect(() => {
    request();
  }, [dashboard.client]);

  useEffect(() => {
    extensionsDataSource.load().then(() => {
      if (extensionsDataSource.extensions.length && chosenExtension === false) {
        setChosenExtension(extensionsDataSource.extensions[0]);
      }
    });
    extensionsDataSource.extensions = extensions
    extensionsDataSource.filterCallback = props.setExtensionCount;
    props.setExtensionCount(extensionsDataSource.extensions.length);
    props.setTotalExtensionsCount(extensionsDataSource.extensions.length);
  }, [extensions])


  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
  }

  const renderCell = (column, data) => {
    let text = data[column.key];
    let cssClass = "";
    let fieldCssClass = "";
    let padding = "";
    if (column.key === "latest_version") {
      if (text.startsWith("Yes")) {
        cssClass = "latest-version-yes";
      } else if (text.startsWith("No")) {
        cssClass = "latest-version-no";
      }
    }
    return <View paddingRight={padding}>
      {cssClass && <span className={cssClass}> </span>}
      <span style={{fontSize: "14px"}} className={fieldCssClass}>{text}</span>
    </View>;
  };

  const onCellClick = extensionsDataSource => (column, rowIndex) => {
    setChosenExtension(extensionsDataSource.extensions[rowIndex])
  };

  const columns = [
    {
      title: "Module Name",
      key: "module_name",
      minWidth: 150,
      align: "left",
      sortable: true,
      divider: false
    },
    {
      title: "Version",
      key: "installed_version",
      minWidth: 75,
      maxWidth: 100,
      align: "left",
      sortable: false,
      divider: false
    },
    {
      title: "Latest",
      key: "latest_version",
      minWidth: 85,
      maxWidth: 100,
      align: "left",
      sortable: false,
      divider: false
    }
  ];

  const tableView = <TableView
    columns={columns}
    dataSource={extensionsDataSource}
    renderCell={renderCell}
    onCellClick={onCellClick(extensionsDataSource)}
    defaultSortDescriptor={{column: columns[0], direction: TableView.SORT_ASCENDING}}
    allowsSelection={false}
    className="extensions-grid"
    rowHeight={14}
    quiet={true}
  />;

  return <>
    {extensions.length !== 0 ?
      <Flex direction="row" gap="size-200" minHeight="700px" style={{justifyContent: "center"}}>
        <View flexBasis="50%">{tableView}</View>
        <View flexBasis="50%">
          <ExtensionDetail extension={chosenExtension}/>
        </View>
      </Flex>
      : <View><span style={{fontWeight: "bold"}}>No extensions installed</span></View>
    }
  </>
};

export default ExtensionsGrid;
export {extensionsDataSource};
