import React from 'react';
import './exceptionsGrid.css';

const ExceptionDetails = ({exception}) => {
  return (exception && <div className="spectrum-Table--quiet">
    <div className="spectrum-Table-body react-spectrum-TableView-body exceptionDetail" style={{borderBottom: "none", borderLeft: "none", borderRight: "none"}}>
      <div className="spectrum-Table-headCell">Last Detected (UTC):</div>
      <div>{exception.date}</div>
      <div className="spectrum-Table-headCell">Exception Details:</div>
      <div>{exception.message}</div>
      <div className="spectrum-Table-headCell">Count:</div>
      <div>{exception.count}</div>
    </div>
  </div>);
};

export default ExceptionDetails;
