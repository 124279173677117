import React from 'react';
import {DashboardWidget} from "@quarry/dashboard";

const widgetStyle = {
  padding: '10px 2px',
};

const Widget = ({title, children, button, menuItems, layoutItem, onMenuItemSelect,style, ...props}) => {
  return <DashboardWidget
      editing={"true"}
      title={title}
      className="dashboardWidget"
      onMenuItemSelect={onMenuItemSelect}
      menuItems={menuItems}
      style={widgetStyle}
      {...layoutItem}
      {...props}
    >
      {children}
  </DashboardWidget>
};

export default Widget;
