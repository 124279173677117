export const SET_PATCH_ITEMS = 'patches/SET_PATCH_ITEMS';
export const SET_IS_MQP_INSTALLED = 'patches/SET_IS_MQP_INSTALLED';
export const SET_MQP_VERSION = 'patches/SET_MQP_VERSION';

export const setPatchItems = payload => ({
  type: SET_PATCH_ITEMS,
  payload,
});
export const setIsMqpInstalled = payload => ({
  type: SET_IS_MQP_INSTALLED,
  payload,
});
export const setMqpVersion = payload => ({
  type: SET_MQP_VERSION,
  payload,
});
