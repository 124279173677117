import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Flex,
  View,
  Checkbox,
} from '@adobe/react-spectrum';
import Filter from '@spectrum-icons/workflow/Filter';
import { extensionsDataSource } from "./extensionsGrid";

const CheckboxHeading = styled.h6`
  font-family: 'adobe-clean-bold', Times, serif;
  font-size: 14px;
  color: #2c2c2c;
  letter-spacing: 0;
  margin-bottom: 10px;
`;
const TopLine = styled.hr`
  border: 1px solid #eaeaea;
  margin: 12px 0 16px 0;
  grid-area: divider;
`;

const FilterExtensionsButton = props => {
  const [selectedCheckboxes, setCheckboxes] = useState(new Set());

  const toggleCheckbox = id => {
    const set = new Set(selectedCheckboxes);
    if (selectedCheckboxes.has(id)) {
      set.delete(id);
      setCheckboxes(set);
    } else {
      set.add(id);
      setCheckboxes(set);
    }
  };
  const isSelectedCheckbox = id => {
    let result = false;
    if (selectedCheckboxes.has(id)) {
      result = true;
    }
    return result;
  };

  return (
    <DialogTrigger>
      <Flex direction="row" alignContent="center" alignItems="center">
        <View>
          <Button height="40px" UNSAFE_className="filterGridButton">
            <Filter />
          </Button>
        </View>
        <View>
          <span className="filterCounter">{props.extensionsCount} records out of {props.totalExtensionsCount}</span>
        </View>
      </Flex>
      {close => (
        <Dialog>
          <Heading>Filter Extensions</Heading>
          <TopLine />
          <Content>
            {<>
                <Flex direction="row">
                  <View width="25%">
                    <CheckboxHeading level="6">Type</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('type_all')}
                        isSelected={isSelectedCheckbox('type_all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_shared_package')}
                        isSelected={isSelectedCheckbox('type_shared_package')}
                      >
                        Shared Package
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_magento2-module')}
                        isSelected={isSelectedCheckbox('type_magento2-module')}
                      >
                        magento2-module
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_extension')}
                        isSelected={isSelectedCheckbox('type_extension')}
                      >
                        Extension
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="25%">
                    <CheckboxHeading level="6">On Marketplace</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('marketplace_yes')}
                        isSelected={isSelectedCheckbox('marketplace_yes')}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('marketplace_no')}
                        isSelected={isSelectedCheckbox('marketplace_no')}
                      >
                        No
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="25%">
                    <CheckboxHeading level="6">Vendor Bundle Extensions</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('vbe_yes')}
                        isSelected={isSelectedCheckbox('vbe_yes')}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('vbe_no')}
                        isSelected={isSelectedCheckbox('vbe_no')}
                      >
                        No
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="25%">
                    <CheckboxHeading level="6">Enabled</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('enabled_yes')}
                        isSelected={isSelectedCheckbox('enabled_yes')}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('enabled_no')}
                        isSelected={isSelectedCheckbox('enabled_no')}
                      >
                        No
                      </Checkbox>
                    </Flex>
                  </View>
                </Flex>
              </>
            }
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>
              Cancel
            </Button>
            <Button
              variant="cta"
              onPress={() => {
                extensionsDataSource.filter([...selectedCheckboxes]);
                close();
              }}
            >
              Apply
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
export default FilterExtensionsButton;
