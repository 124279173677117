import React from 'react';
import CheckSection from "./CheckSection";
import {
  Heading,
  View,
  Content,
  TableView,
  TableBody,
  Column,
  TableHeader,
  Cell,
  Row,
} from '@adobe/react-spectrum';

class Fastly extends CheckSection {

  getFastlyIoSettings () {
    let finalDomains = [];
    this.getInfoArray('fastlyDeepIo').map(( listValue ) => {
      if ('data' in listValue) {
        return listValue['data'].map(( d ) => {
          finalDomains.push(d);
        });
      }
    });
    return finalDomains;
  }
  getRightSection() {
    return (
      <div>
        <Heading level={2} marginTop={30}>Fastly Tester URL</Heading>
        <View
          borderWidth="thin"
          borderColor="dark"
          borderRadius="medium"
          padding="size-250"
          backgroundColor="white"
        >
          <Content>
            Fastly – ensure that the Fastly module is enabled and configured, and that pages and blocks are cached
            correctly:
          </Content>
          <div className={'fastly-tester-url'}>
            <a href={this.getInfoValue('fastlyTesterUrl')} target="_blank">
              {this.getInfoValue('fastlyTesterUrl')}
            </a>
          </div>
          <br/>
          <Content>
            <b>Cache Engine:</b> {this.getInfoValue('general-cache-engine') || "No data available"}
          </Content>
        </View>

        <Heading level={2} marginTop={30}>Image Optimisation Settings</Heading>
        <TableView>
          <TableHeader>
            <Column>Description</Column>
            <Column>Source</Column>
            <Column>Current Value</Column>
          </TableHeader>
          <TableBody>
            {this.getInfoArray('fastlyDeepIo').map(( listValue, index ) => {
              return (
                <Row key={index}>
                  <Cell>{listValue['Setting']}</Cell>
                  <Cell>{listValue['Source']}</Cell>
                  <Cell>{listValue['Value'].toString()}</Cell>
                </Row>
              );
            })}
            {this.getInfoArray('fastlyDeepIo').length === 0 && (
              <Row key={1}>
                <Cell>No data available</Cell>
                <Cell></Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </TableView>

        <Heading level={2} marginTop={30}>Other Configurations</Heading>
        <TableView>
          <TableHeader>
            <Column>Setting</Column>
            <Column>Value</Column>
          </TableHeader>
          <TableBody>
            <Row>
              <Cell>Fastly Ignore URL Params List</Cell>
              <Cell>{this.getInfoValue('fastlyIgnoredUrlParams')}</Cell>
            </Row>
          </TableBody>
        </TableView>

        <Heading level={2} marginTop={30}>Shield Location(s)</Heading>
        <p>The recommended default location is the closest to your Cloud service region.</p>
        <TableView>
          <TableHeader>
            <Column>Name</Column>
            <Column>Shield Location</Column>
          </TableHeader>
          <TableBody>
            {this.getInfoArray('fastlyShields').map(( listValue, index) => {
              return (
                <Row key={index}>
                  <Cell>{listValue['name']}</Cell>
                  <Cell>{listValue['shield'].toString()}</Cell>
                </Row>
              );
            })}
            {this.getInfoArray('fastlyShields').length === 0 && (
              <Row key={2}>
                <Cell>No data available</Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      </div>
    );
  }
}

export default Fastly;
