import {getConfig} from 'config';
import {postData} from '../dataProvider';

export const downloadReport = (hash, filters) => {
  return postData(
    getConfig('reportUrl'),
    {
      filters
    },
    {
      responseType: 'blob',
    }
  );
};
export const sendToZD = (hash, filters) => {
  return postData(
    getConfig('sendToZdUrl'),
    {filters},
    {
      withCredentials: true
    }
  );
};
