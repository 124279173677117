import React from 'react';
import styled from 'styled-components';

const Links = styled.div`
  text-align: right;
  padding: 0 40px;
  & > span {
    padding 0 15px;
  }
  & a, & span {
    color: #747474;
    font-size: 14px;
    line-height: 36px;
    text-decoration: none;
  }
`;
const Copyright = styled.div`
  text-align: right;
  padding: 0 40px;
  & > span {
    padding 0 15px;
  }
  & a, & span {
    color: #747474;
    font-size: 14px;
    line-height: 36px;
    text-decoration: none;
  }
`;
const FooterBlock = styled.footer`
  justify-content: space-between;
  display: flex;
  background-color: #F5F5F5;
  align-items: center;
`;

const Footer = () => {
  return (
    <FooterBlock>
      <Copyright>&copy; {new Date().getFullYear()} Adobe Commerce. All Rights Reserved</Copyright>
      <Links>
        <span>
          <a
            href="https://magento.com/sites/default/files/Magento-Privacy-Policy-CCPA-Updates.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </span>
        <span>
          <a
            href="https://magento.com/sites/default/files/Cookie-Policy.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Cookie Policy
          </a>
        </span>
        <span>
          <a
            href="https://magento.com/legal/terms/website"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>
        </span>
        <span>
          <a
            href="https://magento.com/legal/licensing"
            rel="noopener noreferrer"
            target="_blank"
          >
            License /Trademark
          </a>
        </span>
        <span>
          <a
            href="https://support.magento.com/hc/en-us"
            rel="noopener noreferrer"
            target="_blank"
          >
            Knowledge Base
          </a>
        </span>
      </Links>
    </FooterBlock>
  );
};

export default Footer;
