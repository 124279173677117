import React from "react";
import { Controller } from "react-hook-form";
import { Picker, Item } from "@adobe/react-spectrum";

function createPickerItems(items) {
  return items.map((item) => {
    return <Item key={item.value}>{item.name}</Item>;
  });
}

function PickerController({
  required,
  label,
  validationState,
  name,
  items,
  control,
  setValue,
  defaultValue,
}) {
  return (
    <Controller
      rules={{ required: required }}
      as={
        <Picker
          isRequired
          validationState={validationState}
          label={label}
          width={"100%"}
          onSelectionChange={(selected) => setValue(name, selected)}
          defaultSelectedKey={defaultValue ? defaultValue : ""}
        >
          {createPickerItems(items)}
        </Picker>
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}

export default PickerController;
