import React from 'react';
import Widget from "./widget";
import {
  TableView,
  TableHeader,
  Column,
  Row,
  TableBody,
  Cell,
  Flex,
} from '@adobe/react-spectrum';
import useDashboard from "state/dashboard/hooks/useDashboard";
import {Link} from '@adobe/react-spectrum';
import {GetUiId} from "../../base/components/jsUtilities";

let columns = [
  {name: 'Name'},
  {name: 'Value'},
];

const renderValue = item => {
  if (item.declaration.frontend_component.type === 'url') {
    switch (item.declaration.frontend_component.label_source) {
      case 'data':
        return <Link isQuiet><a target="_blank" href={item.result} data-ui-id={GetUiId(item.declaration.label) + "-widget-link"}>{item.declaration.label}</a></Link>
      case 'parse_data':
        let chunks = item.data.split('|');
        return <Link isQuiet><a target="_blank" href={chunks.shift()}  data-ui-id={GetUiId(chunks.join('-')) + "-widget-link"}>{chunks.join('|')}</a></Link>
      case 'label':
        return <Link isQuiet><a target="_blank" href={item.result} data-ui-id={GetUiId(item.declaration.frontend_component.label_text) + "-widget-link"}>{item.declaration.frontend_component.label_text}</a></Link>
    }
  }
  return item.result
}

const ApplicationInfoWidget = layoutItem => {
  const [dashboard,,] = useDashboard();

  return <Widget layoutItem={layoutItem} title="Application Info">
      <Flex width={'100%'} direction="column" UNSAFE_style={{overflow: 'hidden'}}>
        <TableView flexGrow isQuiet UNSAFE_style={{margin: '0 -1rem' ,border:'none'}}>
          <TableHeader>
            <Column align={'start'} width={'60%'}>
              {columns[0].name}
            </Column>
            <Column align={'start'} width={'40%'}>
              {columns[1].name}
            </Column>
          </TableHeader>
            <TableBody items={dashboard.widget.applicationInfo} UNSAFE_stye={{border:'none'}} >
              {item => (
                <Row>
                  <Cell>
                    {item.declaration.label}
                  </Cell>
                  <Cell>
                    {renderValue(item)}
                  </Cell>
                </Row>
              )}
            </TableBody>
        </TableView>
      </Flex>
    </Widget>;
};

export default ApplicationInfoWidget;
