export const SET_PRODUCT_SEARCH_RESULTS = 'live_search/SET_PRODUCT_SEARCH_RESULTS';
export const SET_IS_LOADING = 'live_search/SET_IS_LOADING';

export const setProductSearchResults = payload => ({
  type: SET_PRODUCT_SEARCH_RESULTS,
  payload,
});

export const setIsLoading = payload => ({
  type: SET_IS_LOADING,
  payload,
});
