import Unavailable from '@spectrum-icons/illustrations/Unavailable';
import {IllustratedMessage, Content, Heading} from '@adobe/react-spectrum'
import React from 'react';

const MaintenancePage = () => {
  return (
    <>
      <IllustratedMessage>
        <Unavailable />
        <Heading>Error 503: Service Unavailable</Heading>
        <Content>
          This page isn't working. Try a different page or try again later.
        </Content>
      </IllustratedMessage>
    </>
  );
};
export default MaintenancePage;
