import {useState} from 'react';
import {useStateValue} from 'state/index';
import {loadInfoTabSections} from '../queries';
import {setInfoTabSections} from '../actions';
import {onException} from "../../httpStatusHandler";

export const useInfoSections = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async (tabTitle) => {
    setIsLoading(true);

    await loadInfoTabSections(tabTitle)
      .then(response => {
        dispatch(setInfoTabSections(response.data.data));
        setIsLoading(false);
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
  };

  return [state.infoSections, isLoading, request];
};

export default useInfoSections;
