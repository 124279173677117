
const config = {
  apiDomain: process.env.API_DOMAIN,
  clientsUrl: process.env.API_DOMAIN + '/load-clients',
  dashboardUrl: process.env.API_DOMAIN + '/dashboard',
  infoTabsUrl: process.env.API_DOMAIN + '/info/tabs',
  infoTabSectionsUrl: process.env.API_DOMAIN + '/info/tab',
  recommendationsUrl: process.env.API_DOMAIN + '/recommendations',
  recommendationDismissUrl: process.env.API_DOMAIN + '/recommendations/update',
  feedbackUrl: process.env.API_DOMAIN + '/feedback/send',
  reportUrl: process.env.API_DOMAIN + '/issues/export/report',
  acceptSplash: process.env.API_DOMAIN + '/splash/accept',
  cancelSplash: process.env.API_DOMAIN + '/splash/cancel',
  contextHelpUrl: process.env.API_DOMAIN + '/context-help-data',
  extensionsUrl: process.env.API_DOMAIN + '/extensions',
  exceptionsUrl: process.env.API_DOMAIN + '/exceptions',
  alertsUrl: process.env.API_DOMAIN + '/alerts',
  patchesUrl: process.env.API_DOMAIN + '/patches',
  recommendedPatchesUrl: process.env.API_DOMAIN + '/patches/recommended',
  sendToZdUrl: process.env.API_DOMAIN + '/issues/send',
  uctReportUrl: process.env.API_DOMAIN + '/uct/report/download',
  uctScanRunUrl: process.env.API_DOMAIN + '/uct/scan',
  securityScanAddUrl: process.env.API_DOMAIN + '/security-scan/add',
  securityScanReportUrl: process.env.API_DOMAIN + '/security-scan/report',
  outagesTabUrl: process.env.API_DOMAIN + '/outages/list',
  supportCaseSubmitUrl: process.env.API_DOMAIN + '/supportCases/submit',
  supportCasesUrl: process.env.API_DOMAIN + '/supportCases/fields',
  supportCaseSolutionFoundUrl: process.env.API_DOMAIN + '/supportCases/solution-found',
  caseDeflectionUrl: process.env.API_DOMAIN + '/supportCases/recommendations',
  productSearchUrl: process.env.API_DOMAIN + '/livesearch/submit',
  productDataUrl: process.env.API_DOMAIN + '/livesearch/get-data',
  preLaunchChecksUrl: process.env.API_DOMAIN + '/prelaunch-checks',
  preLaunchCheckSaveUrl: process.env.API_DOMAIN + '/prelaunch-checks/save',
  preLaunchCheckEmailSummary: process.env.API_DOMAIN + '/prelaunch-checks/email',
  preLaunchCheckDocGen: process.env.API_DOMAIN + '/prelaunch-checks/docgen',
};

export const getConfig = key => {
  if (config.hasOwnProperty(key)) {
    return config[key];
  } else if (process.env.hasOwnProperty(key)) {
    return process.env[key];
  }
  throw new Error(key + ' is not defined');
};
