import React from "react";
import useSecurityScanAdd from "state/securityCenterWidget/hooks/useSecurityScanAdd";
import {
  DialogTrigger,
  Dialog,
  Heading,
  Header,
  Divider,
  Content,
  Text,
  ButtonGroup,
  Button,
  Link,
} from "@adobe/react-spectrum";

function AddSecurityScan({ status }) {
  const addSiteEvent = useSecurityScanAdd();
  const addSite = () => {
    addSiteEvent(() => {});
    window.open("https://account.magento.com/scanner/websites/add/", "_blank");
  };
  if (status === 1) {
    return (
      <Link isQuiet variant="primary">
        <a
          href="https://account.magento.com/scanner/websites/grid/"
          target="_blank"
          data-ui-id="manage-scans-widget-link"
        >
          Manage Scans
        </a>
      </Link>
    );
  } else if (status === 0) {
    return (
      <DialogTrigger>
        <Link isQuiet variant="primary">
          <a data-ui-id="security-scan-add-site-dialog">Add Security Scan</a>
        </Link>
        {(close) => (
          <Dialog>
            <Heading UNSAFE_style={{ textTransform: "capitalize" }}>
              Start Monitoring Your Site
            </Heading>
            <Header>Connection status: Not connected</Header>
            <Divider />
            <Content>
              <Text>
                To enable Security Scans on your site you must log into your
                magento.com account. Click on the button below to be redirected
                to the appropriate page to enable Security Scan.
                <br />
                <br />
                <i>
                  NOTE: Security Scan reports might take up 24hrs to be
                  produced.
                </i>
              </Text>
            </Content>
            <ButtonGroup>
              <Button
                type={"close"}
                variant="secondary"
                onPress={close}
                data-ui-id="security-scan-add-site-close-button"
              >
                Close
              </Button>
              <Button
                type={"redirect"}
                variant="cta"
                onPress={addSite}
                data-ui-id="security-scan-add-site-redirect-button"
              >
                Redirect
              </Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogTrigger>
    );
  }
}

export default AddSecurityScan;
