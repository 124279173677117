import React from "react";
import styled from "styled-components";

const ViolationFieldValue = styled.div`
  color: #fa0000;
`;

function ViolationRuleTextFieldValue({ item }) {
  if (item.violation?.length > 0) {
    return <ViolationFieldValue>{item.data.charAt(0).toUpperCase() + item.data.slice(1)}</ViolationFieldValue>;
  }
  return <>{item.data.charAt(0).toUpperCase() + item.data.slice(1)}</>;
}

export default ViolationRuleTextFieldValue;
