import {SET_OUTAGE_INCIDENTS, SET_OUTAGE_ISSUES, SET_INFORMATION_LINKS} from './actions';

export const INITIAL_STATE = {
  outageIncidents: [],
  outageIssues: [],
  informationLinks: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OUTAGE_INCIDENTS:
      return {
        ...state,
        outageIncidents: action.payload,
      };
    case SET_OUTAGE_ISSUES:
      return {
        ...state,
        outageIssues: action.payload,
      };
    case SET_INFORMATION_LINKS:
      return {
        ...state,
        informationLinks: action.payload,
      };
    default:
      return state;
  }
};
