import React from "react";
import { View, Flex } from "@adobe/react-spectrum";
import styled from "styled-components";

const StyledSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

function RecommendationCard({ recommendations }) {
  const recommendationsSelected = recommendations.filter((recommendation) => recommendation.selected);
  const recommendationsOther = recommendations.filter((recommendation) => !recommendation.selected);

  const render = (recommendations, title) => (
    <>
      <StyledSpan>{title}</StyledSpan>
      <ul>
        {recommendations.map((recommendation) => (
          <li key={recommendation.id}>
            <a href={recommendation.url} target="_blank">{recommendation.title}</a>: {recommendation.summary}
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <View
      backgroundColor="gray-50"
      borderWidth="thin"
      borderColor="dark"
      borderRadius="medium"
      padding="size-250"
    >
      <Flex direction="column" gap="size-50">
        <div className="recommendationDetail">
          {recommendationsSelected.length > 0 && render(recommendationsSelected, 'Selected')}
          {recommendationsOther.length > 0 && render(recommendationsOther, 'Other')}
          {recommendations.length === 0 && <StyledSpan>No solutions were found.</StyledSpan>}
        </div>
      </Flex>
    </View>
  )
}

export default RecommendationCard;
