export const SET_PRELAUNCH_CHECKS = 'preLaunchChecks/SET_PRELAUNCH_CHECKS';
export const SET_PRELAUNCH_CHECKS_UPDATE = 'preLaunchChecks/SET_PRELAUNCH_CHECKS_UPDATE';

export const setPreLaunchData = payload => ({
  type: SET_PRELAUNCH_CHECKS,
  payload,
});

export const setRecommendationUpdate = (
  action,
  declaration_id, reason = null,
  dismissed_user = null,
  dismissed_time = null
) => ({
  type: SET_PRELAUNCH_CHECKS_UPDATE,
  payload: { action, declaration_id, reason, dismissed_user, dismissed_time },
});
