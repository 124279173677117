import Unauthorized from '@spectrum-icons/illustrations/Unauthorized';
import {IllustratedMessage, Content, Heading} from '@adobe/react-spectrum'
import React from 'react';

const UnauthorizedPage = () => {
  return (
    <>
      <IllustratedMessage>
        <Unauthorized />
        <Heading>Error 403: Access not allowed</Heading>
        <Content>
          You do not have permission to view this page.
          Please access this page via the link from the Adobe Commerce Admin panel on your production or staging environment.
          If you recently changed the Website URL, allow 30 minutes to sync up the changes.
        </Content>
        <Content>
          <br />
          <br />
          If you have an on-premises installation of Adobe Commerce, <a href="https://experienceleague.adobe.com/docs/commerce-operations/tools/site-wide-analysis-tool/installation.html" target="_blank">install an agent</a> on your infrastructure to use the tool. You do not need to install the agent on Adobe Commerce on cloud infrastructure projects.
        </Content>
      </IllustratedMessage>
    </>
  );
};
export default UnauthorizedPage;
