import React, { useState } from 'react';
import useFeedback from 'state/feedback/hooks/useFeedback';

import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Form,
  TextField,
  TextArea,
  ProgressCircle,
} from '@adobe/react-spectrum';

const TopLine = styled.hr`
  border: 1px solid #eaeaea;
  margin: 12px 0 16px 0;
  grid-area: divider;
`;

const LeaveFeedbackButton = ({ children }) => {
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [feedbackInvalid, setFeedbackInvalid] = useState(false);

  const [isLoading, request] = useFeedback();

  const isEmptyValue = value => {
    if (
      value === '' ||
      value === null ||
      value === undefined ||
      value === false
    ) {
      return true;
    }
    return false;
  };

  function isValidEmail(string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(string);
  }

  const submitFeedback = close => {
    let submit = true;
    if (isEmptyValue(name)) {
      submit = false;
      setNameInvalid('invalid');
    } else {
      setNameInvalid(false);
    }
    if (isEmptyValue(email) || !isValidEmail(email)) {
      submit = false;
      setEmailInvalid('invalid');
    } else {
      setEmailInvalid(false);
    }
    if (isEmptyValue(feedback)) {
      submit = false;
      setFeedbackInvalid('invalid');
    } else {
      setFeedbackInvalid(false);
    }
    if (submit) {
      request(name, email, feedback);
      close();
    }
  };

  return (
    <DialogTrigger>
      <Button UNSAFE_className="unvisibleButton" data-ui-id="open-feedback-dialog">
        {React.cloneElement(children, {
          style: { cursor: 'pointer' },
          height: '17px',
          width: '17px'
        })}
      </Button>
      {close => (
        <Dialog>
          <Heading>Feedback</Heading>
          <TopLine />
          <Content>
            {isLoading ? (
              <ProgressCircle aria-label="Loading…" isIndeterminate />
            ) : (
              <Form maxWidth="size-3600" isRequired necessityIndicator="label">
                <TextField
                  label="Name"
                  isRequired
                  placeholder="Jane Smith"
                  validationState={nameInvalid}
                  onChange={value => setName(value)}
                />
                <TextField
                  label="Email"
                  isRequired
                  placeholder="test@example.com"
                  validationState={emailInvalid}
                  onChange={value => setEmail(value)}
                />
                <TextArea
                  label="Feedback"
                  isRequired
                  validationState={feedbackInvalid}
                  onChange={value => setFeedback(value)}
                />
              </Form>
            )}
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close} data-ui-id="submit-feedback-cancel-button">
              Cancel
            </Button>
            <Button variant="cta" onPress={() => submitFeedback(close)} data-ui-id="submit-feedback-button">
              Submit Feedback
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
export default LeaveFeedbackButton;
