import React from 'react';
import styled from "styled-components";
import { Heading, Flex, View, Link } from "@adobe/react-spectrum";
import moment from 'moment';
import Widget from "./widget";
import Alert from '@spectrum-icons/workflow/Alert';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import useDashboard from "state/dashboard/hooks/useDashboard";

const Description = styled.div`
  color: #6E6E6E;
  text-transform: capitalize;
`;

const getDate = date => {
  const before = moment(date).format('YYYY-MM-DD HH:mm:ss');
  if (moment().diff(before, 'days')) {
    return moment().diff(before, 'days') + ' day(s) ago';
  }
  if (moment().diff(before, 'hours')) {
    return moment().diff(before, 'hours') + ' hour(s) ago';
  }
  if (moment().diff(before, 'minutes')) {
    return moment().diff(before, 'minutes') + ' minute(s) ago';
  }
  return 'Just now';
}

const getName = name => {
  let regexpNames =  /(?<name>[a-zA-Z%\d\s]+)\s\(/;
  let match = []
  match = regexpNames.exec(name)
  if (match && match.length) {
    return match[1]
  }
  return name
}
const getDescription = item => {
  let regexpNames =  /i-[0-9a-z]+\S(?<description>.*)\(/;
  let match = []
  let description = item.alert_description;

  match = regexpNames.exec(description)
  if (match && match.length) {
    description = match[1]
    let name = getName(item.alert_code);
    name = name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let regex = new RegExp("on.*" + name, 'g');
    description = description.replace(regex, '');
  }
  return description
}

export const AlertWidget = () => {
  const [dashboard,,] = useDashboard();
  const dateStyle = {
    textAlign: 'right',
    fontFamily: 'adobe-clean-italic',
    fontStyle: 'italic',
    color: '#6E6E6E',
    fontSize: '12px',
  };

  if (dashboard.counts.alerts !== 0) {
    return <Widget
      title={"NewRelic Alerts - High priority: " + dashboard.widget.newrelicAlerts.length}
      headerContent={
        <Link variant={'primary'} isQuiet>
          <a href="/tab/alerts" target="_self" data-ui-id="alert-widget-view-all">
            View All
          </a>
        </Link>
      }
    >
      <Flex direction="column" justifyContent="start" width="100%">
        {
          dashboard.widget.newrelicAlerts.map(item => <View width="100%" marginBottom={'3%'} key={item.id}>
              <Flex alignItems="stretch">
                <Flex flexBasis="8%" UNSAFE_style={{color: '#E34850'}} alignItems="center">
                  <Alert size="M"/>
                </Flex>
                <View flexBasis="96%">
                  <Flex direction="row" justifyContent="space-between">
                    <View><Heading level={5}>{getName(item.alert_code)}</Heading></View>
                    <View flexBasis="18%" UNSAFE_style={dateStyle}>{getDate(item.started_at)}</View>
                  </Flex>
                  <Description>{getDescription(item)}</Description>
                </View>
              </Flex>
            </View>
          )
        }
      </Flex>
    </Widget>;
  }

  //Return No Alerts state if there is no alerts in DB.
  return <Widget title={'NewRelic Alerts'}>
    <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height="100%" width="100%">
      <CheckmarkCircle color={'positive'} size={'XL'} marginBottom={'10px'}/>
      <Description>No Alerts</Description>
    </Flex>
  </Widget>


};
export default AlertWidget;
