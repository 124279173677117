import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Text,
  Flex,
  View,
  Checkbox,
  ProgressCircle,
} from '@adobe/react-spectrum';
import Download from '@spectrum-icons/workflow/Download';
import useReport from 'state/report/hooks/useReport';
import useZenDesk from 'state/report/hooks/useZenDesk';
import { useParams } from 'react-router';
import Access from 'views/base/components/access'
import HelpOutline from "@spectrum-icons/workflow/HelpOutline";
import ClickableTooltip from "../clickableTooltip";

const StyledButton = styled(Button)`
  border: 1px solid #eaeaea !important;
  padding: 0 14px 0 8px !important;
  cursor: pointer !important;
`;
const ButtonText = styled.span`
  font-family: 'adobe-clean', Times, serif;
  font-size: 14px;
  color: #505050;
  font-weight: normal;
  padding-left: 10px;
`;
const StyledHeading = styled.h3`
  margin-top: 24px;
`;
const InlineTooltipStyle = styled.div`
  display: inline-block;
  margin: 10px 10px;
`;
const CheckboxHeading = styled.h6`
  font-family: 'adobe-clean-bold', Times, serif;
  font-size: 14px;
  color: #2c2c2c;
  letter-spacing: 0;
  margin-bottom: 10px;
`;
const TopLine = styled.hr`
  border: 1px solid #eaeaea;
  margin: 12px 0 16px 0;
  grid-area: divider;
`;
const Line = styled.hr`
  margin: 5px 0 12px 0;
`;

const GenerateReportButton = () => {
  const [selectedCheckboxes, setCheckboxes] = useState(new Set());
  const [isLoading, request, error] = useReport();
  const [isSending, send] = useZenDesk();

  const { hash } = useParams();

  const toggleCheckbox = id => {
    const set = new Set(selectedCheckboxes);
    if (selectedCheckboxes.has(id)) {
      set.delete(id);
      setCheckboxes(set);
    } else {
      set.add(id);
      setCheckboxes(set);
    }
  };
  const isSelectedCheckbox = id => {
    let result = false;
    if (selectedCheckboxes.has(id)) {
      result = true;
    }
    return result;
  };

  return (
    <DialogTrigger>
      <StyledButton marginEnd="size-300" height="40px" UNSAFE_style={{backgroundColor:'white'}} data-ui-id="download-recommendations-report-dialog">
        <Download />
        <ButtonText>Download Recommendations Report</ButtonText>
      </StyledButton>
      {close => (
        <Dialog>
          <Heading>
            Report settings
            <InlineTooltipStyle>
              <ClickableTooltip
                color="#6e6e6e"
                margin="10px 10px 0px 0px"
                code={'helpSWATReport'}
              >
                <HelpOutline size="XS"/>
              </ClickableTooltip>
            </InlineTooltipStyle>
          </Heading>
          <TopLine />
          <Content>
            {isLoading || isSending ? (
              <ProgressCircle aria-label={isLoading ? 'Loading...' : isSending ? 'Sending...' : ''} isIndeterminate />
            ) : error ? (
              <>
                <Text>{error}</Text>
              </>
            ) : (
              <>
                <Text>
                  Select recommendation types and priorities to include in this
                  report.
                </Text>
                <StyledHeading level={3}>Recommendations to include</StyledHeading>
                <Line />
                <Flex direction="row">
                  <View width="60%">
                    <CheckboxHeading level="6">Type</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('type_all')}
                        isSelected={isSelectedCheckbox('type_all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_accessibility')}
                        isSelected={isSelectedCheckbox('type_accessibility')}
                      >
                        Accessibility
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_functionality')}
                        isSelected={isSelectedCheckbox('type_functionality')}
                      >
                        Functionality
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_performance')}
                        isSelected={isSelectedCheckbox('type_performance')}
                      >
                        Performance
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_security')}
                        isSelected={isSelectedCheckbox('type_security')}
                      >
                        Security
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="40%">
                    <CheckboxHeading level="6">Risk Level</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_all')}
                        isSelected={isSelectedCheckbox('priority_all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P0')}
                        isSelected={isSelectedCheckbox('priority_P0')}
                      >
                        Severe
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P1')}
                        isSelected={isSelectedCheckbox('priority_P1')}
                      >
                        High
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P2')}
                        isSelected={isSelectedCheckbox('priority_P2')}
                      >
                        Elevated
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P3')}
                        isSelected={isSelectedCheckbox('priority_P3')}
                      >
                        Medium
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P4')}
                        isSelected={isSelectedCheckbox('priority_P4')}
                      >
                        Low
                      </Checkbox>
                    </Flex>
                  </View>
                </Flex>
              </>
            )}
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close} data-ui-id="download-report-dialog-cancel-button">
              Cancel
            </Button>
            <Button
              variant="cta"
              onPress={() => {
                request(hash, [...selectedCheckboxes], close);
                setCheckboxes(new Set());
              }}
              data-ui-id="download-report-dialog-button"
            >
              Download Report
            </Button>
            <Access requiredRole='createZdTicket' requiredHosting='magento-commerce-cloud' silent={true}>
              <Button
                variant="cta"
                onPress={() => {
                  send(hash, [...selectedCheckboxes], close)
                  setCheckboxes(new Set());
                }}
                data-ui-id="send-report-to-zd-dialog-button"
              >
                Send report to ZD
              </Button>
            </Access>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
export default GenerateReportButton;
