import { useState } from 'react';
import { useStateValue } from 'state/index';
import { loadRecommendations } from '../queries';
import {setRecommendations} from '../actions';
import { onException } from "state/httpStatusHandler";

export const useRecommendations = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await loadRecommendations()
      .then(response => {
        dispatch(setRecommendations(response.data.recommendations));
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
      setIsLoading(false);
  };

  return [state.recommendations, isLoading, request];
};

export default useRecommendations;
