import {useState} from 'react';
import {useStateValue} from 'state/index';
import {loadExceptions} from '../queries';
import {setExceptions} from '../actions';
import {onException} from 'state/httpStatusHandler';

const useExceptions = () => {
  const [state, dispatch] = useStateValue();

  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await loadExceptions()
      .then((response) => {
        if (response && response.hasOwnProperty('data')) {
          dispatch(setExceptions(response.data));
        }
      })
      .catch(errorObject => onException(errorObject, dispatch, state));

    setIsLoading(false);
  };

  return [state.exceptions, isLoading, request];
};

export default useExceptions;
