import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Flex,
  View,
  Checkbox,
  ProgressCircle,
} from '@adobe/react-spectrum';
import Filter from '@spectrum-icons/workflow/Filter';
import { recommendationsDataSource } from "./recommendationsGrid";
import {useParams} from "react-router";
import useRecommendations from "state/recommendations/hooks/useRecommendations";

const CheckboxHeading = styled.h6`
  font-family: 'adobe-clean-bold', Times, serif;
  font-size: 14px;
  color: #2c2c2c;
  letter-spacing: 0;
  margin-bottom: 10px;
`;
const TopLine = styled.hr`
  border: 1px solid #eaeaea;
  margin: 12px 0 16px 0;
  grid-area: divider;
`;

const FilterRecommendationsButton = props => {
  const [,isLoading,] = useRecommendations();
  const { filter } = useParams();
  let defaultState = new Set();
  if (filter) {
    defaultState.add(filter);
  }
  const [selectedCheckboxes, setCheckboxes] = useState(defaultState);

  const toggleCheckbox = id => {
    const set = new Set(selectedCheckboxes);
    if (selectedCheckboxes.has(id)) {
      set.delete(id);
      setCheckboxes(set);
    } else {
      set.add(id);
      setCheckboxes(set);
    }
  };
  const isSelectedCheckbox = id => {
    let result = false;
    if (selectedCheckboxes.has(id)) {
      result = true;
    }
    return result;
  };

  useEffect(() => {
    recommendationsDataSource.filterRecs([...selectedCheckboxes]);
  }, [recommendationsDataSource.recommendationsData]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
  }

  return (
    <DialogTrigger>
      <Flex direction="row" alignContent="center" alignItems="center">
        <View>
          <Button height="40px" isQuiet UNSAFE_className="filterGridButton">
            <Filter />
          </Button>
        </View>
        <View>
          <span className="filterCounter">{props.recommendationCount} records out of {props.totalRecommendationCount}</span>
        </View>
      </Flex>
      {close => (
        <Dialog>
          <Heading>Filter Recommendations</Heading>
          <TopLine />
          <Content>
            {<>
                <Flex direction="row">
                  <View width="33%">
                    <CheckboxHeading level="6">Type</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('type_all')}
                        isSelected={isSelectedCheckbox('type_all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_availability')}
                        isSelected={isSelectedCheckbox('type_availability')}
                      >
                        Availability
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_functionality')}
                        isSelected={isSelectedCheckbox('type_functionality')}
                      >
                        Functionality
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_performance')}
                        isSelected={isSelectedCheckbox('type_performance')}
                      >
                        Performance
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('type_security')}
                        isSelected={isSelectedCheckbox('type_security')}
                      >
                        Security
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="33%">
                    <CheckboxHeading level="6">Risk Level</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_all')}
                        isSelected={isSelectedCheckbox('priority_all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P0')}
                        isSelected={isSelectedCheckbox('priority_P0')}
                      >
                        Severe
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P1')}
                        isSelected={isSelectedCheckbox('priority_P1')}
                      >
                        High
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P2')}
                        isSelected={isSelectedCheckbox('priority_P2')}
                      >
                        Elevated
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P3')}
                        isSelected={isSelectedCheckbox('priority_P3')}
                      >
                        Medium
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('priority_P4')}
                        isSelected={isSelectedCheckbox('priority_P4')}
                      >
                        Low
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="33%">
                    <CheckboxHeading level="6">Status</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('status_1')}
                        isSelected={isSelectedCheckbox('status_1')}
                      >
                        Detected
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('status_2')}
                        isSelected={isSelectedCheckbox('status_2')}
                      >
                        Dismissed
                      </Checkbox>
                    </Flex>
                  </View>
                </Flex>
              </>
            }
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>
              Cancel
            </Button>
            <Button
              variant="cta"
              onPress={() => {
                recommendationsDataSource.filterRecs([...selectedCheckboxes]);
                close();
              }}
            >
              Apply
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
export default FilterRecommendationsButton;
