import {getConfig} from 'config';
import {getData, putData} from '../dataProvider';

export const loadPatches = () => {
  return getData(`${getConfig('patchesUrl')}`);
};

export const loadRecommendedPatches = (sourceName = '', action = '') => {
  return putData(
    getConfig('recommendedPatchesUrl'),
    {"source": sourceName, "action": action},
    {}
  );
};
