import React from "react";
import {Flex, View} from "@adobe/react-spectrum";
import {TableView} from "@react/react-spectrum/TableView";
import ListDataSource from "@react/react-spectrum/ListDataSource";
import "./assets/publicAuthTable.css";

const publicAuthTable = ({item}) => {

  let tableData = [];

  class TableDataSource extends ListDataSource {
    load(sortDescriptor) {
      if (sortDescriptor && Array.isArray(tableData)) {
        tableData.sort(
          (a, b) => (a[sortDescriptor.column.key] > b[sortDescriptor.column.key])
            ? sortDescriptor.direction : -sortDescriptor.direction
        );
      }

      return tableData;
    };
  }

  const renderCell = (column, data) => {
    let text = data[column.key];
    let cssClass = "";
    if (column.key === "Response Body") {
      if (text.endsWith("is Valid")) {
        cssClass = "valid";
      } else {
        cssClass = "invalid";
      }
    }

    return <View>
      <span className={cssClass}> </span>
      <span style={{fontSize: "14px"}}>{text}</span>
    </View>;
  };

  const tableDataSource = new TableDataSource();

  const tableView = item => {
    let data = JSON.parse(item.data);
    let columns = [];

    tableData = data;

    if (Array.isArray(data) && data.length > 0) {
      Object.keys(data[0]).forEach(column => {
        let columnObj = {
          title: column,
          key: column,
          align: "left",
          divider: false
        }
        if (column === 'Response Code') {
          columnObj.align = "center";
        }
        let width = 150;
        switch (column) {
          case "URL":
            columnObj.minWidth = 300;
            break;
          case 'Response Code':
            columnObj.width = 150;
            break;
          case 'Response Body':
            columnObj.width = 200;
            break;
          default:
            columnObj.width = 100;
        }
        columns.push(columnObj)
      });
    }

    if (!columns.length) {
      return;
    }

    return <TableView
      columns={columns}
      dataSource={tableDataSource}
      renderCell={renderCell}
      allowsSelection={false}
      className="public-auth-grid"
      rowHeight={5}
      quiet={true}
    />;
  }
  return <Flex direction="row" height="size-2400">
      <View flexBasis="100%">{tableView(item)}</View>
    </Flex>
};
export default publicAuthTable;
