import Unauthorized from '@spectrum-icons/illustrations/Unauthorized';
import {IllustratedMessage, Content, Heading} from '@adobe/react-spectrum'
import React from 'react';
import useDashboard from "state/dashboard/hooks/useDashboard";

const ImsErrorPage = () => {
  const [dashboard,,] = useDashboard();
  return (
    <>
      <IllustratedMessage>
        <Unauthorized />
        <Heading>Error 403: Access not allowed</Heading>
        <Content>Note: Your account should be entitled to Support Permissions.
          <p>See more details in <a href="https://experienceleague.adobe.com/docs/commerce-admin/start/commerce-account/commerce-account-share.html" target="_blank">Commerce account</a> in our user guide.</p>
          <p>Please try <a href="https://account.magento.com/customer/account/logout" target="_blank">logging out</a> and selecting an entitled organization when you log in and refresh this page.</p>
          <p>Error code: {dashboard.imsErrorCode}</p>
        </Content>
      </IllustratedMessage>
    </>
  );
};
export default ImsErrorPage;
