import {SET_ALERT_ITEMS} from './actions';
import {SET_OAC_LINK} from './actions';

export const INITIAL_STATE = {
  items: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALERT_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case SET_OAC_LINK:
      return {
        ...state,
        oacLink: action.payload,
      };
    default:
      return state;
  }
};
