import React from 'react';
import {Flex, View} from "@adobe/react-spectrum";

const emptySpaceType = ({section, sectionIndex}) => {
  return <div key={sectionIndex}>
    <Flex direction="row" height="181.79px" justifyContent="space-between" columnGap="16px" width="100%">
      <View height="100%">
        &nbsp;<br/>
      </View>
    </Flex>
  </div>;
};

export default emptySpaceType;
