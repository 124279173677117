import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Flex,
  View,
  Checkbox,
} from '@adobe/react-spectrum';
import Filter from '@spectrum-icons/workflow/Filter';
import { patchesDataSource } from "./patchesGrid";
import usePatches from "../../../state/patches/hooks/usePatches";
import {useParams} from "react-router";
import useRecommendedPatches from "../../../state/patches/hooks/useRecommendedPatches";

const CheckboxHeading = styled.h6`
  font-family: 'adobe-clean-bold', Times, serif;
  font-size: 14px;
  color: #2c2c2c;
  letter-spacing: 0;
  margin-bottom: 10px;
`;
const TopLine = styled.hr`
  border: 1px solid #eaeaea;
  margin: 12px 0 16px 0;
  grid-area: divider;
`;

const FilterPatchesButton = props => {
  const { filter } = useParams();
  const [patches,,, isLoading] = usePatches();

  let defaultState = new Set();
  if (filter) {
    defaultState.add(filter);
  }
  const [selectedCheckboxes, setCheckboxes] = useState(defaultState);

  const viewRecommendedPatchesEvent = useRecommendedPatches('grid', 'filter');
  const trackFilterAction = (selectedFilters) => {
    selectedFilters.forEach(filter => {
      const f = filter.split("|");
      if (f[0]==="is_recommended") {
        viewRecommendedPatchesEvent(() => {});
      }
    })
  };

  useEffect(() => {
    patchesDataSource.filterCallback = props.setPatchCount
    if (!isLoading && patches.length > 0 && patchesDataSource.items) {
      patchesDataSource.items = patches;
      patchesDataSource.filterPatches([...selectedCheckboxes]);
    }
  }, [isLoading]);

  const getCategories = patches => {
    let result = [];
    patches.forEach(patch => {
      result = result.concat(patch.categories);
    });
    result = result.filter(function (value, index, self) {
      return self.indexOf(value) === index;
    }).sort();

    return result;
  };

  const toggleCheckbox = id => {
    const set = new Set(selectedCheckboxes);
    if (selectedCheckboxes.has(id)) {
      set.delete(id);
      setCheckboxes(set);
    } else {
      set.add(id);
      setCheckboxes(set);
    }
  };

  const isSelectedCheckbox = id => {
    let result = false;
    if (selectedCheckboxes.has(id)) {
      result = true;
    }
    return result;
  };

  return (
    <DialogTrigger>
      <Flex direction="row" alignContent="center" alignItems="center">
        <View>
          <Button height="40px" isQuiet UNSAFE_className="filterGridButton">
            <Filter />
          </Button>
        </View>
        <View>
          <span className="filterCounter">{props.patchCount} records out of {props.totalPatchCount}</span>
        </View>
      </Flex>
      {close => (
        <Dialog>
          <Heading>Filter Patches</Heading>
          <TopLine />
          <Content>
            {<>
                <Flex direction="row">
                  <View width="25%">
                    <CheckboxHeading level="6">Categories</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('categories|all')}
                        isSelected={isSelectedCheckbox('categories|all')}
                      >
                        All
                      </Checkbox>
                      {getCategories(patches).map(function (category) {
                        return <Checkbox
                                key={category}
                                onChange={() => toggleCheckbox('categories|' + category)}
                                isSelected={isSelectedCheckbox('categories|' + category)}
                               >
                          {category}
                      </Checkbox>
                      })}
                    </Flex>
                  </View>

                  <View width="45%">
                    <CheckboxHeading level="6">Origin</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('origin|all')}
                        isSelected={isSelectedCheckbox('origin|all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('origin|magento os community')}
                        isSelected={isSelectedCheckbox('origin|magento os community')}
                      >
                        Magento OS Community
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('origin|adobe commerce support')}
                        isSelected={isSelectedCheckbox('origin|adobe commerce support')}
                      >
                        Adobe Commerce Support
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="25%">
                    <CheckboxHeading level="6">Status</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('status|all')}
                        isSelected={isSelectedCheckbox('status|all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('status|applied')}
                        isSelected={isSelectedCheckbox('status|applied')}
                      >
                        Applied
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('status|not applied')}
                        isSelected={isSelectedCheckbox('status|not applied')}
                      >
                        Not applied
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('status|na')}
                        isSelected={isSelectedCheckbox('status|na')}
                      >
                        N/A
                      </Checkbox>
                    </Flex>
                  </View>
                  <View width="25%">
                    <CheckboxHeading level="6">Recommended</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('is_recommended|all')}
                        isSelected={isSelectedCheckbox('is_recommended|all')}
                      >
                        All
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('is_recommended|Yes')}
                        isSelected={isSelectedCheckbox('is_recommended|Yes')}
                      >
                        Yes
                      </Checkbox>
                    </Flex>
                  </View>
                </Flex>
              </>
            }
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>
              Cancel
            </Button>
            <Button
              variant="cta"
              onPress={() => {
                patchesDataSource.filterPatches([...selectedCheckboxes]);
                trackFilterAction([...selectedCheckboxes]);
                close();
              }}
            >
              Apply
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
export default FilterPatchesButton;
