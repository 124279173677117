import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Item,
  Picker,
  TextField,
  View,
} from "@adobe/react-spectrum";
import { useProductSearch } from "state/liveSearch/hooks/useProductSearch";
import Cancel from "@spectrum-icons/workflow/Cancel";
import { useStateValue } from "state/index";
import WarningMessage from "views/sharedComponents/warningMessage";
import InfoMessage from "views/sharedComponents/infoMessage";

const liveSearchType = ({ section, sectionIndex }) => {
  let [state] = useStateValue();
  let clientPrefix =
    state.dashboard.client.hash +
    "_" +
    state.dashboard.client.environment +
    "_";
  let [sku, setSku] = useState("");
  let [storeCodes, setStoreCodes] = useState("");
  let [isEnvironmentIdEmpty] = useState(section.items['livesearch-environment-id'] === undefined);
  let [isMageIdEmpty] = useState(section.items['mageId'] === undefined);
  let { productSearch, cancelSearch, isLoading } = useProductSearch();

  useEffect(() => {
    const storedSku = localStorage.getItem(clientPrefix + "sku");
    const storedCodes = localStorage.getItem(clientPrefix + "storeCodes");
    if (storedSku) {
      setSku(storedSku);
    }
    if(storedCodes) {
      setStoreCodes(storedCodes);
    }
  }, []);

  const findProduct = (sku, storeCodes) => {
    localStorage.setItem(clientPrefix + 'sku', sku);
    localStorage.setItem(clientPrefix + 'storeCodes', storeCodes)
    productSearch(sku, storeCodes);
  };

  return (
    <div key={sectionIndex} style={{minHeight: '139.59px', display:'flex', flexDirection: 'column', justifyContent:'center'}}>
      <Flex
        direction="row"
        height="size-8000"
        justifyContent="space-between"
        columnGap="16px"
        width="100%"
      >
        <View width="100%">

          {(isEnvironmentIdEmpty || isMageIdEmpty) && (
            <WarningMessage
              message={"This client does not have a Live Search or Mage ID. Product Search is not available."}
              />
          )}
          {!isEnvironmentIdEmpty && !isMageIdEmpty && (
            <InfoMessage
              message={"Search for a product by SKU and Store View."}
              />
          )}
          <Flex direction={"row"} alignItems={"end"} gap={"size-100"}>
            <Picker
              placeholder="Select a Store View"
              label="Store View"
              width={"size-3000"}
              selectedKey={storeCodes}
              isDisabled = {isEnvironmentIdEmpty || isMageIdEmpty}
              onSelectionChange={(selected) => setStoreCodes(selected)}
            >
              {section.items["general-storeview-store-website-codes"] &&
                section.items["general-storeview-store-website-codes"].map(
                  (item) => (
                    <Item key={JSON.stringify(item)}>
                      {item["store_view_name"]+ " (Website: " + item["website_name"] + ")"}
                    </Item>
                  )
                )}
            </Picker>
            <TextField
              label="SKU"
              value={sku}
              placeholder="Enter product's SKU"
              width={"size-1700"}
              onChange={setSku}
              isDisabled = {isEnvironmentIdEmpty || isMageIdEmpty}
            />
            <Button
              variant="primary"
              style="outline"
              type="submit"
              isDisabled={isLoading || !sku || !storeCodes || isEnvironmentIdEmpty || isMageIdEmpty}
              onPress={() => findProduct(sku, storeCodes)}
            >
              {isLoading ? "Searching..." : "Find product"}
            </Button>
            <Button
              variant="secondary"
              type="submit"
              isDisabled={!isLoading}
              onPress={() => cancelSearch()}
            >
              <Cancel /> Cancel
            </Button>
          </Flex>
        </View>
      </Flex>
    </div>
  );
};

export default liveSearchType;
