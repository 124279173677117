import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import { Provider as ProviderV3, defaultTheme } from '@adobe/react-spectrum';
import Provider  from '@react/react-spectrum/Provider';
import { StateProvider } from 'state';
import reducers from 'state/reducers';
import Base from 'views/base';
import { INITIAL_STATE as DASHBOARD_INITIAL_STATE } from 'state/dashboard/reducers';
import { INITIAL_STATE as RECOMMENDATIONS_INITIAL_STATE } from 'state/recommendations/reducers';
import { INITIAL_STATE as CONTEXT_HELP_INITIAL_STATE } from 'state/contextHelp/reducers';
import { INITIAL_STATE as INFO_INITIAL_STATE } from 'state/infoTabs/reducers';
import { INITIAL_STATE as EXCEPTIONS_INITIAL_STATE } from 'state/exceptions/reducers';
import { INITIAL_STATE as PATCHES_INITIAL_STATE } from 'state/patches/reducers';
import Styles from './styles';

const Root = () => <Provider colorScheme={'light'}>
      <ProviderV3 theme={defaultTheme} colorScheme={'light'} UNSAFE_style={{backgroundColor:'#F5F5F5', justifyContent:'space-between'}}>
        <StateProvider initialState={{
          ...DASHBOARD_INITIAL_STATE,
          ...RECOMMENDATIONS_INITIAL_STATE,
          ...CONTEXT_HELP_INITIAL_STATE,
          ...INFO_INITIAL_STATE,
          ...EXCEPTIONS_INITIAL_STATE,
          ...PATCHES_INITIAL_STATE
        }} reducer={reducers}>
          <Styles />
          <Router>
            <Switch>
              <Route path="/" exact component={() => <Redirect to='/commerce' />} />
              <Route path="/commerce/tab/:tab/:filter?" component={Base} />
              <Route path="/commerce/:context?" component={Base} />
            </Switch>
          </Router>
        </StateProvider>
      </ProviderV3>
    </Provider>

export default Root;
