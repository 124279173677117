import React from "react";
import styled from "styled-components";
import useDashboard from "state/dashboard/hooks/useDashboard";
import Widget from "./widget";
import ReportIcon from "@spectrum-icons/workflow/Report";
import AddSecurityScan from "./securityCenterWidget/AddSecurityScan";
import VegaDonutCustomSpec from "./securityCenterWidget/VegaDonutCustomSpec";
import useSecurityCenterReport from "state/securityCenterWidget/hooks/useSecurityCenterReport";
import { ActionButton, Text, View, Flex } from "@adobe/react-spectrum";
import defaultValueMerge from "./securityCenterWidget/defaultValueMerge";
import moment from "moment";

const Title = styled.div`
  font-family: adobe-clean !important;
  font-size: 12px;
  color: #2c2c2c;
  margin-top: -5px;
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: 1px;
`;

const WidgetSubtitle = styled.div`
  font-family: adobe-clean !important;
  font-size: 14px;
  color: #6e6e6e;
  font-weight: 400;
  margin-top: -10px;
`;

const Label = styled.div`
  font-family: adobe-clean !important;
  font-size: 14px;
  color: #6e6e6e;
  text-align: start;
`;

const Value = styled.div`
  font-family: adobe-clean !important;
  font-size: 14px;
  color: #323232;
  margin-bottom: 5px;
  text-align: end;
`;

const SecurityCenterWidget = (layoutItem) => {
  const [dashboard, ,] = useDashboard();
  const viewRecommendationsEvent = useSecurityCenterReport();

  const viewRecommendations = () => {
    viewRecommendationsEvent(() => {});
    window.location.href = "/tab/recommendations/type_security";
  };

  // Function to merge values coming from backend with default value object.
  // This is done because deepmerge function in reducer does not merge the default value object with the object coming from backend.
  let mergedObj = defaultValueMerge(dashboard.widget.securityCenter);

  const renderGroupRow = (item) => {
    if (item.label === 'Scan last run') {
      item.value = moment.utc(item.value).local().format('MMM Do, YYYY');
    }
    return <Flex
      key={item.label}
      direction="row"
      justifyContent={"space-between"}
      gap={"size-50"}
    >
      <Label>{item.label}</Label>
      <Value>{item.value}</Value>
    </Flex>
  }

  return (
    <Widget
      className={"uctReportReady"}
      layoutItem={layoutItem}
      headerContent={
        <AddSecurityScan status={mergedObj.status} />
      }
      title={
        <Flex direction={"column"}>
          Security Center
          <WidgetSubtitle>
            This widget streamlines security insights
          </WidgetSubtitle>
        </Flex>
      }
    >
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        gap={"size-200"}
        width={"100%"}
      >
        <View height={"100%"} width={"55%"}>
          <VegaDonutCustomSpec data={mergedObj.issues} />
        </View>
        <View width={"45%"}>
          <Flex
            direction={"column"}
            justifyContent={"space-between"}
            height={"100%"}
          >
            <div>
              <Title>TOP FAILURES BY GROUP</Title>
              {mergedObj.list.map(renderGroupRow)}
            </div>
            <ActionButton
              onClick={viewRecommendations}
              alignSelf={"end"}
              data-ui-id={"security-center-widget-view-report-button"}
            >
              <ReportIcon />
              <Text>View Recommendations</Text>
            </ActionButton>
          </Flex>
        </View>
      </Flex>
    </Widget>
  );
};
export default SecurityCenterWidget;
