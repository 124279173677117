import {useState} from 'react';
import {useStateValue} from 'state/index';
import {loadPatches} from '../queries';
import {setPatchItems, setIsMqpInstalled, setMqpVersion} from '../actions';
import {onException} from 'state/httpStatusHandler';

const usePatches = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await loadPatches()
      .then((response) => {
        if (response && response.hasOwnProperty('data')) {
          dispatch(setPatchItems(response.data.patches));
          dispatch(setIsMqpInstalled(response.data.isMqpInstalled));
          dispatch(setMqpVersion(response.data.mqpVersion));
        }
      })
      .catch(errorObject => onException(errorObject, dispatch, state));

    setIsLoading(false);
  };

  return [state.patches.items, state.patches.isMqpInstalled, state.patches.mqpVersion, isLoading, request];
};

export default usePatches;
