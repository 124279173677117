import {SET_RECOMMENDATIONS, SET_RECOMMENDATION_UPDATE} from './actions';

export const INITIAL_STATE = {
  recommendations: []
};

export default (recommendations = [], action) => {
  switch (action.type) {
    case SET_RECOMMENDATIONS:
      return [
        ...action.payload
      ];
    case SET_RECOMMENDATION_UPDATE:
      const { declaration_id, reason, dismissed_user, dismissed_time } = action.payload;
      const rec = recommendations.find(recommendation => recommendation.declaration_id === declaration_id)
      if (rec) {
        rec.recommendation_status = action.payload.action === 'dismiss' ? 2 : 0;
        rec.dismissed_reason = reason;
        rec.dismissed_time = dismissed_time;
        rec.dismissed_user_name = dismissed_user;
      }
    default:
      return recommendations;
  }
};
