import React from "react";
import PickerController from "../inputFields/picker";

function EnvironmentField({ control, errors, setValue, fields }) {
  const environment = fields.environment;

  return (
    <PickerController
      required={true}
      label={environment.label}
      name={environment.name}
      items={environment.items}
      control={control}
      setValue={setValue}
      validationState={errors[environment.name] ? "invalid" : null}
      defaultValue={environment.value}
    />
  );
}

export default EnvironmentField;
