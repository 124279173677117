import { useState } from 'react';
import { useStateValue } from 'state/index';
import { submitFeedback } from '../queries';
import { success } from "@react/react-spectrum/Toast";
import { onException } from "../../httpStatusHandler";

const useFeedback = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const request = async (name, email, feedback) => {
    setIsLoading(true);

    await submitFeedback(name, email, feedback)
      .then(response => {
        success('Your feedback has been submitted!', {
          timeout: 0
        })
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
    setIsLoading(false);
  };

  return [isLoading, request];
};

export default useFeedback;
