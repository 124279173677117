export const SET_DASHBOARD = 'dashboard/SET_DASHBOARD';
export const DISPLAY_SPLASH_PAGE = 'dashboard/DISPLAY_SPLASH_PAGE';
export const DISPLAY_ERROR_PAGE = 'dashboard/DISPLAY_ERROR_PAGE';
export const DISPLAY_IMS_ERROR_PAGE = 'dashboard/DISPLAY_IMS_ERROR_PAGE';

export const setDashboard = payload => ({
  type: SET_DASHBOARD,
  payload
});
export const displaySplashPage = (payload) => ({
  type: DISPLAY_SPLASH_PAGE,
  payload
});
export const displayErrorPage = (payload) => ({
  type: DISPLAY_ERROR_PAGE,
  payload
});
export const displayImsErrorPage = payload => ({
  type: DISPLAY_IMS_ERROR_PAGE,
  payload
});
