import React from "react";
import PickerController from "../inputFields/picker";

function ImplementationTypeField({
  control,
  errors,
  setValue,
  watchImplementationType,
  watchIsSiteLive,
  fields,
}) {
  const implementationType = fields.implementationType;
  const isSiteLive = fields.isSiteLive;
  const upcomingGoLive = fields.upcomingGoLive;

  return (
    <>
      <PickerController
        required={true}
        label={implementationType.label}
        validationState={errors[implementationType.name] ? "invalid" : null}
        name={implementationType.name}
        items={implementationType.items}
        control={control}
        setValue={setValue}
        defaultValue={implementationType.value}
      />

      {watchImplementationType === "impl_type_cloud" && (
        <PickerController
          required={true}
          label={isSiteLive.label}
          validationState={errors[isSiteLive.name] ? "invalid" : null}
          name={isSiteLive.name}
          items={isSiteLive.items}
          control={control}
          setValue={setValue}
        />
      )}

      {watchIsSiteLive === "live_no_confirmed" && (
        <PickerController
          required={true}
          label={upcomingGoLive.label}
          validationState={errors[upcomingGoLive.name] ? "invalid" : null}
          name={upcomingGoLive.name}
          items={upcomingGoLive.items}
          control={control}
          setValue={setValue}
        />
      )}
    </>
  );
}

export default ImplementationTypeField;
