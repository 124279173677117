import React, {useState} from 'react';
import FilterRecommendationsButton from './components/filterRecommendationsButton';
import RecommendationsGrid from './components/recommendationsGrid';

const RecommendationsTab = () => {
  const [filteredRecommendations, setFilteredRecommendations] = useState(false);
  const [gridLoaded, setGridLoaded] = useState(false);
  const [recommendationCount, setRecommendationCount] = useState(0);
  const [totalRecommendationCount, setTotalRecommendationCount] = useState(0);

  return (
    <div className={'recommendationsTab'}>
      <div>Recommendations are best practices that can be used to address issues detected on your system.</div>
      <FilterRecommendationsButton
        gridLoaded={gridLoaded}
        recommendationCount={recommendationCount}
        totalRecommendationCount={totalRecommendationCount}
      />
      <RecommendationsGrid
        setGridLoaded={setGridLoaded}
        filteredRecommendations={filteredRecommendations}
        setRecommendationCount={setRecommendationCount}
        setTotalRecommendationCount={setTotalRecommendationCount}
      />
    </div>
  );
}

export default RecommendationsTab;
