import { error, success } from "@react/react-spectrum/Toast";
import {savePreLaunchChecks} from "../saveAction";

const savePreLaunchData = async (data) => {
  await savePreLaunchChecks(data)
    .then(response => {
      success('Saved Successfully.');
    })
    .catch(errorObject => {
      if (errorObject.response.hasOwnProperty('data')) {
        if (errorObject.response.data.hasOwnProperty('error')) {
          return error(errorObject.response.data.error , {timeout: 60000})
        }
      }
      error('Something went wrong. Please try later.' , {timeout: 60000})
    });
};


export const savePreLaunchChecksAction = (data) => {
  return savePreLaunchData({data});
};

export default savePreLaunchChecksAction;
