//Contains external resource links to snapshot data captured at the time of the outage.
import React from 'react';
import {Divider, View, Flex, Heading, Link, Text} from '@adobe/react-spectrum';
import styled from 'styled-components';
import SnapShotModal from "./snapShotModal";

const FastlyLinksList = styled.ul`
  list-style: disc;
  padding: 0;
  margin: 0;
  list-style-position: inside;
  li {
    margin-bottom: 0rem;
  }
`;


const OutageLinks = ({informationLinks, outageIssues, incidentInfo}) => {
  let incidentTimestamp = new Date(incidentInfo[1]+' UTC').getTime();
  let timeStampMinusOneHour = incidentTimestamp - (60 * 60 * 1000);
  return (
    <View marginBottom={'size-300'}>
      <Flex direction={"row"} gap={"size-300"}>
        <View width={'50%'}>
          <Heading level={3}>SNAPSHOTS</Heading>
          <Divider size={'M'} marginBottom={'size-50'}/>
          <Flex direction={"column"} gap={"size-50"}>
            <SnapShotModal
              heading={'Process List'}
              snapshotData={outageIssues[incidentInfo[0]]['processList']}
            />
            <SnapShotModal
              heading={'My SQL Process List'}
              snapshotData={outageIssues[incidentInfo[0]]['mysqlProcessList']}
            />
          </Flex>
        </View>
        {/*Informational Links Section*/}
        <View width={'50%'}>
          <Heading level={3}>INFORMATIONAL LINKS</Heading>
          <Divider size={'M'} marginBottom={'size-50'}/>
          {/*Static links regardless of incident selected*/}
          <Flex direction={"column"} gap={"size-50"}>
            <Link isQuiet>
              <a href={informationLinks['cloud_url']} target={'_blank'}>Project URL</a>
            </Link>
            <Link isQuiet>
              <a href={informationLinks['newRelicOneAccountLink']+'&platform[timeRange][begin_time]='+timeStampMinusOneHour+'&platform[timeRange][end_time]='+incidentTimestamp} target={'_blank'}>New Relic ({incidentInfo[1]})</a>
            </Link>
            <Link isQuiet>
              <a href={informationLinks['nerdPackUrl']+'&platform[timeRange][begin_time]='+timeStampMinusOneHour+'&platform[timeRange][end_time]='+incidentTimestamp} target={'_blank'}>Observation For Adobe Commerce ({incidentInfo[1]})</a>
            </Link>
            {/*<Text>Fastly Links</Text>*/}
            {/*<FastlyLinksList>*/}
            {/*</FastlyLinksList>*/}
          </Flex>
        </View>
      </Flex>
    </View>
  );
};
export default OutageLinks;
