import React from 'react';
import styled from 'styled-components';
import Heading from '@react/react-spectrum/Heading';
import {ComboBox, Item, Picker} from '@adobe/react-spectrum'
import {useAsyncList} from 'react-stately'
import { loadClients } from 'state/dashboard/queries';
import { success } from "@react/react-spectrum/Toast";
import { Flex, Button, Meter,} from '@adobe/react-spectrum';
import ClickableTooltip from './clickableTooltip'

import GenerateReportButton from './header/generateReportButton';
import LeaveFeedbackButton from './header/leaveFeedbackButton';

import AdobeLogo from './header/img/Adobe_Experience_Cloud_logo_RGB.svg';
import AdobeLogoWhite from './header/img/Adobe_Corporate_White.svg';

import HelpOutline from '@spectrum-icons/workflow/HelpOutline';
import Feedback from '@spectrum-icons/workflow/Feedback';
import { useStateValue } from "state";
import useDashboard from "state/dashboard/hooks/useDashboard";
import useAccess from "state/dashboard/hooks/useAccess";

const HeaderDivider = styled.span`
  font-family: adobe-clean-light, Times, serif !important;
  left: 36%;
  font-size: 16px;
  margin: 0 8px;
`;
const Title = styled.span`
  font-family: adobe-clean, Times, serif !important;
  text-align: left;
  letter-spacing: 0.3px;
  font-size: 16px;
  margin-left: 15px;
  font-weight: 500;
`;
const SecondTitle = styled.span`
  margin: 0;
  font-family: adobe-clean-light, Times, serif !important;;
  text-align: left;
  letter-spacing: 0.7px;
  font-size: 16px;
`;

const UserName = styled.span`
  letter-spacing: 0px;
  font-size: 14px;
  color: rgb(179,179,179);
`;
const BoldHeading = styled(Heading)`
  font-family: adobe-clean-bold, Times, serif !important;
  color: #323232 !important;
  font-size: 18px;
`;

const EnvironmentStatusText = styled.p`
  font-family: adobe-clean, Times, serif !important;
  color: #747474 !important;
  font-size: 14px;
  font-weight: 400;
`;


const EnvironmentPicker = props => {
  let onSelectionChange = projectId => {
    if (projectId) {
      let environment = props.environments.find(x => x.client === projectId).name;
      sessionStorage.setItem('context', JSON.stringify({projectId: projectId}));
      props.reloadDashboard('', () => {
          return success('Environment Changed to "' + environment + '"', {timeout: 10000})
        }
      )
    }
  };

  return <Picker isQuiet
                 UNSAFE_className={'clientEnvironmentPicker'}
                   disallowEmptySelection
                   width="size-2000"
                   selectedKey={props.selectedClient.hash}
                   items={props.environments}
                   onSelectionChange={onSelectionChange}
  >
    {item => <Item key={item.client}>{item.name}</Item>}
  </Picker>
}

const ClientPicker = props => {
  let isFocused = React.useRef(false);
  let list = useAsyncList({
    async load({ signal, cursor, filterText, selectedKeys }) {
      let filter = filterText === props.selectedClient.name ? null : filterText
      // If no cursor is available, then we're loading the first page,
      // filtering the results returned via a query string that
      // mirrors the ComboBox input text.
      // Otherwise, the cursor is the next URL to load,
      // as returned from the previous page.
      let res = await loadClients(
        filter,
        cursor,
        signal,
      );
      let json = await res.json();

      let selectedText;
      let selectedKey = selectedKeys.values().next().value;

      // If selectedKey exists and combobox is not focused, update the input value with the selected key text
      // This allows the input value to be up to date when items load for the first time or the selected key text is updated server side.
      if (!isFocused.current && selectedKey) {
        let selectedItemName = json.data.find((item) =>
          item.name === selectedKey
        )?.name;
        if (selectedItemName != null && selectedItemName !== filterText) {
          selectedText = selectedItemName;
        }
      }
      list.removeSelectedItems()
      return {
        items: json.data,
        cursor: json.next_page_url,
        filterText: selectedText ?? filterText
      };
    },
    initialSelectedKeys: [props.selectedClient.name],
    getKey: (item) => item.name
  });

  let onSelectionChange = (key) => {
    let itemText = list.getItem(key)?.name;
    list.setSelectedKeys(new Set([key]));
    list.setFilterText(itemText);
    let projectId = list.getItem(key)?.hash;
    if (projectId) {
      sessionStorage.setItem('context', JSON.stringify({projectId: projectId}));
      props.reloadDashboard('', () => {
        return success('Project Changed to "' + itemText + '"', {
          timeout: 10000
        })
      })
    }
  };

  let onInputChange = (value) => {
    // Clear key if user deletes all text in the field
    if (value === '' && list.selectedKeys.values().next().value) {
      list.setSelectedKeys(new Set([null]));
    }
    list.setFilterText(value);
  };

  let selectedKey = list.selectedKeys.values().next().value;
  return (
    <ComboBox
      isQuiet
      UNSAFE_className={'clientPicker'}
      onFocusChange={(focus) => isFocused.current = focus}
      selectedKey={selectedKey}
      onSelectionChange={onSelectionChange}
      items={list.items}
      inputValue={list.filterText}
      onInputChange={onInputChange}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      width={'size-6000'}
    >
      {(item) => <Item key={item.name}>{item.name}</Item>}
    </ComboBox>
  );
}

const Header = () => {
  const [{ dashboard }] = useStateValue();
  const [,, reloadDashboard] = useDashboard();
  const hasRole = useAccess();

  const getEnvironmentField = () => {
    if ((dashboard.imsEnabled && !hasRole('publicUser')) || hasRole('devTeam')) {
      return <EnvironmentPicker
        reloadDashboard={reloadDashboard}
        environments={dashboard.client.environments}
        selectedClient={dashboard.client} />
    }
    if (
      dashboard.client.environments.length === 1 || hasRole('publicUser')
      || (!hasRole('devTeam') && !dashboard.imsEnabled)
    ) {
      return <EnvironmentStatusText>Environment: {dashboard.client.environment}</EnvironmentStatusText>
    }
  }

  const getClientField = () => {
    if (hasRole('publicUser') || (!hasRole('devTeam') && !dashboard.imsEnabled)) {
      return dashboard.client.name
    }

    if ((dashboard.imsEnabled && !hasRole('publicUser')) || hasRole('devTeam')) {
      return <ClientPicker selectedClient={dashboard.client} reloadDashboard={reloadDashboard}/>
    }
  }

  const getProjectSection = () => {
    return <>
      <BoldHeading>
        {getClientField()}
      </BoldHeading>
      <Flex direction={'row'} gap={'size-150'}>
        {getEnvironmentField()}
      </Flex>
    </>
  }

  return (
    <header>
      <Flex
        direction="row"
        height="single-line-height"
        alignItems="center"
        justifyContent="space-between"
        marginStart="40px"
        minHeight="50px"
        UNSAFE_style={{marginBottom:'0 !important', backgroundColor:'#080808', marginLeft:'0', color:'white'}}
      >
        <Flex
          width="45%"
          alignItems="center"
          justifyContent="start"
          UNSAFE_style={{color:'white'}}
        >
          <img className="adobeLogo" src={AdobeLogo} alt="logo" width="25px" height="25px" />
          <Title>Adobe Commerce</Title>
          <HeaderDivider>|</HeaderDivider>
          <SecondTitle>Site-Wide Analysis Tool</SecondTitle>
        </Flex>
        <Flex
          width="45%"
          alignItems="center"
          justifyContent="end"
        >
          <UserName>{dashboard.user.name}</UserName>
          <LeaveFeedbackButton UNSAFE_style={{marginLeft:'10px'}}>
            <Feedback UNSAFE_style={{color: 'rgb(179,179,179)'}} size="S"/>
          </LeaveFeedbackButton>
          <ClickableTooltip
            color="rgb(179,179,179)"
            code="helpSWAT">
            <HelpOutline size="S" UNSAFE_style={{marginLeft:'20px'}}/>
          </ClickableTooltip>
          <Button elementType="a" href="http://adobe.com" target="_blank" UNSAFE_className="unvisibleButton" data-ui-id="adobe-logo-link">
            <img className="adobeLogoWhite" src={AdobeLogoWhite} alt="logo" width="25px"/>
          </Button>
        </Flex>
      </Flex>
      <Flex
        direction="row"
        height="size-1100"
        gap="size-100"
        justifyContent="space-between"
        UNSAFE_className="second-header-row"
        UNSAFE_style={{backgroundColor: '#F5F5F5', marginTop:'0px', paddingTop:'0.5rem'}}
      >
        <Flex direction={'column'} width="45%" justifyContent="start">
          {getProjectSection()}
        </Flex>
      <Flex
          width="45%"
          alignItems="center"
          justifyContent="end"
        >
          <GenerateReportButton />
          <Flex direction="column">
            <Meter
              label={'Health Index'}
              value={Math.round(dashboard.client.healthIndex)}
              variant={'positive'}
              />
          </Flex>
      </Flex>
      </Flex>
    </header>
  );
};

export default Header;
