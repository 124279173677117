import {SET_DASHBOARD, DISPLAY_SPLASH_PAGE, DISPLAY_ERROR_PAGE, DISPLAY_IMS_ERROR_PAGE} from './actions';
import deepmerge from "deepmerge";

export const INITIAL_STATE = {
  dashboard: {
    displayWidgets: true,
    imsEnabled: false,
    client: {
      id: '',
      hash: '',
      name: '',
      status: '',
      accountUrl: '',
      healthIndex: 0,
      hostingType: '',
      environments: [],
      selectedEnvironment: '',
    },
    user: {
      name: '',
      emailHash: '',
      roles: []
    },
    issueSummary: {
      P0: 0,
      P1: 0,
      P2: 0,
      P3: 0,
      P4: 0,
    },
    widget: {
      uct: {
        status: '',
        list: [],
        uctAvailable: true,
        isDisplayedError: false,
        report: '',
        issues: {
          critical: 0,
          error: 0,
          warning: 0
        },
      },
      securityScan: {
        status: false,
        list: [],
        report: '',
        issues: [],
      },
      extensions: {
        outdated: 0,
        upToDate: 0,
        disabled: 0,
        unknown: 0
      },
      recommendations: {
        P0: 0,
        P1: 0,
        P2: 0,
        P3: 0,
        P4: 0,
      },
      newrelicAlerts: [],
      recommendedVersions: {
        list: []
      },
      recommendedPatches: [],
      applicationInfo: [],
      securityCenter: {
        list: [],
        issues: [],
        status: 0,
      }
    },
    notifications: [
      {
        id: '',
        type: '',
        title: '',
        notification: '',
        date: ''
      }
    ],
    counts: {
      recommendations: 0,
      exceptions: 0,
      extensions: 0,
      alerts: 0,
      patches: 0,
      outages: 0,
    },
    displaySplashPage: true,
    displayMaintenancePage: false,
    displayUnauthorizedPage: false,
    imsErrorCode: '00000',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD:
      let dashboardData = deepmerge.all(
          [state, action.payload, {
            displayMaintenancePage: false,
            displayUnauthorizedPage: false,
          }
        ],
      );
      if (action.payload.widget) {
        dashboardData.widget = deepmerge(
          INITIAL_STATE.dashboard.widget,
          action.payload.widget,
        );
      }
      if (action?.payload?.client?.environments) {
        dashboardData.client.environments = action.payload.client.environments;
      }
      return dashboardData;
    case DISPLAY_SPLASH_PAGE:
      return {
        ...state,
        displaySplashPage: action.payload,
        displayMaintenancePage: false,
        displayUnauthorizedPage: false,
      };
    case DISPLAY_ERROR_PAGE:
      return {
        ...state,
        displayMaintenancePage: action.payload.displayMaintenancePage,
        displayUnauthorizedPage: action.payload.displayUnauthorizedPage
      };
    case DISPLAY_IMS_ERROR_PAGE:
      return {
        ...state,
        imsErrorCode: action.payload.imsErrorCode,
      };
    default:
      return state;
  }
};
