import React, {useEffect} from 'react';
import {ProgressCircle} from '@react-spectrum/progress';
import {Heading} from '@adobe/react-spectrum';
import useInfoSections from 'state/infoSections/hooks/useInfoSections';
import Masonry from 'react-masonry-css'

const InfoTabSections = ({infoTabTitle, layout}) => {
  const [infoSections, isLoading, request] = useInfoSections();

  const getTabData = () => {
    return infoSections.find(item => item.title === infoTabTitle);
  };

  useEffect(() => {
      request(infoTabTitle);
  }, [infoTabTitle]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>
  }

  const tabData = getTabData();
  if (!tabData) {
    return <div style={{textAlign: 'center'}}>
      <Heading level={3}>There are no data</Heading>
    </div>
  }

  const getDirection = layout => {
    if (layout === '1-column') {
      return '1';
    }
    if (layout === '2-column') {
      return '2';
    }
  }

  const renderSection = (section, sectionIndex) => {
    if (section.hasOwnProperty('frontend_component') && section.frontend_component.hasOwnProperty("type")) {
      const Component = require('./sectionType/' + section.frontend_component.type + 'Type').default;
      return <Component key={sectionIndex} section={section} sectionIndex={sectionIndex}/>;
    }
    return <></>;
  }

  return <Masonry
    breakpointCols={getDirection(layout)}
    className="infoTab-grid"
    columnClassName="infoTab-grid_column">
    {tabData.sections.map((section, index) => renderSection(section, index))}
  </Masonry>
};

export default InfoTabSections;
