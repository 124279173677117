import React, {useEffect, useState} from 'react';
import useExceptions from "state/exceptions/hooks/useExceptions";
import ExceptionDetails from "./exceptionDetails";
import ListDataSource from "@react/react-spectrum/ListDataSource";
import {TableView} from '@react/react-spectrum/TableView';
import {Flex, ProgressCircle, View} from "@adobe/react-spectrum";
import './exceptionsGrid.css';
import useDashboard from "../../../state/dashboard/hooks/useDashboard";

class ExceptionsDataSource extends ListDataSource {
  exceptions = [];
  async load(sortDescriptor) {
    if (sortDescriptor) {
      this.exceptions.sort(
        (a, b) => (a[sortDescriptor.column.key] > b[sortDescriptor.column.key])
          ? sortDescriptor.direction : -sortDescriptor.direction
      );
    }
    return this.exceptions;
  }

  getExceptions() {
    return this.exceptions;
  }
}
const columns = [
  {
    title: "Last Detected (UTC)",
    key: "date",
    align: "left",
    minWidth: 135,
    sortable: true,
    divider: false
  },
  {
    title: "Exception Details",
    key: "message",
    align: "left",
    minWidth: 500,
    sortable: true,
    divider: false
  },
  {
    title: "Count",
    key: "count",
    align: "right",
    sortable: true,
    divider: false
  },
];

const exceptionsDataSource = new ExceptionsDataSource();

const ExceptionsGrid = () => {
  const [exceptions, isLoading, request] = useExceptions();
  const [chosenException, setChosenException] = useState(false);
  const [dashboard,,] = useDashboard();
  useEffect(() => {
      request();
  }, [dashboard.client]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
  }

  exceptionsDataSource.exceptions = exceptions;

  exceptionsDataSource.load().then(results => {
    if (results.length && chosenException === false) {
      setChosenException(results[0]);
    }
  })

  const renderCell = (column, data) => {
    let itemData = data[column.key];
    if (column.key === 'message' && data[column.key].length > 100) {
      itemData = data[column.key].slice(0, 100) + '...';
    }
    return <View>
      <span>{itemData}</span>
    </View>;
  };

  const onCellClick = (exceptionsDataSource)  => {
    return (column, rowIndex) => {
      setChosenException(exceptionsDataSource.getExceptions()[rowIndex])
    }
  };

  const tableView = <TableView

    columns={columns}
    dataSource={exceptionsDataSource}
    renderCell={renderCell}
    onCellClick={onCellClick(exceptionsDataSource)}
    defaultSortDescriptor={{column: columns[2], direction: TableView.SORT_DESCENDING}}
    allowsSelection={false}
    className="exceptionGrid"
    rowHeight={14}
    quiet={true}
  />;
  return (
    <Flex direction="row" gap="size-200" height="700px" style={{justifyContent: "center"}}>
      <View flexBasis="50%">{tableView}</View>
      <View flexBasis="50%"><ExceptionDetails exception={chosenException}/></View>
    </Flex>
  );
};

export default ExceptionsGrid;
export {exceptionsDataSource};
