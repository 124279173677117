import React from "react";
import PickerController from "../inputFields/picker";

function ContactReasonField({ control, setValue, errors, fields }) {
  const contactReason = fields.contactReason;

  return (
    <PickerController
      required={true}
      label={contactReason.label}
      validationState={errors[contactReason.name] ? "invalid" : null}
      name={contactReason.name}
      items={contactReason.items}
      control={control}
      setValue={setValue}
      defaultValue={contactReason.value}
    />
  );
}

export default ContactReasonField;
