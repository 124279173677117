import React from "react";
import {Flex, View} from "@adobe/react-spectrum";
import {TableView} from "@react/react-spectrum/TableView";
import ListDataSource from "@react/react-spectrum/ListDataSource";
import "./assets/servicesTable.css";

const servicesTable = ({item}) => {

  let tableData = [];

  class TableDataSource extends ListDataSource {
    load(sortDescriptor) {
      if (sortDescriptor && Array.isArray(tableData)) {
        tableData.sort(
          (a, b) => (a[sortDescriptor?.column?.key] > b[sortDescriptor?.column?.key])
            ? sortDescriptor?.direction : -sortDescriptor?.direction
        );
      }

      return tableData;
    };
  }

  const renderCell = (column, data) => {
    let text = data[column.key];
    let cssClass = "";
    if (column.key === "Status") {
      if (text.startsWith("Active")) {
        cssClass = "status-active";
      } else if (text.startsWith("Ending")) {
        cssClass = "status-ending";
      } else if (text.startsWith("Expired")) {
        cssClass = "status-expired";
      }
    }

    return <View>
      <span className={cssClass}> </span>
      <span style={{fontSize: "14px"}}>{text}</span>
    </View>;
  };

  const tableDataSource = new TableDataSource();

  const tableView = item => {
    let data = JSON.parse(item.data);
    let columns = [];

    tableData = data;

    if (Array.isArray(data) && data.length > 0) {
      Object.keys(data[0]).forEach(column =>  {
        let columnObj = {
          title: column,
          key: column,
          align: "left",
          sortable: true,
          divider: false
        }
        let width = 150;
        switch (column) {
          case "Name":
            columnObj.minWidth = 250;
            break;
          case 'Status':
          case 'EOL Date':
            columnObj.width = 80;
            break;
          case 'Issuer':
            columnObj.width = 180;
            break;
          default:
            columnObj.width = 100;
        }
        columns.push(columnObj)
      });
    }

    if (!columns.length) {
      return;
    }

    return <TableView
      columns={columns}
      dataSource={tableDataSource}
      renderCell={renderCell}
      defaultSortDescriptor={{column: columns[0], direction: TableView.SORT_ASCENDING}}
      allowsSelection={false}
      className="services-grid"
      rowHeight={14}
      quiet={true}
    />;
  }


  return <>
    <Flex direction="row" height="size-4600">
      <View flexBasis="100%">{tableView(item)}</View>
    </Flex>
  </>
};
export default servicesTable;
