import React from "react";
import PickerController from "../inputFields/picker";

function InfrastructureOrApplicationField({
  contactReason,
  control,
  setValue,
  errors,
  fields,
}) {
  const commerceApplicationContactReason =
    fields.commerceApplicationContactReason;
  const commerceInfrastructureContactReason =
    fields.commerceInfrastructureContactReason;

  if (contactReason === "com_cr_adobe_commerce_cloud_application") {
    return (
      <PickerController
        required={true}
        label={commerceApplicationContactReason.label}
        validationState={
          errors[commerceApplicationContactReason.name] ? "invalid" : null
        }
        name={commerceApplicationContactReason.name}
        items={commerceApplicationContactReason.items}
        control={control}
        setValue={setValue}
      />
    );
  } else if (contactReason === "com_cr_adobe_commerce_cloud_infrastructure") {
    return (
      <PickerController
        required={true}
        label={commerceInfrastructureContactReason.label}
        validationState={
          errors[commerceInfrastructureContactReason.name] ? "invalid" : null
        }
        name={commerceInfrastructureContactReason.name}
        items={commerceInfrastructureContactReason.items}
        control={control}
        setValue={setValue}
      />
    );
  }
  return null;
}

export default InfrastructureOrApplicationField;
