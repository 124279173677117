import React from 'react';
import Widget from "./widget";
import {
  TableView,
  TableHeader,
  Column,
  Row,
  TableBody,
  Cell,
  Flex,
} from '@adobe/react-spectrum';
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import styled from "styled-components";
import useDashboard from "../../../state/dashboard/hooks/useDashboard";

const Description = styled.div`
  color: #6E6E6E;
  text-transform: capitalize;
`;
const InstalledVersion = styled.span`
  color: #C9252D;
`;

let columns = [
  {name: 'Name'},
  {name: 'Installed ver'},
  {name: 'Recommended ver'},
];

const RecommendedVersionsWidget = (layoutItem) => {
  const [dashboard,,] = useDashboard();
  if (dashboard.widget.recommendedVersions.list.length === 0) {
    //Return No Recommendations state if there is no recommendations in DB.
    return <Widget layoutItem={layoutItem} title="Non recommended software in use">
      <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height="100%" width={'100%'}>
        <CheckmarkCircle color={'positive'} size={'XL'} marginBottom={'10px'}/>
        <Description>All software complies with recommendations</Description>
      </Flex>
    </Widget>;
  }

  return <Widget layoutItem={layoutItem} title="Non recommended software in use">
      <Flex width={'100%'} direction="column" UNSAFE_style={{overflow: 'hidden'}}>
        <TableView flexGrow isQuiet UNSAFE_style={{margin: '0 -1rem' ,border:'none'}}>
          <TableHeader>
            <Column align={'start'} width={'40%'}>
              {columns[0].name}
            </Column>
            <Column align={'start'} width={'30%'}>
              {columns[1].name}
            </Column>
            <Column align={'start'} width={'30%'}>
              {columns[2].name}
            </Column>
          </TableHeader>
            <TableBody items={dashboard.widget.recommendedVersions.list} UNSAFE_stye={{border:'none'}} >
              {item => (
                <Row>
                  <Cell>
                    {item.name}
                  </Cell>
                  <Cell>
                    <InstalledVersion>{item.installed}</InstalledVersion>
                  </Cell>
                  <Cell>
                    {item.recommended}
                  </Cell>
                </Row>
              )}
            </TableBody>
        </TableView>
      </Flex>
    </Widget>;
};


export default RecommendedVersionsWidget;

