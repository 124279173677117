import React from 'react';
import {Divider, Text, View, Flex, Heading} from '@adobe/react-spectrum'
import {Accordion, AccordionItem} from "@react/react-spectrum/Accordion";

const RequestsToOrigin = ({incidentInfo, outageIssues}) => {

  let incidentRequestsToOrigin = null;

  if (outageIssues[incidentInfo[0]]['outageRequestsToOrigin'] === undefined) {
    return (
      <View width={'100%'}>
        <Heading level={3}>Requests to origin</Heading>
        <Divider size={'M'} marginBottom={'size-100'}/>
        <Text>No data available for this specific incident</Text>
      </View>
    )
  }

  let issueData = outageIssues[incidentInfo[0]]['outageRequestsToOrigin'];

  incidentRequestsToOrigin = issueData.map((item) => {
    if (item.time !== 'One hour before outage') {
      return (
        <>
          <Flex direction={"row"} gap={"size-300"} justifyContent={'space-between'}>
            <Text>{item.time + ' before outage'}</Text>
            <Text>{item.value}</Text>
          </Flex>
          <Divider marginY={'size-100'} size={'S'}/>
        </>
      )
    } else {
      return (
        <>
          <Flex direction={"row"} gap={"size-300"} justifyContent={'space-between'}>
            <Text>{item.time}</Text>
            <Text>{item.value}</Text>
          </Flex>
          <Divider marginY={'size-100'} size={'S'}/>
        </>
      )}
  })

  return(
    <Accordion>
      <AccordionItem header={'Requests to origin'}>
        <View>
          <Text>Compare number of requests over time, relative to the time of the outage.</Text>
          <Flex direction={"row"} gap={"size-300"} justifyContent={'space-between'} marginTop={'size-100'}>
            <Heading level={3} UNSAFE_style={{fontSize: '14px', fontWeight: '300', color: '#6E6E6E'}}>WHEN</Heading>
            <Heading level={3} UNSAFE_style={{fontSize: '14px', fontWeight: '300', color: '#6E6E6E'}}>Average Requests per hour</Heading>
          </Flex>
          <Divider size={'M'} marginBottom={'size-100'}/>
          {incidentRequestsToOrigin}
        </View>
      </AccordionItem>
    </Accordion>
  )
};

export default RequestsToOrigin;
