import { useState } from 'react';
import { useStateValue } from 'state/index';
import { loadContextHelp } from '../queries';
import { setContextHelp } from '../actions';
import { onException } from "../../httpStatusHandler";

const useContextHelp = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    if (isLoading) {
      return [state.contextHelp, isLoading, request];
    }
    setIsLoading(true);

    await loadContextHelp()
      .then(response => {
        if (response) {
          dispatch(setContextHelp(response.data));
        }
        setIsLoading(false);
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
  };

  return [state.contextHelp, isLoading, request];
};

export default useContextHelp;
