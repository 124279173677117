import {getConfig} from 'config';
import {postData, getDataWithParams} from '../dataProvider';

export const findProduct = (data) => {
  return postData(`${getConfig('productSearchUrl')}`, data, {});
}

export const getProductSearchResults = (data, cancelToken) => {
  return getDataWithParams(`${getConfig('productDataUrl')}`, data, cancelToken, {});
}

