import {SET_FORM_FIELDS} from './actions';

export const INITIAL_STATE = {
  formFields: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FORM_FIELDS:
      return {
        ...state,
        formFields: action.payload,
      };
    default:
      return state;
  }
};
