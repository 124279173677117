import React from 'react';
import AlertsGrid from './components/alertsGrid';

const AlertsTab = () => {
  return (
      <AlertsGrid/>
  );
}

export default AlertsTab;
