import React from 'react';
import CheckSection from "./CheckSection";
import {
  Heading,
  TableView,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell
} from '@adobe/react-spectrum';
class Other extends CheckSection {
  getRightSection() {
    return (
      <div>
        <Heading level={2} marginTop={30}>Misconfigured Indexers List</Heading>
        <TableView>
          <TableHeader>
            <Column>Indexer</Column>
            <Column>Current Indexer Mode (Not Recommended)</Column>
          </TableHeader>
          <TableBody>
            {this.getRecommendationsDataArray('indexersMode', 'wrong_indexers_mode').map(( listValue ) => {
              return (
                <Row>
                  <Cell>{listValue['indexer name']}</Cell>
                  <Cell>{listValue['indexer mode']}</Cell>
                </Row>
              );
            })}
            {this.getRecommendationsDataArray('indexersMode', 'wrong_indexers_mode').length === 0 && (
              <Row>
                <Cell>No misconfigured indexers found</Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
        <br/>
        <Heading level={2} marginTop={30}>Additional Information</Heading>
        <TableView>
          <TableHeader>
            <Column maxWidth={80}>Status</Column>
            <Column>Supporting Information</Column>
          </TableHeader>
          <TableBody>
            <Row>
              <Cell maxWidth={80}>{this.isRecommendationAppliedStatus('newrelicAlertsSubscription')}</Cell>
              <Cell>New Relic default Alert policies configured.</Cell>
            </Row>
            <Row>
              <Cell maxWidth={80}>{this.getInfoIcon()}</Cell>
              <Cell><b>OpCache Excludes Status:</b> {this.getInfoValue('opcacheExcludes') || '(No data available)'}</Cell>
            </Row>
          </TableBody>
        </TableView>
      </div>
    );
  }
}
export default Other;
