import React from 'react';
import Widget from "./widget";
import useDashboard from "state/dashboard/hooks/useDashboard";
import {
  TableView,
  TableHeader,
  Column,
  Row,
  TableBody,
  Cell,
  Link,
  Flex,
} from '@adobe/react-spectrum';
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import styled from "styled-components";
import useRecommendedPatches from "../../../state/patches/hooks/useRecommendedPatches";
import {GetUiId} from "../../base/components/jsUtilities";

const Description = styled.div`
  color: #6E6E6E;
  text-transform: capitalize;
`;

const RecommendedPatchesWidget = (layoutItem) => {
  const [dashboard,,] = useDashboard();
  const viewRecommendedPatchesEvent = useRecommendedPatches('widget', 'filter');

  const viewRecommendedPatches = () => {
    viewRecommendedPatchesEvent(() => {});
    window.location.href = '/tab/patches/is_recommended|Yes'
  };

  if (dashboard.widget.recommendedPatches.length === 0) {
    return <Widget title={'Recommended Patches'}>
      <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height="100%" width="100%">
        <CheckmarkCircle color={'positive'} size={'XL'} marginBottom={'10px'}/>
        <Description>No recommended patches</Description>
      </Flex>
    </Widget>
  }
  return <Widget layoutItem={layoutItem}
                 title="Recommended Patches"
                 headerContent={
                   <Link variant={'primary'} isQuiet onPress={viewRecommendedPatches}>
                     <a target="_self" data-ui-id="recommended-patches-widget-view-all">
                       View All
                     </a>
                   </Link>
                 }>
    <Flex width={'100%'} direction="column" UNSAFE_style={{overflow: 'hidden'}}>
      <TableView flexGrow isQuiet UNSAFE_style={{margin: '0 -1rem', border: 'none'}}>
        <TableHeader>
          <Column align={'start'} width={'25%'}>Patch ID</Column>
          <Column align={'start'} width={'75%'}>Description</Column>
        </TableHeader>
        <TableBody items={dashboard.widget.recommendedPatches} UNSAFE_stye={{border: 'none'}}>
          {item => (
            <Row>
              <Cell><Link variant={'primary'} isQuiet onPress={viewRecommendedPatches}>
                <a target="_self" data-ui-id={GetUiId(item.patch_id) + "-widget-link"}>{item.patch_id}</a></Link></Cell>
              <Cell>{item.patch_title}</Cell>
            </Row>
          )}
        </TableBody>
      </TableView>
    </Flex>
  </Widget>;
};

export default RecommendedPatchesWidget;

