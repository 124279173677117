import { useState } from 'react';
import { useStateValue } from 'state/index';
import download from 'downloadjs';
import {downloadUctReport} from '../queries';
import { onException } from "state/httpStatusHandler";

const useUctReport = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await downloadUctReport()
      .then (response => {
        if (response && response.status === 200) {
          const contentDisposition = response.headers['content-disposition'];
          const fileReader = new FileReader();

          fileReader.onload = () => {
            let result = [];

            if (response.data.type === 'application/json') {
              result = JSON.parse(this.result);
            }

            if (result.redirect === true) {
              window.location.href = result.location;
              return;
            }

            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(
                /filename=(?<filename>.*)/
              );
              if (
                Object.prototype.hasOwnProperty.call(
                  fileNameMatch.groups,
                  'filename'
                )
              ) {
                download(response.data, fileNameMatch.groups.filename);
              }
            }
          };
          fileReader.readAsText(response.data);
        }
      })
      .catch(errorObject => onException(errorObject, dispatch, state));

    setIsLoading(false);
  };

  return [isLoading, request];
};

export default useUctReport;
