import { useState } from 'react';
import { useStateValue } from 'state/index';
import { loadInfoTabs } from '../queries';
import { setInfoTabs } from '../actions';
import { onException } from "../../httpStatusHandler";

export const useInfoTabs = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await loadInfoTabs()
      .then(response => {
        setIsLoading(false);
        dispatch(setInfoTabs(response.data.data));
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
  };

  return [state.infoTabs, isLoading, request];
};

export default useInfoTabs;
