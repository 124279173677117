import {useStateValue} from 'state/index';
import {onException} from "state/httpStatusHandler";
import {addUrl} from "../queries";

const useSecurityScanAdd = () => {
  const [state, dispatch] = useStateValue();
  return async (callback) => {
    await addUrl()
      .then(() => callback())
      .catch(errorObject => onException(errorObject, dispatch, state));
  };
}

export default useSecurityScanAdd;
