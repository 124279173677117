import {SET_INFO_TAB_SECTIONS} from './actions';

export const INITIAL_STATE = {
  infoTabsSections: []
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_INFO_TAB_SECTIONS:

      const index = state.findIndex(element => element.title === action.payload.tab_title);
      if (index === -1) {
        state.push({
          title: action.payload.tab_title,
          sections: action.payload.sections.sort((a, b) => (b['position'] < a['position']) ? 1 : -1)
        });
      } else {
        state[index] = {
          title: action.payload.tab_title,
          sections: action.payload.sections.sort((a, b) => (b['position'] < a['position']) ? 1 : -1)
        };
      }
      return state;
    default:
      return state;
  }
};
