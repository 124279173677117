import React from "react";
import { Controller } from "react-hook-form";
import { TextArea } from "@adobe/react-spectrum";

function TextAreaController({
  required,
  label,
  validationState,
  name,
  control,
  defaultValue
}) {
  return (
    <Controller
      rules={{ required: required }}
      as={
        <TextArea
          id={name}
          isRequired={required}
          label={label}
          width={"100%"}
          validationState={validationState}
          defaultValue={defaultValue ? defaultValue : ""}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}

export default TextAreaController;
