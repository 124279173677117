import {SET_PATCH_ITEMS, SET_IS_MQP_INSTALLED, SET_MQP_VERSION} from './actions';

export const INITIAL_STATE = {
  isMqpInstalled: false,
  mqpVersion: [],
  items: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_IS_MQP_INSTALLED:
      return {
        ...state,
        isMqpInstalled: action.payload
      };
    case SET_MQP_VERSION:
      return {
        ...state,
        mqpVersion: action.payload
      };
    case SET_PATCH_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};
