import {preLaunchChecksEmailSummary} from "../emailAction";
import { error, success } from "@react/react-spectrum/Toast";
const preLaunchChecksEmailData = async (data) => {
  await preLaunchChecksEmailSummary(data)
    .then(response => {
      success('Email Summary sent out.');
    })
    .catch(errorObject => {
      if (errorObject.response.hasOwnProperty('data')) {
        if (errorObject.response.data.hasOwnProperty('error')) {
          return error(errorObject.response.data.error , {timeout: 60000})
        }
      }
      error('Something went wrong. Please try later.' , {timeout: 60000})
    });
};

export const preLaunchChecksEmailSummaryAction = (data) => {
  return preLaunchChecksEmailData({data});
};

export default preLaunchChecksEmailSummaryAction;
