import { SET_CONTEXT_HELP } from './actions';

export const INITIAL_STATE = {
  contextHelp: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTEXT_HELP:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
