import React from "react";
import {
  Text,
  Heading,
  Divider,
  Content,
  DialogTrigger,
  Dialog,
  ActionButton,
  ButtonGroup,
  Button
} from "@adobe/react-spectrum";
import "./assets/generalDialog.css";
import styled from "styled-components";
import {GetUiId} from "../../../base/components/jsUtilities";

const Field = styled.div`
  font-family: adobe-clean-light, Times, serif !important;
  color: #505050;
  font-size: 14px;
  font-weight: 300;
`;

const generalDialog = ({item}) => {
  return <>
    <DialogTrigger type="fullscreen">
      <ActionButton data-ui-id={GetUiId(item.title) + "-see-details-dialog"}>See Details</ActionButton>
      {(close) => (
        <Dialog>
          <Heading>{item.title}</Heading>
          <Divider />
          <Content>
            <Text>
              <pre>
                {item.data}
              </pre>
            </Text>
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>Close</Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  </>
};
export default generalDialog;
