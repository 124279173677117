import React from "react";
import TextFieldController from "./inputFields/textField";
import TextAreaController from "./inputFields/textArea";

function StaticFields({ control, errors, fields, subject, issueDescription }) {
  return (
    <>
      <TextFieldController
        required={true}
        label={"Subject"}
        validationState={errors.subject ? "invalid" : null}
        name={"subject"}
        control={control}
        defaultValue={fields.subject?.value}
      />
      <TextAreaController
        required={true}
        label={"Description"}
        validationState={errors.description ? "invalid" : null}
        name={"issueDescription"}
        control={control}
        defaultValue={issueDescription}
      />
      <TextAreaController
        required={false}
        label={fields.stepsToReproduce.label}
        validationState={null}
        name={fields.stepsToReproduce.name}
        control={control}
        defaultValue={fields.stepsToReproduce?.value}
      />
    </>
  );
}

export default StaticFields;
