export const SET_RECOMMENDATIONS = 'recommendations/SET_RECOMMENDATIONS';
export const SET_RECOMMENDATION_UPDATE = 'recommendations/SET_RECOMMENDATION_UPDATE';

export const setRecommendations = payload => ({
  type: SET_RECOMMENDATIONS,
  payload,
});

export const setRecommendationUpdate = (
  action,
  declaration_id, reason = null,
  dismissed_user = null,
  dismissed_time = null
) => ({
  type: SET_RECOMMENDATION_UPDATE,
  payload: { action, declaration_id, reason, dismissed_user, dismissed_time },
});
