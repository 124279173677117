import {useState} from 'react';
import {useStateValue} from 'state/index';
import {loadAlerts} from '../queries';
import {setAlertItems, setOACLink} from '../actions';
import {onException} from 'state/httpStatusHandler';

const useAlerts = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await loadAlerts()
      .then((response) => {
        if (response && response.hasOwnProperty('data')) {
          dispatch(setAlertItems(response.data.alerts));
          dispatch(setOACLink(response.data.oacLink));
        }
      })
      .catch(errorObject => onException(errorObject, dispatch, state));

    setIsLoading(false);
  };

  return [state.alerts.items, state.alerts.oacLink, isLoading, request];
};

export default useAlerts;
