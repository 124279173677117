export const SET_OUTAGE_INCIDENTS = 'outage_tabs/SET_OUTAGE_INCIDENTS';
export const SET_OUTAGE_ISSUES = 'outage_tabs/SET_OUTAGE_ISSUES';
export const SET_INFORMATION_LINKS = 'outage_tabs/SET_INFORMATION_LINKS';

export const setOutageIncidents = payload => ({
  type: SET_OUTAGE_INCIDENTS,
  payload,
});

export const setOutageIssues = payload => ({
  type: SET_OUTAGE_ISSUES,
  payload,
});

export const setInformationLinks = payload => ({
  type: SET_INFORMATION_LINKS,
  payload,
});
