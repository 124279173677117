import React, {useState, useEffect} from 'react';
import {ProgressCircle} from '@react-spectrum/progress';
import {TabView, Tab} from '@react/react-spectrum/TabView';
import useInfoTabs from 'state/infoTabs/hooks/useInfoTabs';
import InfoTabSections from "./infoTabSections";
import ClickableTooltip from "../../base/components/clickableTooltip";
import HelpOutline from "@spectrum-icons/workflow/HelpOutline";
import useDashboard from "state/dashboard/hooks/useDashboard";
import {GetUiId} from "../../base/components/jsUtilities";
import InternalOnlyMessage from "../../sharedComponents/internalOnlyMessage";

const InfoTabs = (props) => {
  const [infoTabs, isLoading, request] = useInfoTabs();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [dashboard,,] = useDashboard();

  useEffect(() => {
      request();
  }, [dashboard.client]);

  const LabelWithHelp = ({title, code}) => {
    return <span>
      {title}
      <ClickableTooltip
        color="#6e6e6e"
        margin="0 10px"
        code={code}
      >
        <HelpOutline size="XS"/>
        </ClickableTooltip>
    </span>
  };

  const camelize = string => {
    return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>
  }

  const tabsToShow = infoTabs.filter((tab) => {
    return tab.menuCode === props.menuCode;
  });

  return <>
    {props.menuCode === 'commerce_services' && <InternalOnlyMessage/>}
    {tabsToShow.length &&
    <TabView
      orientation="vertical"
      onSelect={index => setSelectedTabIndex(index)}
      className="infoTab"
    >
      {tabsToShow.map((tab, index) =>
        <Tab key={index} label={<LabelWithHelp title={tab.title} code={camelize('help ' + tab.title)}/>} selected={index === selectedTabIndex} data-ui-id={GetUiId(tab.title) + "-tab"}>
          <InfoTabSections layout={tab.layout} infoTabTitle={tab.title}/>
        </Tab>
      )}
    </TabView>}
  </>
};

export default InfoTabs;
