import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../CheckScore/AppContext';
import useAccess from "state/dashboard/hooks/useAccess";

import {
  Checkbox,
  TextArea,
  Content,
} from '@adobe/react-spectrum';

const ChecklistCard = props => {

  const { globalState, setScore, setBlockers, setCheckboxes} = useAppContext();
  const hasRole = useAccess();

  let textAreaIdentifier = 'textarea:' + props.code;
  const filteredTextArea = globalState.textAreas.filter(item => item.id === textAreaIdentifier).pop();
  const getAnchorHtml = (altText, href) => {
    return `&nbsp;<a href=${href} target="_blank">${altText}</a>&nbsp;`;
  }

  /** only the internal users can
   *  - edit the checkboxes
   *  - edit the notes
   *  - trigger internal only actions
   *  - save progress
   * @returns {*}
   */
  const isReadOnly = () => {
    return !hasRole('internalUser')
  }
  const getDefaultValue = (filteredTextArea) => {
    if (filteredTextArea) {
      if ('content' in filteredTextArea) {
        return filteredTextArea.content;
      }
    }
    return '';
  }

  const handleCheckboxChange = (checkBoxIdentifier, checkScore, checkBlocker) => {
    let currentCheckbox = null;
    let currentCheckboxChecked = false;
    let checkboxes = globalState.checkboxes;

    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === checkBoxIdentifier) {
        currentCheckbox = checkbox;
        currentCheckboxChecked = !checkbox.checked;
        return { ...checkbox, checked: !checkbox.checked }
      } else {
        return checkbox
      }
    });

    setCheckboxes(updatedCheckboxes);

    let operation = currentCheckboxChecked;

    let newScore = 0;
    let blockers = globalState.blockers;

    if (operation) {
      newScore = Number(globalState.score) + checkScore;
      if (checkBlocker) {
        blockers = Number(globalState.blockers) - 1;
      }
    } else {
      newScore = Number(globalState.score) - checkScore;
      if (checkBlocker) {
        blockers = Number(globalState.blockers) + 1;
      }
    }

    newScore = newScore < 0 ? 0 : newScore;
    blockers = blockers < 0 ? 0 : blockers;

    setScore(newScore);
    setBlockers(blockers);
  };

  return (
    <div className="card-divider" id={props.id}>
      <div className="card">
        <div className="medium-6 columns -right">
          <div dangerouslySetInnerHTML={{__html: props.cardDescription}} />
        </div>
          { props.checkboxList && props.checkboxList.length > 0 &&
            props.checkboxList.map((checkbox, index) => {
              let checkboxLabel = checkbox.label;
              let checkDescription = checkbox.check_description;
              let checkBoxIdentifier = 'check:' + checkbox.name;
              let checkScore = checkbox.score ? checkbox.score : 0;
              let checkBlocker = checkbox.severity === 1;
              let blockerCssClass = checkBlocker ? 'input-group -child pl-blocker' : 'input-group -child';
              const filteredCheckbox = globalState.checkboxes.filter(item => item.id === checkBoxIdentifier).pop();

              if (checkbox.links) {
                checkbox.links.map((link, index) => {
                  let replaced = getAnchorHtml(link.alt, link.href);
                  checkboxLabel = checkboxLabel.replace(link.alt, replaced);

                  if (checkDescription) {
                    checkDescription = checkDescription.replace(link.alt, replaced);
                  }
                });
              }

              return (
                <div className={blockerCssClass} key={index}>
                  <Checkbox isReadOnly={isReadOnly()} isEmphasized={true} size="xl" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} isSelected={filteredCheckbox.checked} name={checkBoxIdentifier} value={checkBoxIdentifier} onChange={() => handleCheckboxChange(checkBoxIdentifier, checkScore, checkBlocker)}></Checkbox>
                  <label className="chk-label" dangerouslySetInnerHTML={{__html: checkboxLabel}}/>
                  {checkDescription &&
                    <Content>
                      <div className="check-description" dangerouslySetInnerHTML={{__html: checkDescription}} />
                    </Content>
                  }
                </div>
              )
            })
          }
        <div className="input-group -child pl-textarea">
          <TextArea label="Notes:" isReadOnly={isReadOnly()} maxLength={1950} isRequired={false} width="100%" min-height="100px" className='pl-textarea' name={'textarea:' + props.code} defaultValue={getDefaultValue(filteredTextArea)}/>
        </div>
      </div>
    </div>
  )
}

ChecklistCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardDescription: PropTypes.string,
  checklist: PropTypes.object,
  sectionTitle: PropTypes.string,
  warning: PropTypes.string,
  cnt: PropTypes.number,
  code: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired
};
export default ChecklistCard;
