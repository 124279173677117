import {acceptSplash, cancelSplash} from '../queries';
import { onException } from "state/httpStatusHandler";
import {useStateValue} from "../../index";

export const useSplash = () => {
  const [state, dispatch] = useStateValue();
  const requestAccept = async (callBack) => {
    return await acceptSplash()
      .then(response => {
        callBack();
        return response;
      })
      .catch(response => onException(response, dispatch, state));
  };
  const requestCancel = async (callBack) => {
    return await cancelSplash()
      .then(response => {
        callBack();
        return response;
      })
      .catch(response => {
        callBack();
        onException(response, dispatch, state);
        return response;
      });
  };
  return [requestAccept, requestCancel]
};
