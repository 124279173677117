import React from 'react';
import {useGainsight} from "state/base/hooks/useGainsight";
import {useStateValue} from "state";

const Gainsight = () => {
  const [isLoaded, isLoading, loadGainsight] = useGainsight();

  if (isLoading || isLoaded) {
    return null;
  }
  const [{dashboard}] = useStateValue();

  loadGainsight(() => {
    let hostname, userObject, accountObject;
    _satellite.outputEnabled = true;
    if(!window.hasOwnProperty('digitalData')){
      window.digitalData = {
        page: {},
        user: {},
        asset: {}
      };
    }
    window.digitalData.page.autoTrack = false;
    window.digitalData.user.id = dashboard.user.emailHash;
    window.digitalData.user.corpId = dashboard.client.hash;
    window.digitalData.user.corpName = dashboard.client.name;

    window.adminAnalyticsMetadata = {
      "secure_base_url": window.location.href,
      "product_edition": "SWAT",
      "user": dashboard.user.emailHash,
    };
    window.aptrinsic('set', 'globalContext', {"name": "SWAT"});

    if (dashboard.client.accountUrl) {
      hostname = new URL(dashboard.client.accountUrl).hostname;

      userObject = {
        id: dashboard.user.emailHash,
        accountId: hostname
      }

      accountObject = {
        id: hostname,
      }
      aptrinsic('identify', userObject, accountObject);
    } else {
      _satellite.track('gainsight_identify');
    }
  });

  return null;
}

export default Gainsight;
