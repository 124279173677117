import React from 'react';
import {preLaunchChecksDocGenAction} from "state/cloudReports/hooks/preLaunchChecksDoc";
import {checks} from "./checks";

import {
  Button,
  ActionButton, ButtonGroup, Content, Dialog, DialogTrigger, Divider, Header, Heading, Text
} from '@adobe/react-spectrum';

import FileChart from '@spectrum-icons/workflow/FileChart';

const DocGen = props => {

  const sendDoc = (close) => {
    preLaunchChecksDocGenAction(checks).then().catch();
    close();
  }

  return (
    <div className="quick-action email-summary" id={props.id}>
      <DialogTrigger>
        <ActionButton className="action-button"><FileChart /> Email the .DOCX template for the checklist &nbsp;</ActionButton>
        {(close) => (
          <Dialog>
            <Heading>Did you SAVE your progress?</Heading>
            <Header>Email the .DOCX</Header>
            <Divider />
            <Content>
              <Text>
                This document will not include the captured responses from the customer<br/>
              </Text>
              <Text>
                Only the checklist and automated support information
              </Text>
            </Content>
            <ButtonGroup>
              <Button variant="secondary" onPress={close}>Cancel</Button>
              <Button variant="accent" onPress={() => sendDoc(close)}>Confirm</Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogTrigger>
    </div>
  )
}

export default DocGen;
