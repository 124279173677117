import React from 'react';
import useAccess from 'state/dashboard/hooks/useAccess'
import useClientAccess from "state/dashboard/hooks/useClientAccess";

const Access = ({requiredRole, requiredHosting, silent, unauthorized, children, ...props}) => {
  const hasRole = useAccess();
  const hasClientAccess = useClientAccess();
  if (!hasRole(requiredRole) || !hasClientAccess(requiredHosting)) {
    if (silent) {
      return null;
    }
    if (unauthorized) {
      return {unauthorized};
    }
    return <>
      You are not authorized to see this block
    </>
  }

  return React.cloneElement(children, props);
};
export default Access;
