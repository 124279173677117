import React from 'react';
import CheckSection from "./CheckSection";
import {
  TableView,
  TableBody,
  Column,
  TableHeader,
  Cell,
  Row,
} from '@adobe/react-spectrum';

class Deployments extends CheckSection {
  getRightSection() {
    return (
      <div>
        <h2>Minification Settings</h2>
        <TableView>
          <TableHeader>
            <Column maxWidth={80}>Status</Column>
            <Column>Supporting Information</Column>
          </TableHeader>
          <TableBody>
            <Row>
              <Cell maxWidth={80}>{this.isRecommendationAppliedStatus('cssAssetsMinification')}</Cell>
              <Cell>CSS Minification</Cell>
            </Row>
            <Row>
              <Cell maxWidth={80}>{this.isRecommendationAppliedStatus('jsAssetsMinification')}</Cell>
              <Cell>JS Minification</Cell>
            </Row>
          </TableBody>
        </TableView>

        <h2>Currently configured list of themes</h2>
        <p>Even if you are using headless/pwa, still make sure the SCD Ideal state with SCD_MATRIX variable is configured to avoid these themes getting deployed unnecessarily during maintenance window.</p>
        <TableView>
          <TableHeader>
            <Column>Theme Path</Column>
            <Column>Area</Column>
            <Column>Theme Title</Column>
            <Column>Type</Column>
          </TableHeader>
          <TableBody>
            {this.getInfoArray('general-installed-themes').map(( listValue ) => {
              return (
                <Row>
                  <Cell>{listValue['theme_path']}</Cell>
                  <Cell>{listValue['area']}</Cell>
                  <Cell>{listValue['theme_title']}</Cell>
                  <Cell>{listValue['type']}</Cell>
                </Row>
              );
            })}
            {this.getInfoArray('general-installed-themes').length === 0 && (
              <Row>
                <Cell>No data available</Cell>
                <Cell></Cell>
                <Cell></Cell>
                <Cell></Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      </div>
    );
  }
}

export default Deployments;
