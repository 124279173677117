import { getConfig } from 'config';
import { postData } from '../dataProvider';

export const loadSupportCasesTab = (data) => {
  return postData(`${getConfig('supportCasesUrl')}`, data, {});
}

export const submitSupportCase = (data) => {
  return postData(`${getConfig('supportCaseSubmitUrl')}`, data, {});
}

export const getRecommendations = (data) => {
  return postData(`${getConfig('caseDeflectionUrl')}`, data, {});
}

export const solutionFound = (data) => {
  return postData(`${getConfig('supportCaseSolutionFoundUrl')}`, data, {});
}
