import {useState} from 'react';
import {useStateValue} from 'state/index';
import {loadExtensions} from '../queries';
import {setExtensions} from '../actions';
import {onException} from 'state/httpStatusHandler';

const useExtensions = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await loadExtensions()
      .then((response) => {
        if (response && response.hasOwnProperty('data')) {
          dispatch(setExtensions(response.data));
        }
      })
      .catch(errorObject => onException(errorObject, dispatch, state));

    setIsLoading(false);
  };

  return [state.extensions, isLoading, request];
};

export default useExtensions;
