import React from "react";
import { useState } from "react";
import { View, Flex } from "@adobe/react-spectrum";
import SubmitCaseForm from "./components/submitCaseForm";
import StepOne from "./components/steps/stepOne";
import { Meter } from "@adobe/react-spectrum";
import StepTwo from "./components/steps/stepTwo";
import InternalOnlyMessage from "../sharedComponents/internalOnlyMessage";

const SupportCasesTab = () => {
  let [page, setPage] = useState(1);
  let [issueDescription, setIssueDescription] = useState("");
  let [issueTicket, setIssueTicket] = useState("");

  function pageSwitch() {
    switch (page) {
      case 1:
        return (
          <StepOne
            setPage={setPage}
            issueTicket={issueTicket}
            setIssueTicket={setIssueTicket}
            issueDescription={issueDescription}
            setIssueDescription={setIssueDescription}
          />
        );
      case 2:
        return (
          <StepTwo
            issueTicket={issueTicket}
            setIssueTicket={setIssueTicket}
            issueDescription={issueDescription}
            setIssueDescription={setIssueDescription}
            setPage={setPage}
          />
        );
      case 3:
        return <SubmitCaseForm issueDescription={issueDescription} />;
      default:
        return (
          <StepOne
            setPage={setPage}
            issueTicket={issueTicket}
            setIssueTicket={setIssueTicket}
            issueDescription={issueDescription}
            setIssueDescription={setIssueDescription}
          />
        );
    }
  }

  return (
    <View>
      <Flex justifyContent={"space-between"} direction={"row"}>
        <InternalOnlyMessage />
        <Meter
          label="Progress"
          marginBottom="size-300"
          value={(100 / 3) * page}
          valueLabel={page + " of 3"}
          variant="positive"
          height={"size-600"}
        />
      </Flex>
      <Flex direction={"column"} gap={"size-100"} alignItems={"center"}>
        {pageSwitch()}
      </Flex>
    </View>
  );
};

export default SupportCasesTab;
