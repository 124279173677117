import {getConfig} from 'config';
import {putData} from '../dataProvider';

export const submitFeedback = (name, email, feedback) => {
  return putData(
    getConfig('feedbackUrl'),
    {name, email, feedback},
    {
      responseType: 'json',
      withCredentials: true,
    }
  );
};
