import dashboardReducer from './dashboard/reducers';
import recommendationsReducer from './recommendations/reducers';
import preLaunchReducer from './cloudReports/reducers';
import contextHelpReducer from './contextHelp/reducers';
import infoTabsReducer from './infoTabs/reducers';
import infoSectionsReducer from './infoSections/reducers';
import extensionsReducer from './extensions/reducers';
import exceptionsReducer from './exceptions/reducers';
import alertsReducer from './alerts/reducers';
import patchesReducer from './patches/reducers';
import outagesReducer from './outages/reducers';
import supportCasesReducer from './supportCasesTab/reducers';
import liveSearchReducer from './liveSearch/reducers';
import baseReducer from './base/reducers';

export default ({base, dashboard, recommendations, preLaunchChecks, contextHelp, infoTabs, infoSections, extensions, exceptions, alerts, patches, outages, supportCases, liveSearch}, action) => ({
  dashboard: dashboardReducer(dashboard, action),
  recommendations: recommendationsReducer(recommendations, action),
  preLaunchChecks: preLaunchReducer(recommendations, action),
  contextHelp: contextHelpReducer(contextHelp, action),
  infoTabs: infoTabsReducer(infoTabs, action),
  infoSections: infoSectionsReducer(infoSections, action),
  extensions: extensionsReducer(extensions, action),
  exceptions: exceptionsReducer(exceptions, action),
  alerts: alertsReducer(alerts, action),
  patches: patchesReducer(patches, action),
  outages: outagesReducer(outages, action),
  base: baseReducer(base, action),
  supportCases: supportCasesReducer(supportCases, action),
  liveSearch: liveSearchReducer(liveSearch, action),
});
