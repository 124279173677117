import React from 'react';
import './assets/alertDetails.css';

const AlertDetails = ({alert}) => {
  return (alert && (<div className="spectrum-Table--quiet">
    <div className="spectrum-Table-body react-spectrum-TableView-body alertDetails" style={{borderBottom: "none", borderLeft: "none", borderRight: "none"}}>
      <div className="spectrum-Table-headCell">Incident Id:</div>
      <div>{alert.incident_id}</div>
      <div className="spectrum-Table-headCell">Alert Name:</div>
      <div>{alert.alert_description}</div>
      <div className="spectrum-Table-headCell">Opened:</div>
      <div>{new Date(alert.started_at).toUTCString()}</div>
      {alert.finished_at && <>
        <div className="spectrum-Table-headCell">Closed:</div>
        <div>{new Date(alert.finished_at).toUTCString()}</div>
      </>}
      <div className="spectrum-Table-headCell">Status:</div>
      <div>
        {alert.finished_at?'Closed':'Open'}
      </div>
      {alert.incident_url && <>
      <div className="spectrum-Table-headCell">Incident URL:</div>
      <div>
        <a href={alert.incident_url.replaceAll('"','')} target="_blank">{alert.incident_url.replaceAll('"','')}</a>
      </div>
      </>}
      {alert.runbook_url && <>
      <div className="spectrum-Table-headCell">KB Article:</div>
      <div>
        <a href={alert.runbook_url.replaceAll('"','')} target="_blank">{alert.runbook_url.replaceAll('"','')}</a>
      </div>
      </>}
    </div>
  </div>));
};

export default AlertDetails;
