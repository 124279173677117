import { useState } from 'react';
import { useStateValue } from 'state/index';
import { loadOutagesTab } from '../queries';
import { setOutageIssues, setOutageIncidents, setInformationLinks } from '../actions';
import { onException } from "../../httpStatusHandler";

export const useOutagesTab = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const request = async () => {
    setIsLoading(true);

    await loadOutagesTab()
      .then(response => {
        setIsLoading(false);
        if (response) {
          dispatch(setOutageIncidents(response.data['outage_incidents']));
          dispatch(setOutageIssues(response.data['outage_issues']));
          dispatch(setInformationLinks(response.data['information_links']));
        }
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
  };

  return [state.outages.outageIncidents, state.outages.outageIssues, state.outages.informationLinks, isLoading, request];
};

export default useOutagesTab;
