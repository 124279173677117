import React from 'react';
import Button from '@react/react-spectrum/Button';
import { success, error, info } from '@react/react-spectrum/Toast';
import useRecommendationDismiss from "state/recommendations/hooks/useRecommendationDismiss";

const DismissButton = ({ recommendation }) => {
  if (!recommendation.recommendation_is_dismissible) return null;

  const [dismissed, loading, setDismissed] = useRecommendationDismiss(recommendation);

  const dismissHandler = () => {
    if (dismissed) {
      // Restore
      const restore = confirm("Do you wish to restore this recommendation to its un-dismissed state?");
      if (!restore) return;
      setDismissed(false)
        .then(() => success('The recommendation has been restored. Its status will be updated in the next scan.'))
        .catch(e => postCatch(e, 'restoring'));
    } else {
      // Dismiss
      const reason = prompt("You can dismiss the recommendation if you believe it is not relevant to your website.\nProvide a reason to dismiss this recommendation or leave empty to cancel. Max 500 characters.");
      if (!reason) {
        info("No reason was provided, so the recommendation was not dismissed.");
        return;
      }
      setDismissed(true, reason)
        .then(() => success('The recommendation has been dismissed.'))
        .catch(e => postCatch(e, 'dismissing'));
    }
  }

  return (
    <Button onClick={dismissHandler} variant="action" style={{ margin: '-1em 0', bottom: '.5em' }}>{
      dismissed ? 'Restore' : 'Dismiss'
    }</Button>
  );
};

/**
 * Handle errors from dismissing or restoring a recommendation.
 * @param e - The Axios request error object itself after a failed call to postData. Should contain a response object with the error message.
 * @param {string} verbGerund - The verb in gerund form, e.g. 'dismissing' or 'restoring'. Used for the error message.
 */
function postCatch(e, verbGerund) {
  if (e.response.data.error) {
    error('Error while ' + verbGerund + ' this recommendation: ' + e.response.data.error);
  } else {
    error('An unexpected error occurred while ' + verbGerund + ' this recommendation.');
  }
}


export default DismissButton;
