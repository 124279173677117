import { useState } from 'react';
import { postData } from 'state/dataProvider';
import { getConfig } from 'config';
import {useStateValue} from "../../index";
import {setRecommendationUpdate} from "../actions";
import {onException} from "../../httpStatusHandler";

/**
 * @typedef {(
 *   dismissed: boolean,
 *   reason?: string
 * ) => Promise<unknown>} setRecommendationDismiss
 */

/**
 * @param {Object.<string, unknown>} recommendation
 * @returns {[boolean, boolean, setRecommendationDismiss]}
 */
export const useRecommendationDismiss = (recommendation) => {
  const url = getConfig('recommendationDismissUrl');

  const [state, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);

  /** @type {setRecommendationDismiss} */
  const setRecommendationDismiss = (dismiss, reason) => {
    setLoading(true);
    const action = dismiss ? 'dismiss' : 'restore';
    const declaration_id = recommendation.declaration_id;
    const data = { action, declaration_id };
    if (reason !== undefined) data.reason = reason;
    return postData(url, data, { responseType: 'json' })
      .then(data => {
        const { declaration_id, reason, dismissed_user, dismissed_time } = data.data;
        dispatch(setRecommendationUpdate(action, declaration_id, reason, dismissed_user, dismissed_time));
        setLoading(false);
      })
      .catch(errorObject => {
        onException(errorObject, dispatch, state)
        return Promise.reject(errorObject)
      });
  }

  return [recommendation.recommendation_status === 2, loading, setRecommendationDismiss];
};
export default useRecommendationDismiss;
