import React from 'react';

function InfoMessage({message}) {
  return (
    <div
      className="alerts-callout alerts-callout-info"
      style={{ margin: "0 0 20px 0" }}
    >
      {message}
    </div>
  )
}

export default InfoMessage;





