import React from 'react';

function WarningMessage({message}) {
  return (
    <div
      className="patches-callout patches-callout-warning"
      style={{ margin: "0 0 20px 0" }}
    >
      {message}
    </div>
  )
}

export default WarningMessage;

