import {getConfig} from 'config';
import {putData} from '../dataProvider';

export const addUrl = () => {
  return putData(
    getConfig('securityScanAddUrl'),
    {},
    {}
  );
};

export const viewReport = () => {
  return putData(
    getConfig('securityScanReportUrl'),
    {},
    {}
  );
};
