import React from 'react';
import CheckSection from "./CheckSection";
import {
  Heading,
  TableView,
  TableBody,
  Column,
  TableHeader,
  Cell,
  Row,
} from '@adobe/react-spectrum';

class Database extends CheckSection {

  getIntInfoValue(code="mariadb-row-format-compact") {
    let value = this.getInfoValue('mariadb-row-format-compact');
    if (!value) {
      return 'N/A';
    }
    // Check if the value is an empty array
    if (Array.isArray(value) && value.length === 0) {
      return 0;
    }
    return value;
  }

  getRightSection() {
    let tableSizeTable = '';

    if (!this.isRecommendationApplied('mysqlTableSize') ) {
      tableSizeTable = <div>
        <Heading level={2} marginTop={30}>Large Tables Found</Heading>
        <TableView>
          <TableHeader>
            <Column>Table</Column>
            <Column>Size (MB)</Column>
          </TableHeader>
          <TableBody>
            {this.getRecommendationsDataArray('mysqlTableSize', 'big_mysql_tables').map(( listValue ) => {
              return (
                <Row>
                  <Cell>{listValue['table']}</Cell>
                  <Cell>{listValue['size']}</Cell>
                </Row>
              );
            })}
          </TableBody>
        </TableView>
      </div>
    }


    return (
      <div>
        <TableView>
          <TableHeader>
            <Column maxWidth={80}>Status</Column>
            <Column>Supporting Information</Column>
          </TableHeader>
          <TableBody>
            <Row>
              <Cell maxWidth={80}>{this.isRecommendationAppliedStatus('mysqlSlaveConnection')}</Cell>
              <Cell>MySQL Slave Connection</Cell>
            </Row>
            <Row>
              <Cell maxWidth={80}>{this.isRecommendationAppliedStatus('mysqlTableSize')}</Cell>
              <Cell>Database table size</Cell>
            </Row>
            <Row>
              <Cell maxWidth={80}>{this.isRecommendationAppliedStatus('mysqlMyISAM')}</Cell>
              <Cell>Check if there are MyISAM tables, convert them to InnoDB engine</Cell>
            </Row>
            <Row>
              <Cell maxWidth={80}>{this.getCheckIcon(!this.getIntInfoValue('mariadb-row-format-compact'))}</Cell>
              <Cell>Tables found with 'COMPACT' row format: {this.getIntInfoValue('mariadb-row-format-compact') || 'No data available'} </Cell>
            </Row>
          </TableBody>
        </TableView>

        {tableSizeTable}
      </div>
    );
  }
}

export default Database;
