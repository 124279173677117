import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Heading,
  Flex,
  View,
  Checkbox,
} from '@adobe/react-spectrum';
import Filter from '@spectrum-icons/workflow/Filter';
import { alertsDataSource } from "./alertsGrid";

const CheckboxHeading = styled.h6`
  font-family: 'adobe-clean-bold', Times, serif;
  font-size: 14px;
  color: #2c2c2c;
  letter-spacing: 0;
  margin-bottom: 10px;
`;
const TopLine = styled.hr`
  border: 1px solid #eaeaea;
  margin: 12px 0 16px 0;
  grid-area: divider;
`;

const FilterAlertsButton = props => {
  const [selectedCheckboxes, setCheckboxes] = useState(new Set());

  const toggleCheckbox = id => {
    const set = new Set(selectedCheckboxes);
    if (selectedCheckboxes.has(id)) {
      set.delete(id);
      setCheckboxes(set);
    } else {
      set.add(id);
      setCheckboxes(set);
    }
  };
  const isSelectedCheckbox = id => {
    let result = false;
    if (selectedCheckboxes.has(id)) {
      result = true;
    }
    return result;
  };

  return (
    <DialogTrigger>
      <Flex direction="row" alignContent="center" alignItems="center">
        <View>
          <Button height="40px" isQuiet UNSAFE_className="filterGridButton">
            <Filter />
          </Button>
        </View>
        <View>
          <span class="filterCounter">{props.alertCount} records out of {props.totalAlertCount}</span>
        </View>
      </Flex>
      {close => (
        <Dialog>
          <Heading>Filter Alerts</Heading>
          <TopLine />
          <Content>
            {<>
                <Flex direction="row">
                  <View>
                    <CheckboxHeading level="6">Incident Status</CheckboxHeading>
                    <Flex direction="column">
                      <Checkbox
                        onChange={() => toggleCheckbox('status|open')}
                        isSelected={isSelectedCheckbox('status|open')}
                      >
                        Open
                      </Checkbox>
                      <Checkbox
                        onChange={() => toggleCheckbox('status|closed')}
                        isSelected={isSelectedCheckbox('status|closed')}
                      >
                        Closed
                      </Checkbox>
                    </Flex>
                  </View>
                </Flex>
              </>
            }
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>
              Cancel
            </Button>
            <Button
              variant="cta"
              onPress={() => {
                alertsDataSource.filterAlerts([...selectedCheckboxes]);
                close();
              }}
            >
              Apply
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
export default FilterAlertsButton;
