import React, {useEffect, useState} from 'react';
import PreLaunchChecks from './components/preLaunchChecks';
import InternalOnlyMessage from "../sharedComponents/internalOnlyMessage";

const preLaunchChecksTab = () => {
  const [filteredRecommendations, setFilteredRecommendations] = useState(false);

  return (
    <div className={'prelaunchChecksTab'}>
      <InternalOnlyMessage />
      <PreLaunchChecks />
    </div>
  );
}

export default preLaunchChecksTab;
