import React from 'react';
import Widget from "./widget";
import { VegaDonut } from '@quarry/react-vega';
import styled from 'styled-components';
import useDashboard from "state/dashboard/hooks/useDashboard";
import {Link, Flex} from '@adobe/react-spectrum';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';

const VegaGaugeDiv = styled.div`
  height: 100% !important;
  min-width: 100% !important;
  position: 'relative';
`
const Description = styled.div`
  color: #6E6E6E;
  text-transform: capitalize;
`;


const RecommendationWidget = (layoutItem) => {
  const [dashboard,,] = useDashboard();
  if (dashboard.counts.recommendations !== 0) {
    return <Widget
      layoutItem={layoutItem}
      title="Recommendations"
      headerContent={
        <Link variant={'primary'} isQuiet>
          <a href="/tab/recommendations" target="_self" data-ui-id="recommendation-widget-view-all">
            View All
          </a>
        </Link>
      }
    >
      <VegaGaugeDiv>
        <VegaDonut
          data={{
            types: {issues: 'string', value: 'string'},
            data: [
              {issues: 'Severe', value: dashboard.widget.recommendations.P0},
              {issues: 'High', value: dashboard.widget.recommendations.P1},
              {issues: 'Elevated', value: dashboard.widget.recommendations.P2},
              {issues: 'Medium', value: dashboard.widget.recommendations.P3},
              {issues: 'Low', value: dashboard.widget.recommendations.P4},
            ],
          }}
          height={'auto'}
          width={'auto'}
          color={"issues"}
          sort={false}
          angle={"value"}
          angleTitle={"Recommendations"}
          titleNumberFormat=".0s"
          tooltipNumberFormat=",.0s"
          spec={{
            scales: {
              name: "color",
              type: "ordinal",
              domain: {
                data: "table",
                field: "issues"
              },
              range: ["#C9252D", "#D43082", "#E68623", "#5151D3", "#26C0C7"]
            },
            marks: [
              {
                type: "arc",
                from: {
                  data: "table"
                },
                encode: {
                  enter: {
                    fill: {
                      scale: "color",
                      field: "issues"
                    },
                    x: {
                      signal: "width / 2"
                    },
                    y: {
                      signal: "height / 2"
                    },
                    tooltip: {
                      signal: "{title: datum.issues, 'Recommendations': format(datum.value, ',.0f')}"
                    }
                  },
                  update: {
                    startAngle: {
                      field: "startAngle"
                    },
                    endAngle: {
                      field: "endAngle"
                    },
                    padAngle: {
                      value: 0.015
                    },
                    innerRadius: {
                      signal: "if(width >= height, height, width) / 2 * 0.85"
                    },
                    outerRadius: {
                      signal: "if(width >= height, height, width) / 2"
                    },
                    fillOpacity: {
                      signal: "hovered === null ? 1 : hovered === datum.issues ? 1 : 0.25"
                    }
                  }
                }
              },
              {
                type: "group",
                marks: [
                  {
                    type: "text",
                    interactive: false,
                    from: {
                      data: "aggregate"
                    },
                    encode: {
                      enter: {
                        x: {
                          signal: "width / 2"
                        },
                        y: {
                          signal: "height / 2 + if(width >= height, height, width) / 30",
                          round: true
                        },
                        dy: {
                          signal: "if(width >= height, height, width) / 75"
                        },
                        text: {
                          field: "sum"
                        },
                        fontSize: {
                          signal: "if(width >= height, height, width) / 4",
                          round: true
                        },
                        fontWeight: {
                          value: 300
                        },
                        align: {
                          value: "center"
                        },
                        baseline: {
                          value: "bottom"
                        }
                      }
                    }
                  },
                  {
                    type: "text",
                    interactive: false,
                    encode: {
                      enter: {
                        x: {
                          signal: "width / 2"
                        },
                        y: {
                          signal: "height / 2 + 10"
                        },
                        text: {
                          value: "Recommendations"
                        },
                        fontSize: {
                          signal: "if(width >= height, height, width) / 13",
                          round: true
                        },
                        fontWeight: {
                          value: "bold"
                        },
                        align: {
                          value: "center"
                        },
                        baseline: {
                          value: "top"
                        }
                      }
                    }
                  }
                ]
              }
            ],
            legends: [
              {
                fill: "color",
                gridAlign: "all",
                labelLimit: {
                  signal: "width * 0.4"
                },
                orient: "left",
                direction: "vertical",
                columns: 1,
                title: "Prioritized Recommendations",
                offset: -20,
                symbolSize: "250"
              }
            ],
            signals: [
              {
                name: "hovered",
                value: null,
                on: [
                  {
                    events: ":mouseover",
                    update: "datum.issues"
                  },
                  {
                    events: ":mouseout",
                    update: "null"
                  }
                ]
              },
              {
                name: "click",
                value: null,
                on: [
                  {
                    events: [
                      {
                        type: "click",
                        consume: true,
                        source: "view"
                      }
                    ],
                    update: "datum.issues"
                  }
                ]
              }
            ]
          }}
        />
      </VegaGaugeDiv>
    </Widget>
  }
  //Return No Recommendations state if there is no recommendations in DB.
  return <Widget layoutItem={layoutItem} title="Recommendations">
    <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height="100%" width={'100%'}>
      <CheckmarkCircle color={'positive'} size={'XL'} marginBottom={'10px'} />
      <Description>No Recommendations</Description>
    </Flex>
  </Widget>
};

export default RecommendationWidget;
