import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const Styles = createGlobalStyle`
    ${reset}
    @font-face {
        font-family: adobe-clean;
        src: url(/fonts/AdobeClean-Regular_0.otf);
        font-weight:normal;
        font-style:normal;
    }
    @font-face {
        font-family: adobe-clean-bold;
        src: url(/fonts/AdobeClean-Bold.otf);
        font-weight: bold;
    }
    @font-face {
        font-family: adobe-clean-italic;
        src: url(/fonts/AdobeClean-It_0.otf);
        font-style:italic;
    }
    @font-face {
        font-family: adobe-clean-bold-italic;
        src: url(/fonts/AdobeClean-BoldIt.otf);
        font-style:italic;
        font-weight: bold;
    }
    @font-face {
        font-family: adobe-clean-light;
        src: url(/fonts/AdobeClean-Light_0.otf);
        font-weight: lighter;
    }

    html, body, #root, #root > div {
      height: 100%;
      width: 100%;
      font-size: 14px;
    }

    .react-grid-layout{
      margin: 0 -2.4rem;
    }

    #root > div,
    #root > div > div {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 17px 0px;
    }
    main {
      flex-grow: 1;
    }
    header, main, footer {
        flex-shrink: 0;
    }
    button[type="button"] {
      border: 1px solid #EAEAEA;
      border-radius: 5px;
      cursor: pointer;
    }
    button[type="button"].unvisibleButton, a.unvisibleButton {
      border: 0px;
      padding: 0;
      min-width: 10px;
    }
    body {
      margin: 0
      min-width: 1582px;
      overflow-x: scroll;
    }
    * {
      box-sizing: border-box;
      font-family: 'adobe-clean' !important;
    }
    .spectrum-Tabs-selectionIndicator
    {
      border-color: #323232 !important;
      border-bottom: 2px solid;
      border-radius: 1px !important;
    }
    .spectrum-Tabs--horizontal {
        border-color: #EAEAEA !important;
        border-radius: 1px !important;
    }
    .spectrum-Tabs-item {
      color: #747474;

    }
    .spectrum-Tabs-item.is-selected {
      color: #323232;
    }
    h3 {
      font-family: 'adobe-clean', Times, serif;
      font-size: 14px;
      color: #505050;
      text-transform: uppercase;
      letter-spacing: 0.72px;
    }
    h5 {
      font-family: 'adobe-clean', Times, serif;
      font-size: 14px;
      color: #2C2C2C;
      letter-spacing: 0.72px;
    }
    hr {
      border: 1px solid #eaeaea;
    }
    .spectrum-Tabs--vertical {
      border-left: 0;
    }
    .spectrum-Tabs--vertical .spectrum-Tabs-item {
      border-radius: 5px;
      padding: 15px;
      margin: 0;
      font-size: 14px;
      align-items: center;
      display: flex;
    }
    .spectrum-Tabs--vertical .is-selected {
      background-color: #f3f3f3;
    }
    .spectrum--lightest .spectrum-Accordion-itemHeader,
    .react-spectrum-Accordion-item
    {
      border: 0 none !important;
    }
    .collapsible {
      display: flex;
      width: 100%;
    }
    .spectrum-Accordion-itemHeading {
      border-bottom: 2px solid #eaeaea;
    }
    button.spectrum-Accordion-itemHeader
    {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 27px;
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
      letter-spacing: 0.72px;
    }
    svg.spectrum-Accordion-itemIndicator {
      top: 8.5px;
      left: 2px;
      color: #B3B3B3;
    }
    .spectrum-Tabs--vertical .spectrum-Tabs-selectionIndicator {
      width: 0;
    }
    .spectrum-Tabs--horizontal {
      padding-left: 0;
    }
    .react-spectrum-TabView .react-spectrum-TabView-body {
      padding-left: 0;
      padding-right: 0;
    }
    .spectrum-Accordion-item.is-open > .spectrum-Accordion-itemContent {
      padding: 10px 0;
    }
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: #2680EB;
    }
    .spectrum-Tabs-itemLabel {
        font-size: 14px !important;
    }
    .spectrum-Subheading, .spectrum-Heading--subtitle3 {
        font-size: 11px;
        font-weight: 700;
        line-height: 1.3;
        font-style: normal;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        margin-bottom: 8px;
        margin-top: 8px;
    }
    .spectrum-Heading2--quiet, .spectrum-Heading--pageTitle {
        font-size: 31px;
        font-weight: 300;
        line-height: 1.3;
        font-style: normal;
        letter-spacing: 0;
        text-transform: none;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .mainTabNav .spectrum-Tabs-itemLabel > span
    {
      display: flex;
      align-items: center;
    }
    .headWithHelp {
      display: flex;
      align-items: center;
    }
    .infoTab-grid {
      display: flex;
      justify-content: space-between;
      width: auto;
      gap: 20px;
    }
    .infoTab-grid_column > div {
      margin-bottom: 20px;
    }
    .infoTab .react-spectrum-TabView-body {
      padding-right: 0 !important;
      min-height:700px !important;
    }
    button.filterGridButton {
      min-width: 10px !important;
      background-color: transparent !important;
      border: 0px;
      margin-right: 10px;
    }
    .filterCounter {
      color: #747474;
      font-family: 'adobe-clean-italic' !important;
      font-size: 12px;
    }
    #root .react-spectrum-provider,
    #root .react-spectrum-provider > div:first-child {
      margin: 0 !important;
      height: 100%;
    }

    header .unvisibleButton {
      margin-left: 20px;
      background-color: transparent !important;
    }
    header > div {
      padding: 0 40px;
    }
    header .header-row {
      background-color: #080808;
      margin-bottom: 17px !important;
      margin-left: 0px !important;
    }
    .header-row span {
      color: #E2E2E2 !important;
      font-size: 14px !important;
    }
    .adobeLogo {
      // background-color: white;
    }
    header .second-header-row {
      margin-top: 17px;
    }
    .uctReportReady .widgetHeader button {
      border: none;
    }
    .dashboardWidget .widgetSubmitButton {
      font: normal normal bold 14px/17px Adobe Clean;
      border: 2px solid #1473E6;
      border-radius: 16px;

      background-color: #1473E6 !important;
      color: #FFFFFF !important;
      text-transform: capitalize;
    }
    .dashboardWidget .widgetSubmitButton:hover {
      font: normal normal bold 14px/17px Adobe Clean;
      border: 2px solid #1473E6;
      border-radius: 16px;

      background-color: #0d66d0 !important;
      color: #FFFFFF !important;
      text-transform: capitalize;
    }
    .dashboardWidget .widgetHeader {
      padding: 16px 20px;
    }
    .dashboardActions {
      padding: 0px;
    }
    .dashboardWidget-loading-text {
      color: #747474;
      font: normal normal normal 14px/17px Adobe Clean;
    }
    button.spectrum-Accordion-itemHeader {
      background-color: #F5F5F5 !important;
      border: 0px;
    }
    .spectrum-Table-row{
      border-bottom: 1px solid #e1e1e1 !important;
    }
    .spectrum-Tabs--vertical .is-selected {
      background-color: #E9F2FD
    }
  .spectrum-Table-head * {
     font-size: 14px;
      font-weight: 300;
  }

   div[class^="DashboardWidget-module_widgetContent_"] div[class^="_spectrum-Table-cell_"] {
      background-color: white;
    }
   div[class^="DashboardWidget-module_widgetContent_"] div[class^="_spectrum-Table-cellWrapper"] * {
      background-color: white;
    }
   .staticFields svg {
     width: 25px !important;
     top: calc(var(--spectrum-textfield-icon-frame) /2 - var(--spectrum-global-dimension-size-225) /2) !important;
   }
   .clientPicker input {
     font-size: 18px;
     color: #2c2c2c;
     font-family: adobe-clean-bold, Times, serif !important;
   }
    .clientEnvironmentPicker button {
      border-top: none;
      border-right: none;
      border-left: none;
      border-image: initial;
      border-bottom: 1px solid #CACACA;
      border-radius: 0;
    }
    #issueDescription {
      height: 180px;
    }
`;

export default Styles;
