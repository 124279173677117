import React, { useEffect } from 'react';
import { useAppContext } from './AppContext';

import {
  Meter,
  StatusLight,
} from '@adobe/react-spectrum';

const CheckScore = props => {
  const [totalScore, setTotalScore] = React.useState(0);
  const [scoreColor, setScoreColor] = React.useState('critical');
  const [blockerCount, setBlockerCount] = React.useState(0);
  const [blockerColor, setBlockerColor] = React.useState('negative');
  const { globalState } = useAppContext();

  let totalFinalScore = globalState.totalIdealScore;


  const percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  }

  const calculateColor = (percentage) => {
    let color = 'critical';
    let blk = globalState.blockers;

    // ** Blocker count
    if (percentage < 70) {
      color = 'critical';
    } else if (percentage < 80) {
      color = 'warning';
    } else {
      color = 'positive'; // GREEN
    }

    if (blk > 0) {
      color = 'critical';
    }
    setScoreColor(color);
  }

  useEffect(() => {
    let score = globalState.score;
    if (typeof score === 'undefined') {
      score = 0;
    }

    let perc = percentage(score, totalFinalScore);
    setTotalScore(perc);
    calculateColor(perc);
  }, [globalState.score]);

  useEffect(() => {
    let blk = globalState.blockers;
    setBlockerCount(blk);
    let blkColor =  (blk === 0) ? 'positive' : 'negative';
    setBlockerColor(blkColor);

  }, [globalState.blockers]);


  return (
    <div className="meter" id={props.id} >
      <Meter label="Cloud Readiness Score" variant={scoreColor} value={totalScore} />
      <div className="status-light">
        <StatusLight variant={blockerColor}>Potential Blockers: {blockerCount}</StatusLight>
      </div>
    </div>
  )
}

export default CheckScore;
