import {useStateValue} from 'state/index';
import {onException} from "state/httpStatusHandler";
import {success} from "@react/react-spectrum/Toast";
import {runUctScan} from "../queries";

const useUctScan = () => {
  const [state, dispatch] = useStateValue();
  return async (callback) => {
    await runUctScan()
      .then(() => {
        success("Scan in progress. May take 60 minutes or more to complete.", {timeout: 15000});
        callback();
      })
      .catch(errorObject => onException(errorObject, dispatch, state));
  };
}

export default useUctScan;
