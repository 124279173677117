import {SET_PRELAUNCH_CHECKS} from './actions';

export const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRELAUNCH_CHECKS:
      return action.payload;
    default:
      return state;
  }
};
