import React from "react";
import { VegaDonut } from "@quarry/react-vega";

const spec = {
  scales: {
    name: "color",
    type: "ordinal",
    domain: {
      data: "table",
      field: "issues",
    },
    range: ["#C9252D", "#E68623", "#26C0C7", "#E1E1E1"],
  },
  marks: [
    {
      type: "arc",
      from: {
        data: "table",
      },
      encode: {
        enter: {
          fill: {
            scale: "color",
            field: "issues",
          },
          x: {
            signal: "width / 2",
          },
          y: {
            signal: "height / 2",
          },
          tooltip: {
            signal:
              "{title: datum.issues, 'Recommendations': format(datum.value, ',.0f')}",
          },
        },
        update: {
          startAngle: {
            field: "startAngle",
          },
          endAngle: {
            field: "endAngle",
          },
          padAngle: {
            value: 0.015,
          },
          innerRadius: {
            signal: "if(width >= height, height, width) / 2 * 0.85",
          },
          outerRadius: {
            signal: "if(width >= height, height, width) / 2",
          },
          fillOpacity: {
            signal:
              "hovered === null ? 1 : hovered === datum.issues ? 1 : 0.25",
          },
        },
      },
    },
    {
      type: "group",
      marks: [
        {
          type: "text",
          interactive: false,
          from: {
            data: "aggregate",
          },
          encode: {
            enter: {
              x: {
                signal: "width / 2",
              },
              y: {
                signal: "height / 2 + if(width >= height, height, width) / 30",
                round: true,
              },
              dy: {
                signal: "if(width >= height, height, width) / 75",
              },
              text: {
                field: "sum",
              },
              fontSize: {
                signal: "if(width >= height, height, width) / 4",
                round: true,
              },
              fontWeight: {
                value: 300,
              },
              align: {
                value: "center",
              },
              baseline: {
                value: "bottom",
              },
            },
          },
        },
        {
          type: "text",
          interactive: false,
          encode: {
            enter: {
              x: {
                signal: "width / 2",
              },
              y: {
                signal: "height / 2 + 10",
              },
              text: {
                value: "Recommendations",
              },
              fontSize: {
                signal: "if(width >= height, height, width) / 13",
                round: true,
              },
              fontWeight: {
                value: "bold",
              },
              align: {
                value: "center",
              },
              baseline: {
                value: "top",
              },
            },
          },
        },
      ],
    },
  ],
  signals: [
    {
      name: "hovered",
      value: null,
      on: [
        {
          events: ":mouseover",
          update: "datum.issues",
        },
        {
          events: ":mouseout",
          update: "null",
        },
      ],
    },
    {
      name: "click",
      value: null,
      on: [
        {
          events: [
            {
              type: "click",
              consume: true,
              source: "view",
            },
          ],
          update: "datum.issues",
        },
      ],
    },
  ],
};

function VegaDonutCustomSpec({ data }) {
  const totalRecommendations = data.reduce((acc, item) => acc + item.value, 0);

  if (totalRecommendations > 0) {
    spec.legends = [
      {
        fill: "color",
        gridAlign: "all",
        labelLimit: {
          signal: "width * 0.4",
        },
        orient: "left",
        direction: "vertical",
        columns: 1,
        title: "Priority",
        offset: 0,
        symbolSize: "250",
      },
    ];
  }

  return (
    <VegaDonut
      data={{ data: data }}
      height={"auto"}
      width={"auto"}
      color={"issues"}
      angle={"value"}
      angleTitle={"Issues"}
      sort={false}
      titleNumberFormat=".0s"
      tooltipNumberFormat=",.0s"
      spec={spec}
    />
  );
}

export default VegaDonutCustomSpec;
