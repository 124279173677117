import {getConfig} from 'config';
import {getData, postData} from '../dataProvider';

export const downloadUctReport = () => {
  return postData(
    getConfig('uctReportUrl'),
    {},
    {
      responseType: 'blob',
    }
  );
};
export const runUctScan = () => {
  return getData(
    getConfig('uctScanRunUrl'),
  );
};

