import React from 'react';
import PropTypes from "prop-types";
import ChecklistCard from "../ChecklistCard/ChecklistCard";
import {
  Heading,
  Divider
} from '@adobe/react-spectrum';

import Alert from '@spectrum-icons/workflow/Alert';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import Info from '@spectrum-icons/workflow/Info';

class CheckSection extends React.Component {

  /**
   * open a link in a new tab
   * @param url
   */
  openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  }

  getInfoValue (infoLabel) {
    if (typeof this.props.data.info !== 'undefined') {
      return this.props.data.info[infoLabel];
    } else {
      return [];
    }
  }

  getRecommendationsDataArray(issueLabel, data_label) {
    try {
      let data = this.props.data.recommendations[issueLabel]['issue_data'][data_label];
      let finalArr = [];
      if (typeof data !== 'undefined') {
        for (const [key, value] of Object.entries(data)) {
          finalArr.push(value);
        }
      }
      return finalArr;
    } catch (e) {
      return [];
    }
  }

  isRecommendationApplied(issueLabel) {
    try {
      // ** if any recommendations not found in the initial array, we assume it is configured.
      return !(issueLabel in this.props.data.recommendations);
    } catch (e) {
      return [];
    }
  }

  /**
   *
   * @param issueLabel
   * @returns {JSX.Element|*[]|boolean}
   */
  isRecommendationAppliedStatus(issueLabel) {
    try {
      return (
        this.isRecommendationApplied(issueLabel) ?
            <CheckmarkCircle aria-label="Positive Check" color="positive" />
            : <Alert aria-label="Negative Alert" color="negative" />
      );
      // ** if any recommendations not found in the initial array, we assume it is configured.
      return !(issueLabel in this.props.data.recommendations);
    } catch (e) {
      return [];
    }
  }

  /**
   *
   * @param value
   * @returns {JSX.Element}
   */
  getCheckIcon(value){
    return value ?  <CheckmarkCircle aria-label="Positive Check" color="positive" />
      : <Alert aria-label="Negative Alert" color="negative" />
  }

  getInfoIcon(){
    return <Info aria-label="Information" color="warning" />
  }

  getCheck () {
    return this.props.check;
  }
  getInfoArray(infoLabel) {
    let data = this.getInfoValue(infoLabel);
    let finalArr = [];
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        finalArr.push(value);
      }
    }
    return finalArr;
  }

  getRightSection () {
    return (
      <div></div>
    );
  }

  getLeftSection () {
    return (
      <div></div>
    );
  }

  render () {
    return (
      <div className="row">
        <div className='section-wrapper'>
          {this.getCheck().cardTitle && (
            <div className='section-title'>
              <Heading level={1} marginTop={30}>{this.props.cnt}. { this.getCheck().cardTitle}</Heading>
              <Divider size={'M'} marginBottom={'size-100'}/>
            </div>
          )}

          <div className='left-section'>
            <ChecklistCard
              cardStep={''}
              cardTitle={this.getCheck().cardTitle}
              cardDescription={this.getCheck().itemDescription}
              checkboxList={this.getCheck().checkboxList}
              sectionTitle={this.getCheck().cardTitle}
              cnt={this.props.cnt}
              code={this.getCheck().code}
              data={this.props.data}
            />
            {this.getLeftSection()}
          </div>
          <div className='right-section'>
            {this.getRightSection()}
          </div>
        </div>
      </div>
    )
  }
}


CheckSection.propTypes = {
  check: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  cnt: PropTypes.number
};


export default CheckSection;
