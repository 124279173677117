import React from 'react';
import {DialogTrigger, Link, Dialog, Heading, Divider, Content, ButtonGroup, Button, Text} from '@adobe/react-spectrum';
import {success} from '@react/react-spectrum/Toast';
import {error} from '@react/react-spectrum/Toast';
import {GetUiId} from "../../../base/components/jsUtilities";


const copyToClipboard = () => {
  const text = document.getElementById('snapshot-data').innerHTML;
  navigator.clipboard
    .writeText(text)
    .then(() => {
      success("Copied text to clipboard!", {
        timeout: 2000,
      });
    })
    .catch((err) => {
      error("There was an error copying the text, try again.", {
        timeout: 2000,
      });
    });
};

const SnapShotModal = ({heading, snapshotData}) => {
  if(!snapshotData){
    return (
      <DialogTrigger>
        <Link isQuiet={true}>{heading}</Link>
        {(close) => (
          <Dialog>
            <Heading>{heading}</Heading>
            <Divider />
            <Content>
              <Text>NO DATA FOUND</Text>
            </Content>
            <ButtonGroup>
              <Button variant={'cta'} type={'close'} onPress={close} data-ui-id="outage-info-no-data-close-button">Close</Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogTrigger>
    )
  }
  return (
    <DialogTrigger type={'fullscreen'} >
      <Link isQuiet={true} data-ui-id={GetUiId(heading) + "-dialog"}>{heading}</Link>
      {(close) => (
        <Dialog>
          <Heading>{heading}</Heading>
          <Divider />
          <Content>
            <pre id={'snapshot-data'}>
              {snapshotData}
            </pre>
          </Content>
          <ButtonGroup>
            <Button variant={'secondary'} type={'copy'} onPress={copyToClipboard} data-ui-id="outage-info-copy-to-clipboard-button">Copy To Clipboard</Button>
            <Button variant={'cta'} type={'close'} onPress={close} data-ui-id="outage-info-close-button">Close</Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  )
};

export default SnapShotModal;
