export const checks = {
  'schedule': {
    cardTitle: 'Pre-Go Live',
    code: 'schedule',
    checkboxList: [
      {
        score: 1,
        severity: 3,
        name: 'review_documentation',
        label: 'Review the documentation about testing and going live (Site launch documentation) and Commerce Cloud Pre-Launch Checklist',
        check_description: `<p>Ensure a comprehensive <i>"go live readiness plan"</i> is fully prepared with your Partner/SI, incorporating all necessary action items. Remember, while the pre-launch checklist emphasizes Adobe's best practices, it <em>does not</em> replace the need for your own go-live readiness plan.</p>`,
        links: [
          {
            alt: 'Site launch documentation',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/launch/overview'
          },
          {
            alt: 'Commerce Cloud Pre-Launch Checklist',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-learn/tutorials/adobe-commerce-cloud/commerce-cloud-best-practices/pre-launch-checklist'
          }
        ]
      },
      {
        score: 10,
        severity: 1,
        name: 'review_swat',
        label: 'Reviewed the Support Insights (SWAT) Recommendations and Information.',
        links: [
          {
            alt: 'Support Insights',
            href: 'https://experienceleague.adobe.com/docs/commerce-operations/tools/site-wide-analysis-tool/intro.html?lang=en'
          }
        ]
      },
      {
        score: 2,
        severity: 3,
        name: 'perform_uat',
        label: 'End user/merchant conducted UAT (User Acceptance Testing), including backend operations.'
      },
      {
        score: 2,
        severity: 3,
        name: 'si_test',
        label: 'System integrator team has performed end-to-end UAT on Staging and Production environments.',
        links: [
          {
            alt: 'end-to-end UAT',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/test/staging-and-production.html'
          }
        ]
      },
      {
        score: 6,
        severity: 3,
        name: 'deployed_to_prod',
        label: 'Confirm code deployment and testing in Staging and Production Environments',
        links: [
          {
            alt: 'Read More',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/test/staging-and-production.html'
          }
        ]
      },
      {
        score: 6,
        severity: 3,
        name: 'cluster_size',
        label: 'The production cluster has been upsized permanently to the contracted daily baseline.',
        links: []
      }
    ],
    itemDescription: `At least 4 weeks prior to launching the new Adobe Commerce Cloud site, please make sure to reach out to the CTA and CSM to notify them of your intention to launch.
              <ol>
                <li>Adobe, Developer/SI, Client to review the status of the following checklist.</li>
                <li>Launch date and time window to be confirmed.</li>
                <li>If launch date or time window changes, please notify the CTA and CSM.</li>
              </ol>`
  },
  'current': {
    cardTitle: 'Current Configurations',
    code: 'current',
    checkboxList: [
      {
        score: 6,
        severity: 3,
        name: 'service_version',
        label: 'Upgrade Adobe Commerce and related packages/services to the latest version',
        links: [
          {
            alt: 'Release Notes',
            href: 'https://experienceleague.adobe.com/docs/commerce-operations/release/notes/overview.html?lang=en'
          }
        ]
      },
      {
        score: 6,
        severity: 3,
        name: 'store_stats',
        label: 'Review the current configurations and services with your SI/Partner, and follow the best practices',
        links: [
          {
            alt: 'follow the best practices',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-operations/implementation-playbook/best-practices/planning/catalog-management'
          }
        ]
      },
      {
        score: 25,
        severity: 5,
        name: 'disk_storage',
        label: 'Review the MySQL/Shared-Files disk usage',
        links: [
          {
            alt: 'disk usage',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/storage/manage-disk-space.html?lang=en'
          }
        ]
      }
    ],
    itemDescription: ``
  },
  'fastly': {
    cardTitle: 'Fastly Configurations',
    code: 'fastly',
    checkboxList: [
      {
        score: 25,
        severity: 1,
        name: 'fastly_cache',
        label: 'Make sure that caching is working (Full-Page Cache or GraphQL Caching). Read the Fastly setup guide',
        links: [
          {
            alt: 'Full-Page Cache',
            href: 'https://developer.adobe.com/commerce/frontend-core/guide/caching/'
          },
          {
            alt: 'GraphQL Caching',
            href: 'https://developer.adobe.com/commerce/webapi/graphql/usage/caching/'
          },
          {
            alt: 'set up guide',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/cdn/fastly.html'
          }
        ]
      },
      {
        score: 5,
        severity: 3,
        name: 'graphql_method',
        label: 'Use HTTP GET method for GraphQL queries on PWA/Headless websites when applicable.',
        links: [
          {
            alt: 'HTTP GET method',
            href: 'https://developer.adobe.com/commerce/webapi/graphql/usage/caching/'
          }
        ]
      },
      {
        score: 15,
        severity: 3,
        name: 'fastly_io',
        label: 'Ensure that Fastly Image Optimization is enabled. (Configure Fastly Image Optimisation)',
        links: [
          {
            alt: 'Configure Fastly Image Optimisation',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/cdn/fastly-image-optimization.html'
          }
        ]
      },
      {
        score: 10,
        severity: 3,
        name: 'fastly_shield',
        label: 'Verify that the correct shield location is configured. (Configure cache, backends and origin shielding)',
        links: [
          {
            alt: 'Configure cache, backends and origin shielding',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/cdn/setup-fastly/fastly-custom-cache-configuration#configure-back-ends-and-origin-shielding'
          }
        ]
      },
      {
        score: 2,
        severity: 3,
        name: 'fastly_waf',
        label: 'Web Application Firewall (WAF) is working.',
        check_description: 'Read more about addressing blocked requests and understanding associated limitations',
        links: [
          {
            alt: 'Read more',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/cdn/fastly-waf-service.html?lang=en'
          }
        ]
      },
      {
        score: 10,
        severity: 3,
        name: 'fastly_ignore_params',
        label: 'Update the Fastly “Ignored URL Parameters” list in the admin panel to enhance cache performance.',
        check_description: `<p>In the Fastly configuration under <i>Admin > Stores > Configurations > System > Full Page Cache > Fastly Configuration > Advanced Configuration > Ignored URL Parameters (Global)</i>, you can find a comma-separated list of parameters that Fastly should disregard when searching for cached pages. Please make sure to re-upload the VCL after modifying this list.</p>`
      }
    ],
    itemDescription: `Fastly – ensure that the Fastly module is enabled and configured, and that pages and blocks are cached correctly:`
  },
  'dns': {
    cardTitle: 'DNS and SSL',
    code: 'dns',
    checkboxList: [
      {
        score: 25,
        severity: 1,
        name: 'configured_domains',
        label: 'Confirm all required domain names are requested. <b>(Submit a support ticket in-advance for any added or changed domains)</b>'
      },
      {
        score: 25,
        severity: 1,
        name: 'ssl_cert',
        label: `Review SSL (TLS) certificates applied to the domain(s).`,
        check_description: `<p>For SSL usage, Adobe needs to verify the ownership of each domain within the Cloud environments. To achieve this for pre-production URLs like <i>mcstaging.domain.com</i> and <i>mcprod.domain.com</i>, you can simply add a CNAME record that directs to the Fastly URL, which is <i>prod.magentocloud.map.fastly.net</i>. The validation process for these domains is automated and occurs twice daily.</p>`,
        links: [
          {
            alt: `Review SSL (TLS) certificates`,
            href: 'https://experienceleague.adobe.com/en/docs/commerce-knowledge-base/kb/how-to/ssl-tls-certificates-for-magento-commerce-cloud-faq'
          }
        ]
      },
      {
        score: 10,
        severity: 3,
        name: 'ttl',
        label: 'Update DNS TTL (Time to Live) value to the minimum possible, for the go live.',
        links: [
          {
            alt: `TTL`,
            href: 'https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/launch/checklist#to-update-dns-configuration-for-site-launch'
          }
        ]
      },
      {
        score: 20,
        severity: 3,
        name: 'sendgrid',
        label: 'Enable Sendgrid SPF and DKIM.',
        links: [
          {
            alt: 'SendGrid service for transactional emails',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/project/sendgrid.html?lang=en'
          }
        ],
        check_description: 'Add the SendGrid CNAME records for each domain to the DNS configuration. Read SendGrid service for transactional emails to see how to change the sender domains and more.'
      }
    ],
    itemDescription: ``
  },
  'database': {
    code: 'database',
    cardTitle: 'Database Configurations',
    checkboxList: [
      {
        score: 16,
        severity: 5,
        name: 'mysql_slave',
        label: 'MYSQL Slave connection is recommended for improved performance during high database loads.',
        links: [
          {
            alt: 'MYSQL Slave connection',
            href: 'https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/mysql-configuration.html?lang=en#slave-connections'
          }
        ]
      },
      {
        score: 16,
        severity: 5,
        name: 'mysql_row_format',
        label: `Convert the row format from COMPACT to DYNAMIC (Especially for on-prem to cloud migrations).`,
        links: [
          {
            alt: 'COMPACT to DYNAMIC',
            href: 'https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/maintenance/commerce-235-upgrade-prerequisites-mariadb.html?lang=en'
          }
        ],
      },
      {
        score: 12,
        severity: 5,
        name: 'mysql_engine',
        label: `Change the database storage engine from MyISAM to InnoDB.`,
        links: [
          {
            alt: 'MyISAM to InnoDB',
            href: 'https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/database-on-cloud.html?lang=en#convert-all-myisam-tables-to-innodb'
          }
        ],
      },
      {
        score: 4,
        severity: 3,
        name: 'large_tables',
        label: `Review and optimize database tables exceeding 1 GB in size well-in-advance.`
      },
      {
        score: 4,
        severity: 3,
        name: 'mysql_cardinality',
        label: `The database schema information is current and up to date. Read more.`,
        links: [
          {
            alt: 'Read more',
            href: 'https://mariadb.com/kb/en/engine-independent-table-statistics/#collecting-statistics-with-the-analyze-table-statement'
          }
        ],
      }
    ],
    itemDescription: `Adobe Commerce Cloud employs a MariaDB Galera cluster as the database for both the Staging and Production environments. Galera clusters are instrumental in enhancing performance and scalability. To gain insights into the optimal practices and constraints of Galera cluster replications, we recommend referring to the following articles.
              <ol>
                <li><a href="https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/mysql-configuration.html?lang=en" target="_blank">MySQL Configurations best practices</a></li>
                <li><a href="https://experienceleague.adobe.com/docs/commerce-knowledge-base/kb/support-tools/managed-alerts/managed-alerts-on-magento-commerce-mariadb-alerts.html?lang=en" target="_blank">Managed Alerts on Adobe Commerce: MariaDB alerts</a></li>
                <li><a href="https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/database-on-cloud.html?lang=en" target="_blank">Best practices for database configuration</a></li>
                <li>Deep analysis to &nbsp;<a href="https://experienceleague.adobe.com/docs/commerce-learn/tutorials/backend-development/galera-db-slow-replication.html?lang=en" target="_blank">Deep analysis to Galera cluster replications and flow-control</a></li>
              </ol>`
  },
  'deployments': {
    code: 'deployments',
    cardTitle: 'Deployments',
    checkboxList: [
      {
        score: 4,
        severity: 3,
        name: 'js_css_minify',
        label: 'Review minification settings for HTML, Javascript, and CSS. (This does not apply to PWA/Headless websites)'
      },
      {
        score: 4,
        severity: 3,
        name: 'scd_state',
        label: `Review the Static Content Deployment (SCD) ideal state to reduce maintenance time during deployments on Production.`,
        links: [
          {
            alt: 'Static Content Deployment (SCD) ideal state',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/develop/deploy/static-content'
          }
        ],
      },
      {
        score: 4,
        severity: 3,
        name: 'scd_vars',
        label: `Confirm that the utilisation of the following cloud variables aligns with their intended purposes.`,
        links: [
          {
            alt: 'MyISAM to InnoDB',
            href: 'https://experienceleague.adobe.com/docs/commerce-operations/implementation-playbook/best-practices/planning/database-on-cloud.html?lang=en#convert-all-myisam-tables-to-innodb'
          },
          {
            alt: 'SCD_MATRIX',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/env/stage/variables-build.html?lang=en#scd_matrix'
          },
          {
            alt: 'SCD_ON_DEMAND',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/env/stage/variables-global.html?lang=en#scd_on_demand'
          },
          {
            alt: 'SKIP_SCD',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/configure/env/stage/variables-deploy.html?lang=en#skip_scd'
          }
        ],
        check_description: `<ul>
<li>SCD_MATRIX</li>
<li>SCD_ON_DEMAND</li>
<li>SKIP_SCD</li>
</ul>`
      }
    ],
    itemDescription: ``
  },
  'testing': {
    code: 'testing',
    cardTitle: 'Testing and Troubleshooting',
    checkboxList: [
      {
        score: 25,
        severity: 5,
        name: 'test_emails',
        label: 'Test Outgoing Emails. Read more about Adobe Commerce Cloud - SendGrid Mail functionality.',
        links: [
          {
            alt: 'SendGrid Mail functionality',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/project/sendgrid.html'
          }
        ],
      },
      {
        score: 4,
        severity: 1,
        name: 'blockers',
        label: 'Any blockers with Adobe?'
      },
      {
        score: 20,
        severity: 1,
        name: 'load_testing',
        label: 'Perform Load and Stress testing on Production instance before going live and share results with the CTA/CSE.',
        links: [
          {
            alt: 'load and stress test',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/develop/test/guidance.html?lang=en#:~:text=A%20load%20test%20can%20help,Scan%20Tool%20for%20your%20sites.'
          }
        ],
        check_description: `A load and stress test serves the purpose of identifying bottlenecks and uncovering performance issues within the application. It plays a crucial role in managing expectations regarding cluster size and determining the necessary scaling adjustments to meet the business requirements effectively.
        <p><span class="warning">WARNING:</span> When preparing a load test please do not send out live transaction emails (even to dummy addresses). Sending emails during testing can cause your project to reach the default send limit (12k) configured for SendGrid prior to launch. How to disable email communication:
              Go to <i>Store > Configuration > Advanced > System > Email Sending Settings</i></p>
        `,
      },
      {
        score: 4,
        severity: 3,
        name: 'penetration_test',
        label: 'Conduct security penetration testing on the production instance as part of the shared responsibility security model',
        links: [
          {
            alt: 'shared responsibility security model',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-operations/security-and-compliance/shared-responsibility#operational-responsibilities-summary'
          }
        ],
        check_description: `For PCI (Payment Card Industry) compliance, the customized site requires penetration testing.`,
      }
    ],
    itemDescription: ``
  },
  'other_configurations': {
    code: 'other_configurations',
    cardTitle: 'Other Configurations',
    checkboxList: [
      {
        score: 12,
        severity: 5,
        name: 'indexers',
        label: 'Switch indexing to “update on schedule”, except the customer_grid which remains on “SAVE”. Read more',
        links: [
          {
            alt: 'Read more',
            href: 'https://developer.adobe.com/commerce/php/development/components/indexing/'
          }
        ],
      },
      {
        score: 2,
        severity: 3,
        name: 'search_engine',
        label: 'Are you using a standard third-party search engine or extension?'
      },
      {
        score: 6,
        severity: 3,
        name: 'seo_setup',
        label: 'Confirm that SEO (Search Engine Optimization) configurations are properly set up to enable indexers/crawlers to scan the website, if relevant.',
        links: [
          {
            alt: 'Search Engine Optimization',
            href: 'https://experienceleague.adobe.com/docs/commerce-admin/marketing/seo/seo-overview.html?lang=en'
          }
        ]
      },
      {
        score: 2,
        severity: 3,
        name: 'routes_redirects',
        label: 'Add redirects and routes. Read more',
        links: [
          {
            alt: 'Read more',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/configure/routes/routes-yaml'
          }
        ],
        check_description: `Add redirects and routes to the routes.yaml file in the Integration environment and verify the configuration in this environment before deploying to Staging and Production.
              <pre>
              "http://&#123;all&#125;/":
              type: upstream
              upstream: "mymagento:http"

              "http://&#123;all&#125;/":
              type: upstream
              upstream: "mymagento:http"
              </pre>`
      },
      {
        score: 8,
        severity: 3,
        name: 'xdebug',
        label: 'Ensure XDebug is disabled if enabled during development.',
        links: [
          {
            alt: 'XDebug',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/develop/test/debug#debug-for-pro-staging-and-production'
          }
        ]
      },
      {
        score: 4,
        severity: 3,
        name: 'php_ini',
        label: 'Verify that op-cache and other configurations have been accurately updated in the php.ini file. Refer to this sample file',
        links: [
          {
            alt: 'sample file',
            href: 'https://github.com/magento/magento-cloud/blob/master/php.ini#L41'
          }
        ],
        check_description: `<p>We recommend keep <i>app/etc/config.php</i> and <i>app/etc/env.php</i> files excluded (blacklisted) from opcache configurations.</p>`
      },
      {
        score: 1,
        severity: 3,
        name: 'adobe_status',
        label: 'Subscribe to the Adobe Commerce status page.',
        links: [
          {
            alt: 'Subscribe',
            href: 'https://status.adobe.com/cloud/experience_cloud#/'
          }
        ]
      },
      {
        score: 1,
        severity: 3,
        name: 'nr_alerts',
        label: 'Subscribe to New Relic “Managed Alerts for Adobe Commerce” notification channels to monitor the given performance metrics.',
        links: [
          {
            alt: 'New Relic',
            href: 'https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/monitor/new-relic/new-relic-service.html'
          },
          {
            alt: 'Managed Alerts for Adobe Commerce',
            href: 'https://experienceleague.adobe.com/docs/commerce-knowledge-base/kb/support-tools/managed-alerts/managed-alerts-for-magento-commerce.html?lang=en'
          }
        ]
      }
    ],
    itemDescription: ``
  },
  'security': {
    code: 'security',
    cardTitle: 'Security',
    checkboxList: [
      {
        score: 12,
        severity: 3,
        name: 'security_scan',
        label: 'Setup the Adobe Commerce Security Scan.',
        check_description: `<p>Adobe Commerce Security Scan is a useful tool that helps discover outdated software versions, incorrect configuration, and potential malware on the site. Sign up, schedule it to run often, and make sure emails are sent to the right technical security contact.</p>

                <p>Complete this task during UAT. If you use the periodic scans option, be sure to schedule scans at low
                demand times. See the Security Scan page in the Adobe Commerce Account. You must log in to Adobe Commerce account to access the Security Scan.</p>`,
        links: [
          {
            alt: 'Adobe Commerce Security Scan',
            'href': 'https://experienceleague.adobe.com/docs/commerce-admin/systems/security/security-scan.html'
          }
        ]
      },
      {
        score: 2,
        severity: 3,
        name: 'admin_settings',
        label: 'Change default settings for the Adobe Commerce admin.'
      },
      {
        score: 1,
        severity: 3,
        name: 'admin_password',
        label: 'Change the admin password. (Configuring Admin Security)',
        links: [
          {
            alt: 'Configuring Admin Security',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-admin/systems/security/security-admin'
          }
        ]
      },
      {
        score: 10,
        severity: 3,
        name: 'admin_url',
        label: 'Change the admin URL.',
        links: [
          {
            alt: 'Change the admin URL.',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-admin/stores-sales/site-store/store-urls#use-a-custom-admin-url'
          }
        ],
      },
      {
        score: 2,
        severity: 3,
        name: 'admin_users',
        label: 'Remove any admin users no longer on the project.',
        links: [
          {
            alt: 'admin users',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-admin/systems/security/security-admin'
          }
        ],
      },
      {
        score: 1,
        severity: 3,
        name: 'admin_pw',
        label: 'Passwords for administrators configured. (see Admin Password Requirements)',
        links: [
          {
            alt: 'Admin Password Requirements',
            href: 'https://experienceleague.adobe.com/en/docs/commerce-admin/systems/security/security-admin#admin-password-requirements'
          }
        ],
      },
      {
        score: 8,
        severity: 3,
        name: 'admin_tfo',
        label: 'Configure two-factor authentication.',
        links: [
          {
            alt: 'two-factor authentication',
            href: 'https://developer.adobe.com/commerce/testing/functional-testing-framework/two-factor-authentication/'
          }
        ],
      },
    ],
    itemDescription: ``
  },
  'go_live': {
    code: 'go_live',
    cardTitle: 'Go Live',
    checkboxList: [],
    itemDescription: `<p>When it is time to cutover, please perform the following steps</p> (for more information, see <a href="https://experienceleague.adobe.com/en/docs/commerce-cloud-service/user-guide/launch/checklist" target="_blank">DNS Configurations</a>)
            <ol>
              <li>Access your DNS service and update A and CNAME records for each of the domains and hostnames:
                  <ol>
                    <li>Add CNAME record for www.yourdomain.com, pointing at prod.magentocloud.map.fastly.net</li>
                    <li>Set four A records for yourdomain.com, pointing at:
                        <pre>
                          151.101.1.124
                          151.101.65.124
                          151.101.129.124
                          151.101.193.124
                        </pre>
                    </li>
                  </ol>
              </li>
              <li>Change Adobe Commerce Base URLs to www.yourdomain.com</li>
              <li>Wait for the TTL time to pass, then restart web browser.</li>
              <li>Test the website.</li>
            </ol>

            <h4>If you have an issue blocking the go-live:</h4>
            <p>
              If you encounter any problems any issues preventing you from launching during the cutover, the fastest
              method to get proper timely support is to utilize the help desk and open a ticket with the reason “Unable
              to launch my store” and calling a hotline support number
              <a href="https://experienceleague.adobe.com/en/docs/commerce-knowledge-base/kb/how-to/adobe-commerce-p1-notification-hotline" target="_blank">see the list of Adobe Commerce P1 (Priority 1) hotline numbers</a>
            </p>
            <ul>
              <li>US Toll Free: (+1) 877 282 7436 (Direct to Adobe Commerce P1 hotline)</li>
              <li>US Toll Free: (+1) 800 685 3620 (At first menu, press 7 for Adobe Commerce P1 hotline)</li>
              <li>US Local: (+1) 408 537 8777</li>
            </ul>

`
  },
  'post_go_live': {
    code: 'post_go_live',
    cardTitle: 'Post Go-Live',
    checkboxList: [],
    itemDescription: `<p>Once the site is live, email the CTA (Customer Technical Advisory) and CSM (Customer Success Manager). The CTA will perform the following tasks as soon as the site
                is verified to be launched with Fastly enabled and caching:
                </p>
                <ul>
                  <li>Tag the cluster as live and create a support ticket to activate High SLA (Service Level Agreements) monitoring.</li>
                  <li>Activate New Relic Synthetics for uptime monitoring.</li>
                  <li>Enable Adobe Commerce Business Intelligence (for Commerce Pro Only).</li>
                </ul>
`
  },
  'signatures': {
    code: 'signatures',
    cardTitle: 'Confirm You Have Reviewed the Checklist',
    checkboxList: [],
    itemDescription: `<h4>Merchant / SI (systems integrators)</h4>
                        <p>Please sign below that you understand that this checklist contains best practices and you
              understand the importance of completing these for the most successful launch.</p>
                        <h5>Make sure you have fully prepared "go live readiness plan" with the Partner/SI. The pre-launch checklist only covers the best practices from the Adobe side</h5>

                      <h4>CTA/CSE</h4>
                      <p>Sign below that you have reviewed this checklist with your Merchant / SI during the onboarding call. Ensuring launch readiness and managing the site launch is the sole responsibility of the client. By signing this document, the CTA is not assuming responsibility for the success of the launch.</p>
`
  },
};
