import { useEffect, useState, useRef } from "react";
import { useStateValue } from "state/index";
import { findProduct, getProductSearchResults } from "../queries";
import { setProductSearchResults, setIsLoading } from "../actions";
import { onException } from "../../httpStatusHandler";
import axios from "axios";

let checksToRun = [
  "adobe-commerce-product-stock-status",
  "adobe-commerce-product-status",
  "adobe-commerce-category-permission",
  "adobe-commerce-exporter-db-product-stock-status",
  "adobe-commerce-exporter-db-product-visibility",
  "adobe-commerce-exporter-db-product-displayable",
  "adobe-commerce-exporter-db-product-status",
  "adobe-commerce-exporter-db-product-overrides-displayable",
  "livesearch-catalog-service-displayable",
  "livesearch-catalog-service-stock-status",
  "livesearch-product-information",
  "prex-elasticsearch-product-by-sku-displayable",
  "prex-elasticsearch-product-by-sku-visibility",
  "prex-elasticsearch-product-by-sku-override-displayable",
  "prex-elasticsearch-product-by-sku-stock-status",
];

function createChecksFilter(args) {
  let checksFilter = {};
  checksToRun.forEach((check) => {
    checksFilter[check] = args;
  });
  return JSON.stringify(checksFilter);
}

export const useProductSearch = () => {
  let [state, dispatch] = useStateValue();
  let [error, setError] = useState(null);
  let clientPrefixJobLabel =
    state.dashboard.client.hash +
    "_" +
    state.dashboard.client.environment +
    "_" +
    "productSearchJobId";
  let [jobId, setJobId] = useState(localStorage.getItem(clientPrefixJobLabel));
  let fetchCount = 0;
  let cancelToken = useRef();

  useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    if (jobId) {
      getProductData();
    }
    return () => {
      if (cancelToken.current) cancelToken.current.cancel();
      if (state.liveSearch.productSearchResults) dispatch(setProductSearchResults({}));
      if (state.liveSearch.isLoading) dispatch(setIsLoading(false));
    };
  }, [jobId]);

  const productSearch = async (sku, storeCodes) => {
    dispatch(setIsLoading(true));
    let storeCodesParsed = JSON.parse(storeCodes);
    const args = {
      SKU: sku,
      STORE_VIEW_CODE: storeCodesParsed["store_view_code"],
      STORE_CODE: storeCodesParsed["store_code"],
      WEBSITE_CODE: storeCodesParsed["website_code"],
    };
    const data = {
      checksFilter: createChecksFilter(args)
    };
    try {
      const response = await findProduct(data);
      if (response.data.jobIds) {
        localStorage.setItem(
          clientPrefixJobLabel,
          JSON.stringify(response.data.jobIds)
        );
        setJobId(response.data.jobIds);
        window.dispatchEvent(new Event("productSearchJobIdSet"));
      }
    } catch (error) {
      setError(error);
      dispatch(setIsLoading(false));
      onException(error, dispatch, state);
    }
  };

  const cancelSearch = async () => {
    if (cancelToken.current) cancelToken.current.cancel();
    localStorage.removeItem(clientPrefixJobLabel);
    setJobId(null);
    dispatch(setIsLoading(false));
  };

  const getProductData = async () => {
    if (localStorage.getItem(clientPrefixJobLabel)) {
      dispatch(setIsLoading(true));
      try {
        await getProductSearchResults(
          {
            jobIds: localStorage.getItem(clientPrefixJobLabel),
          },
          cancelToken.current.token
        ).then((response) => {
          processResponseData(response.data);
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
          dispatch(setIsLoading(false));
          onException(error, dispatch, state);
        }
      }
    } else {
      dispatch(setIsLoading(false));
    }
  };

  async function sleep(ms) {
    await new Promise((resolve) => setTimeout(resolve, ms));
  }

  const processJobError = (data) => {
    setError(data["error_text"]);
    dispatch(setIsLoading(false));
    localStorage.removeItem(clientPrefixJobLabel);
    onException({ response: { status: 500 } }, dispatch, state);
  };

  const processNonExistingSku = () => {
    dispatch(setIsLoading(false));
    localStorage.removeItem(clientPrefixJobLabel);
    onException(
      {
        response: {
          status: 500,
          data: {
            error:
              "The SKU that was requested does not exist. Verify the SKU and try again.",
          },
        },
      },
      dispatch,
      state
    );
  };

  const processJobExecuted = (data) => {
    let skuDoesNotExistMessage =
      "The product that was requested doesn't exist. Verify the product and try again.";
    if (data.data) {
      if (
        data.data["adobe-commerce-product-stock-status"] &&
        data.data["adobe-commerce-product-stock-status"]["value"] ===
          skuDoesNotExistMessage
      ) {
        processNonExistingSku();
        return false;
      }
      dispatch(setProductSearchResults(data.data));
      dispatch(setIsLoading(false));
      return true;
    }
    return false;
  };

  const processResponseData = (responseData) => {
    let shouldFetch = true;

    if (responseData && responseData.length > 0) {
      if (typeof responseData[0] === "object" && responseData[0] !== null) {
        for (const [job, data] of Object.entries(responseData[0])) {
          const { status } = data;
          if (status === "error") {
            processJobError(data);
            shouldFetch = false;
            break;
          } else if (status === "persisted") {
            const foundExecutedJobWithData = processJobExecuted(data);
            shouldFetch = !foundExecutedJobWithData;
            if (foundExecutedJobWithData) break;
          }
        }
      }
    } else {
      shouldFetch = false;
    }

    if (shouldFetch && fetchCount < 120) {
      fetchCount++;
      dispatch(setIsLoading(true));
      sleep(1000).then(getProductData);
    } else if (fetchCount >= 119) {
      dispatch(setIsLoading(false));
      localStorage.removeItem(clientPrefixJobLabel);
      onException({ response: { status: 500 } }, dispatch, state);
    }
  };

  return {
    productSearchResults: state.liveSearch.productSearchResults,
    isLoading: state.liveSearch.isLoading,
    error: error,
    productSearch,
    getProductData,
    cancelSearch,
  };
};
