import React from "react";
import { useEffect } from "react";
import {
  Button,
  DialogTrigger,
  Flex,
  Form,
  View,
  ProgressCircle,
  Heading,
  Divider,
} from "@adobe/react-spectrum";
import { useForm } from "react-hook-form";
import StaticFields from "./staticFields";
import DynamicFields from "./dynamicFields";
import PopUpDialog from "./popUpDialog";
import useFormSubmit from "../../../state/supportCasesTab/hooks/useFormSubmit";
import useSupportCasesTab from "../../../state/supportCasesTab/hooks/useSupportCasesTab";

function SubmitCaseForm({ issueDescription }) {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  let [loading, responseError, response, submitForm] = useFormSubmit();
  let [formFields, isLoading, error, request] = useSupportCasesTab(issueDescription);

  useEffect(() => {
    if (formFields.length === 0) {
      request();
    }
  }, [formFields]);

  const onSubmit = (data) => {
    submitForm(data);
  };

  if (isLoading || formFields.length === 0) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate />;
  } else if (error) {
    return <div>Something went wrong! Try refreshing the page.</div>;
  }

  return (
    <>
      <Heading level={3}>Submit a Support Case</Heading>
      <Divider size={"M"} marginBottom={"size-100"} />
      <Form onSubmit={handleSubmit(onSubmit)} width={"50%"}>
        <Flex direction={"row"} gap={"size-200"}>
          <View width={"60%"} UNSAFE_className={"staticFields"}>
            <StaticFields
              control={control}
              errors={errors}
              fields={formFields}
              issueDescription={issueDescription}
            />
          </View>
          <View width={"40%"}>
            <DynamicFields
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
              fields={formFields}
            />
          </View>
        </Flex>
        <DialogTrigger>
          <Flex direction={"column"} alignItems={"center"}>
            <Button
              type={"submit"}
              variant="cta"
              style="fill"
              marginTop={"size-300"}
              marginBottom={"size-1000"}
              data-ui-id={"case-submission-case-submit"}
            >
              Submit Case
            </Button>
          </Flex>
          <PopUpDialog
            errors={errors}
            ticketId={response}
            isLoading={loading}
            responseError={responseError}
          />
        </DialogTrigger>
      </Form>
    </>
  );
}

export default SubmitCaseForm;
