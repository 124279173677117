import React, { useEffect, useState } from "react";
import defaultType from "./defaultType";
import Collapsible from "../collapsible";
import { ProgressCircle, Text } from "@adobe/react-spectrum";
import { useStateValue } from "state/index";

const productSearchResultType = ({ section, sectionIndex }) => {
  let [state] = useStateValue();
  let [isSectionItemsSet, setIsSectionItemsSet] = useState(false);

  const setSectionData = (productSearchResults) => {
    section.items = [];
    Object.entries(productSearchResults).forEach(([key, value]) => {
      if (section["check_codes"] && section["check_codes"].includes(key)) {
        let data = {
          title: value.label,
          frontend_component: value.frontend_component,
          data: value.value,
          violation: value.violation,
        };
        section.items.push(data);
      }
    });
    if (section.items.length > 0) {
      setIsSectionItemsSet(true);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setIsSectionItemsSet(false);
    };
    window.addEventListener("productSearchJobIdSet", handleStorageChange);
  }, []);

  useEffect(() => {
    if (Object.keys(state.liveSearch.productSearchResults).length > 0) {
      setSectionData(state.liveSearch.productSearchResults);
    }
  }, [state.liveSearch.productSearchResults]);

  if (isSectionItemsSet) {
    return defaultType({ section, sectionIndex});
  }

  return (
    <div key={sectionIndex}>
      <Collapsible
        title={section.title}
        tooltipMessage={section.contextualHelp}
        isOpen
      >
        {state.liveSearch.isLoading ? (
          <ProgressCircle aria-label="Loading..." isIndeterminate />
        ) : (
          <Text>No data collected</Text>
        )}
      </Collapsible>
    </div>
  );
};

export default productSearchResultType;
