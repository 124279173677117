import React from "react";
import {Flex, View} from "@adobe/react-spectrum";
import {TableView} from "@react/react-spectrum/TableView";
import ListDataSource from "@react/react-spectrum/ListDataSource";
import "./assets/servicesTable.css";

const servicesTable = ({item}) => {

  let tableData = [];

  class TableDataSource extends ListDataSource {
    load(sortDescriptor) {
      if (sortDescriptor && Array.isArray(tableData)) {
        tableData.sort(
          (a, b) => (a[sortDescriptor?.column?.key] > b[sortDescriptor?.column?.key])
            ? sortDescriptor?.direction : -sortDescriptor?.direction
        );
      }

      return tableData;
    };
  }

  const renderCell = (column, data) => {
    let text = data[column.key];
    let cssClass = "";
    if (column.key === "Status") {
      if (text.startsWith("Up to date")) {
        cssClass = "status-active";
      } else if (text.startsWith("Ending")) {
        cssClass = "status-ending";
      } else if (text.startsWith("Expired")) {
        cssClass = "status-expired";
      }
    }

    return <View>
      <span className={cssClass}> </span>
      <span style={{fontSize: "14px"}}>{text}</span>
    </View>;
  };

  const tableDataSource = new TableDataSource();

  const showColumns = [
    'Name',
    'Installed Ver.',
    'EOL Date',
    'Status'
  ];

  const tableView = item => {
    let data = JSON.parse(item.data);
    let columns = [];

    tableData = data;

    if (Array.isArray(data) && data.length > 0) {
      Object.keys(data[0]).forEach(column => {
        if (showColumns.includes(column)) {
          columns.push({
            title: column,
            key: column,
            minWidth: 150,
            align: "left",
            sortable: true,
            divider: false
          })
        }
      });
    }

    if (!columns.length) {
      return;
    }

    return <TableView
      columns={columns}
      dataSource={tableDataSource}
      renderCell={renderCell}
      defaultSortDescriptor={{column: columns[0], direction: TableView.SORT_ASCENDING}}
      allowsSelection={false}
      className="services-grid"
      rowHeight={14}
      quiet={true}
    />;
  }


  return <>
    <Flex direction="row" height="size-4600">
      <View flexBasis="100%">{tableView(item)}</View>
    </Flex>
  </>
};
export default servicesTable;
