import React from 'react';
import CheckSection from "./CheckSection";
import {
  TableView,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell
} from '@adobe/react-spectrum';
class Security extends CheckSection {
  getRightSection() {
    let adminUrlConfigured = this.getInfoValue('backendUrl') ? this.getInfoValue('backendUrl').length > 0 : false;
    return (
      <div>
        <TableView>
          <TableHeader>
            <Column maxWidth={80}>Status</Column>
            <Column>Supporting Information</Column>
          </TableHeader>
          <TableBody>
            <Row>
              <Cell maxWidth={80}>{this.isRecommendationAppliedStatus('securityScan')}</Cell>
              <Cell>Security Scan is configured</Cell>
            </Row>
            <Row>
              <Cell maxWidth={80}>{this.getInfoIcon()}</Cell>
              <Cell>Admin User Count: {this.getInfoValue('general-admin-users-count') || '(No data available)'}</Cell>
            </Row>
          </TableBody>
        </TableView>
      </div>
    );
  }
}
export default Security;
