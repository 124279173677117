import React from "react";
import {
  AlertDialog,
  ProgressCircle,
  Text,
  Flex,
} from "@adobe/react-spectrum";
import styled from "styled-components";

const StyledSpan = styled.span`
  font-weight: bold;
  color: #0078d4;
`;

function PopUpDialog({ errors, ticketId, isLoading, responseError }) {
  //Refresh page on confirmation after ticket is submitted
  let onPrimaryAction = () => {
    window.location.reload();
  };

  if (Object.keys(errors).length > 0) {
    return (
      <AlertDialog
        variant="error"
        title="Missing Required Fields"
        primaryActionLabel="Return to Form"
      >
        <Flex direction={"column"} gap={"size-100"}>
          <Text>Please fill out missing fields or verify your input.</Text>
          <Text>Customer contact email must be a valid email.</Text>
          <Text>
            Affected URL and Commerce Cloud URL must be valid URLs and start
            with http:// or https://
          </Text>
        </Flex>
      </AlertDialog>
    );
  } else if (isLoading) {
    return (
      <AlertDialog
        variant="Information"
        title="Processing case submission"
        primaryActionLabel="Return to Form"
        isPrimaryActionDisabled
      >
        <ProgressCircle aria-label="Loading…" isIndeterminate />
        <Text marginStart={"size-300"}>
          Your case is being submitted. Please wait...
        </Text>
      </AlertDialog>
    );
  } else if (responseError && !ticketId) {
    return (
      <AlertDialog
        variant="warning"
        title="Error submitting case"
        primaryActionLabel="Return to Form"
      >
        There was an error submitting the form. Please try again.
      </AlertDialog>
    );
  } else {
    return (
      <AlertDialog
        variant="confirmation"
        title="Case Submitted"
        primaryActionLabel="Return to Form"
        onPrimaryAction={onPrimaryAction}
      >
        <Flex direction={"column"} gap={"size-100"}>
          <Text>
            Your Case <StyledSpan>{ticketId}</StyledSpan> has been submitted.
          </Text>
          <Text>Please keep this number for future reference.</Text>
          <Text>
            A member of our team will be in touch with you shortly via the email
            address you provided.
          </Text>
        </Flex>
      </AlertDialog>
    );
  }
}

export default PopUpDialog;
