import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { useStateValue } from "state";

const ClickableTooltip = ({ code, tooltipMessage, margin, color, iconColor, children }) => {
  const [{ contextHelp }] = useStateValue();

  const ref = React.useRef();
  const [isOpen, setIsOpen] = useState(false);

  const setOpen = (open, e) => {
    e.stopPropagation();
    setIsOpen(open);
  };

  const StyledPopover = withStyles({
    paper: {
      maxWidth: '400px',
      padding: '25px',
      color: '#505050',
      fontSize: '14px',
      boxShadow: '0px 1px 4px #0000001A',
      border: '1px solid #E1E1E1',
      lineHeight: '24px',
    },
  })(Popover);

  const getMessageByCodeFromContextHelp = code => {
    return contextHelp
      && Object.values(contextHelp).find((e) => e.code === code)
      && Object.values(contextHelp).find((e) => e.code === code).description;
  };

  const message = tooltipMessage ? tooltipMessage : getMessageByCodeFromContextHelp(code);
  return <>{message && <div style={{margin, color}} ref={ref}>
    <div onClick={(e) => setOpen(true, e)}
         style={{
           cursor: 'pointer',
           display: 'flex',
           alignItems: 'stretch',
           border: '0px',
           padding: '0',
           minWidth: '10px',
           color: iconColor
         }}
    >
      {React.cloneElement(children, {
        style: {
          cursor: "pointer"
        }
      })}
    </div>
    <StyledPopover
      id="simple-popover"
      open={isOpen}
      anchorEl={ref.current}
      onClose={(e) => setOpen(false, e)}
      disablePortal={false}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div dangerouslySetInnerHTML={{__html: message}}/>
    </StyledPopover>
  </div>}</>
};
export default ClickableTooltip;
