import React, {useEffect, useState} from 'react';
import usePreLaunchChecks from "state/cloudReports/hooks/usePreLaunchChecks";
import {savePreLaunchChecksAction} from "state/cloudReports/hooks/savePreLaunchChecks";
import SectionDNS from "./Sections/DNS";
import SectionDatabase from "./Sections/Database";
import SectionPreGoLive from "./Sections/PreGoLive";
import SectionFastly from "./Sections/Fastly";
import CheckSection from "./Sections/CheckSection";
import SectionDeployments from "./Sections/Deployments";
import Security from "./Sections/Security";
import SectionOther from "./Sections/Other";
import SectionCurrentConfigurations from "./Sections/CurrentConfigurations";
import {checks} from "./Checklist/checks";
import CheckScore from "./CheckScore/CheckScore";
import EmailSummary from "./Checklist/EmailSummary";
import DocGen from "./Checklist/DocGen";
import {AppProvider} from './CheckScore/AppContext';
import useAccess from "state/dashboard/hooks/useAccess";
import {
  Form,
  Grid,
  View,
  Flex,
  ProgressCircle,
  Heading,
  Button,
} from '@adobe/react-spectrum';
import ListDataSource from "@react/react-spectrum/ListDataSource";
import './prelaunch-checks.css';
import useDashboard from "state/dashboard/hooks/useDashboard";
import Save from '@spectrum-icons/workflow/SaveFloppy';

class PreLaunchDataSource extends ListDataSource {
  preLaunchChecks = [];
  async load() {
    return this.preLaunchChecks;
  }
}

const preLaunchDataSource = new PreLaunchDataSource();

const PreLaunchChecks = props => {
  const [preLaunchChecks, isLoading, request] = usePreLaunchChecks();
  const [isSaving, setIsSaving] = useState(false);
  const hasRole = useAccess();

  const [dashboard,,] = useDashboard();
  let sectCounter = 1;

  useEffect(() => {
    request();
  }, [dashboard.client.hash]);

  /** only the internal users can
   *  - edit the checkboxes
   *  - edit the notes
   *  - trigger internal only actions
   *  - save progress
   * @returns {*}
   */
  const isReadOnly = () => {
    return !hasRole('internalUser')
  }
  const getSectCnt = ()  => {
    return sectCounter++;
  }

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
  }

  const handleSubmit = (e) => {
    // Prevent the browser from reloading the page
    setIsSaving(true);
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());

    savePreLaunchChecksAction(formJson).then(response => {
      setIsSaving(false);
    }).catch(errorObject => {
      setIsSaving(false);
    });
  }

  return (
    <div>
      <AppProvider>
        <Form isRequired necessityIndicator="label" onSubmit={handleSubmit}>
          <div className="action-wrapper">
            <div>
              <CheckScore data={preLaunchChecks}></CheckScore>
              <Button isDisabled={isReadOnly()} className={'widgetSubmitButton'} variant={'cta'} type={'submit'} data-ui-id="uct-widget-run-upgrade-scan-button" >
                { !isSaving && <Save /> }
                { isSaving && <ProgressCircle size="S" aria-label="Loading…" isIndeterminate/> }
                &nbsp; Save the Current Progress
              </Button>
            </div>
          </div>
          <View overflow={"hidden"} UNSAFE_style={{color: "black"}}>
            <Heading level={1} marginTop={20} marginBottom={20}>Pre-Launch Checklist (Commerce Cloud)</Heading>
            <div className="section-wrapper">
              <div className={"alert-box left-section"}>
                <div>
                  <View>[BETA RELEASE - INTERNAL ONLY] PLEASE USE THE FEEDBACK FORM FOR YOUR INPUT ( Top Right Corner icon [...] )</View>
                </div>
                <div className={'hpara synopsis'}>
                  The following is a synopsis of the Adobe Commerce <a href="https://experienceleague.adobe.com/docs/commerce-cloud-service/user-guide/launch/overview.html" target="_blank">Site launch documentation</a> and <a href="https://experienceleague.adobe.com/en/docs/commerce-learn/tutorials/adobe-commerce-cloud/commerce-cloud-best-practices/pre-launch-checklist" target="_blank" title="Commerce Cloud Pre-Launch Checklist">Commerce Cloud Pre-Launch Checklist</a>
                </div>
                <div className={'hpara plan'}>
                  This checklist aims to assist in planning and executing a successful launch of the Adobe Commerce Cloud site. Collaborate with your system integrator for Adobe Commerce Cloud to ensure all configuration tasks and checklist items are completed and verified. If you encounter difficulties with any checklist items or have questions, please reach out to the designated Customer Technical Advisor or Customer Success Engineer.
                </div>
                <div className={'hpara plan'}>
                  <b>Potential Blockers:</b> Some checks are highlighted as a <span className="color-red">"Blocker"</span> as they may potentially block your go-live if not carefully reviewed.
                </div>
                <div
                  className="patches-callout patches-callout-warning"
                  style={{ margin: "0 0 20px 0" }}
                >
                  <div className={'hpara responsibility'}>
                    <b>IMPORTANT: </b>You accept responsibility for any adverse effects and associated risks to your Production launch schedule and ongoing site stability, if you fail to use and complete this checklist.
                  </div>
                </div>

              </div>
              { !isReadOnly() &&
                <div className="right-section card quick-actions">
                  <h2>Quick Actions (Internal Only)</h2>
                  <EmailSummary></EmailSummary>
                  <DocGen></DocGen>
                </div>
              }
            </div>
          </View>
          <Flex direction="row" gap="size-200" minHeight="700px" style={{justifyContent: "center"}}>
            <Grid
              areas={[
                'header  header',
                'sidebar content',
                'footer  footer'
              ]}
              rows={['size-1000', 'auto', 'size-1000']}
              gap="size-100">
              <View gridArea="content">
                    <SectionPreGoLive check={checks['schedule']} data={preLaunchChecks} dashboard={dashboard} cnt={getSectCnt()}/>
                    <SectionCurrentConfigurations check={checks['current']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <SectionFastly check={checks['fastly']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <SectionDNS check={checks['dns']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <SectionDatabase check={checks['database']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <SectionDeployments check={checks['deployments']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <CheckSection check={checks['testing']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <SectionOther check={checks['other_configurations']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <Security check={checks['security']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <CheckSection check={checks['go_live']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <CheckSection check={checks['post_go_live']} data={preLaunchChecks} cnt={getSectCnt()}/>
                    <CheckSection check={checks['signatures']} data={preLaunchChecks} cnt={getSectCnt()}/>
              </View>
            </Grid>
          </Flex>
        </Form>
      </AppProvider>
    </div>
  );
};

export default PreLaunchChecks;
export {PreLaunchDataSource};
