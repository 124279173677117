import React from 'react';
import CheckSection from "./CheckSection";
import {
  Heading,
  View,
  Flex,
  Content,
  ActionButton
} from '@adobe/react-spectrum';

import Copy from '@spectrum-icons/workflow/Copy';

class PreGoLive extends CheckSection {

  getCliCommand() {
    const { dashboard } = this.props;

    if (typeof (dashboard.client.environment) !== undefined) {
      return `magento-cloud ssh -e${dashboard.client.environment.toLowerCase()} -p${this.getInfoValue('projectID')}`;
    } else {
      return false;
    }
  }

  getRightSection() {
    return (
      <div>
        <Heading level={2} marginTop={30}>Project Information</Heading>
        <View
          borderWidth="thin"
          borderColor="dark"
          borderRadius="medium"
          padding="size-250"
          backgroundColor="white"
        >
          <Content>
            <Flex gap="size-125">
              <div className={'text-div project-id'}>
                <b>Project ID:</b> {this.getInfoValue('projectID')}
              </div>
              <ActionButton onClick={() => {navigator.clipboard.writeText(this.getInfoValue('projectID'))}} aria-label="Icon only">
                &nbsp; <Copy />
              </ActionButton>
            </Flex>
            <Flex gap="size-125">
              <div className={'text-div project-name'}>
                <b>Project Name:</b> {this.getInfoValue('clientName')}
              </div>
            </Flex>
            <Flex gap="size-125">
              <div className={'text-div cli-command'}>
                <b>SSH Access:</b> {this.getCliCommand()}
              </div>
              <ActionButton onClick={() => {navigator.clipboard.writeText(this.getCliCommand())}} aria-label="Icon only">
                &nbsp; <Copy />
              </ActionButton>
            </Flex>
          </Content>
        </View>
      </div>
    );
  }
}

export default PreGoLive;
