import React from 'react';
import Heading from '@react/react-spectrum/Heading';
import DismissButton from './recommendationDismissButton';
import './recommendationsDetail.css';

const RecommendationDetail = ({recommendation}) => {
  const subtitleStyles = {
    textTransform: "capitalize"
  };
  return (recommendation && <div className="spectrum-Table--quiet" style={{ width: "100%", alignItems: 'center', fontSize: "14px"}}>
    <div role="rowgroup" className="react-spectrum-TableView-row spectrum-Table-head" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
      <div className="spectrum-Table-headCell" style={{ flex: 1 }}>Detail: {recommendation.title}</div>
      <div className="spectrum-Table-headCell"><DismissButton recommendation={recommendation} /></div>
    </div>
    <div className="spectrum-Table-body react-spectrum-TableView-body recommendationDetail" style={{borderBottom: "none", borderLeft: "none", borderRight: "none"}}>
      <div style={{border: 'none', borderBottom: 'none'}}>
        {
          recommendation.recommendation_status === 2 && <div className="patches-callout patches-callout-info">
            <strong>This recommendation was dismissed.</strong>
            <p>Reason: {recommendation.dismissed_reason ?? <em>None provided.</em>}</p>
            <p>Dismissed by <strong>{recommendation.dismissed_user_name ?? "Unknown user"}</strong> at <strong>{recommendation.dismissed_time ?? "an unknown time"}</strong> (UTC).</p>
          </div>
        }
        <div className="spectrum-Table-cell react-spectrum-Table-cell" style={{opacity: recommendation.recommendation_status === 2 ? .5 : 1}}>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Description</Heading>
            <span dangerouslySetInnerHTML={{
              __html: recommendation.issue_description
            }}/>
          </div>
          <div>
            <Heading variant="subtitle3" style={subtitleStyles} level={3}>Recommendation</Heading>
            <span
              dangerouslySetInnerHTML={{
                __html: recommendation.recommendation
              }}/>
          </div>
          {
            recommendation.root_cause && <div>
              <Heading variant="subtitle3" style={subtitleStyles} level={3}>Root Cause</Heading>
              <span
                dangerouslySetInnerHTML={{
                  __html: recommendation.root_cause
                }}/>
            </div>
          }
          {
            recommendation.scenario_preconditions && <div>
              <Heading variant="subtitle3" style={subtitleStyles} level={3}>Scenario/Preconditions</Heading>
              <span
                dangerouslySetInnerHTML={{
                  __html: recommendation.scenario_preconditions
                }}/>
            </div>
          }
          {
            recommendation.site_impact && <div>
              <Heading variant="subtitle3" style={subtitleStyles} level={3}>Site Impact</Heading>
              <span
                className={'site-impact'}
                dangerouslySetInnerHTML={{
                  __html: recommendation.site_impact
                }}/>
            </div>
          }
          {
            recommendation.expected_result && <div>
              <Heading variant="subtitle3" style={subtitleStyles} level={3}>Expected Result</Heading>
              <span
                dangerouslySetInnerHTML={{
                  __html: recommendation.expected_result
                }}/>
            </div>
          }
          {
            recommendation.tools_used && <div>
              <Heading variant="subtitle3" style={subtitleStyles} level={3}>Tools Used</Heading>
              <span
                dangerouslySetInnerHTML={{
                  __html: recommendation.tools_used
                }}/>
            </div>
          }
          {
            recommendation.referenced_pages && <div>
              <Heading variant="subtitle3" style={subtitleStyles} level={3}>Referenced Pages</Heading>
              <span
                dangerouslySetInnerHTML={{
                  __html: recommendation.referenced_pages
                }}/>
            </div>
          }
        </div>
      </div>
    </div>
  </div>);
};

export default RecommendationDetail;
