import React, {useEffect} from 'react';
import { TabView, Tab } from '@react/react-spectrum/TabView';
import {Divider, Flex} from '@adobe/react-spectrum'


import styled from 'styled-components';

import WidgetTab from 'views/widgetTab';
import ExceptionsTab from 'views/exceptionsTab';
import ExtensionsTab from 'views/extensionsTab';
import AlertsTab from "views/alertsTab";
import PatchesTab from 'views/patchesTab';
import InformationTab from 'views/informationTab';
import OutagesTab from 'views/outagesTab';
import SupportCasesTab from 'views/supportCasesTab';
import RecommendationsTab from 'views/recommendationsTab';
import SplashPage from 'views/splashPage';
import MaintenancePage from 'views/maintenancePage';
import UnauthorizedPage from 'views/unauthorizedPage';
import Header from './components/header';
import Footer from './components/footer';
import Loader from './components/loader';

import ClickableTooltip from "views/base/components/clickableTooltip";
import useDashboard from "state/dashboard/hooks/useDashboard";
import useContextHelp from "state/contextHelp/hooks/useContextHelp";
import {useHistory, useLocation} from "react-router-dom";
import { useParams } from 'react-router';
import HelpOutline from "@spectrum-icons/workflow/HelpOutline";
import useAccess from "state/dashboard/hooks/useAccess";
import useClientAccess from "state/dashboard/hooks/useClientAccess";
import ImsErrorPage from "./components/imsError";
import Gainsight from "./components/gainsight";
import CommerceServicesTab from "../commerceServicesTab";
import CloudReportsTab from "../cloudReportsTab";

const Main = styled.main`
  padding: 0 40px;
  background-color:#F5F5F5;
`;

const Base = () => {
  const location = useLocation();
  let history = useHistory();
  const { context, tab } = useParams();
  const hasRole = useAccess();
  const hasClientAccess = useClientAccess();
  const [,, requestContextHelp] = useContextHelp();
  const [dashboard, isLoading, getDashboardData] = useDashboard();

  useEffect(() => {
      getDashboardData(location.search, requestContextHelp);
    }, [dashboard.displaySplashPage, dashboard.displayMaintenancePage, dashboard.displayUnauthorizedPage]
  );

  let isSelected = (index) => index === tab || !tab && index === 'main';

  const tabIdToCode = {
    0: "main",
    1: "information",
    2: "recommendations",
    3: "exceptions",
    4: "extensions",
    5: "alerts",
    6: "patches",
    7: "outages",
    8: "supportCases",
    9: "commerceServices",
    10: "cloudReports"
  };
  if (context) {
    sessionStorage.setItem('context', context);
  }
  let redirectToAfterAuth = localStorage.getItem('redirectToAfterAuth')
  if (redirectToAfterAuth && history.location.pathname !== redirectToAfterAuth) {
    localStorage.removeItem('redirectToAfterAuth')
    history.push(redirectToAfterAuth);
  }

  if (isLoading) {
    return <Loader/>;
  }
  if (dashboard.displayUnauthorizedPage) {
    return <UnauthorizedPage/>;
  }
  if (dashboard.displayMaintenancePage) {
    return <MaintenancePage/>;
  }
  if (dashboard.displaySplashPage) {
    return <SplashPage/>;
  }
  if (dashboard.imsErrorCode !== '00000') {
    return <ImsErrorPage/>;
  }
  const LabelWithHelp = ({title, code}) => {
    return <span>
      {title}
      <ClickableTooltip
        color="#6e6e6e"
        margin="0 10px"
        code={code}
      >
        <HelpOutline size="XS"/>
      </ClickableTooltip>
    </span>
  };

  const isVisible = (name) => {
    return dashboard.menu && dashboard.menu.hasOwnProperty(name);
  }

  return <>
      <Flex direction={'column'} gap={'size-125'} UNSAFE_style={{backgroundColor: '#F5F5F5'}}>
        <Header />
        <Divider size={'M'}/>
        <Main>
          <TabView className="mainTabNav" onSelect={(tabId) => history.push('/commerce/tab/' + tabIdToCode[tabId])}>
            <Tab selected={isSelected('main')} label={<LabelWithHelp title="Dashboard" code="helpWidget"/>} data-ui-id="main-tab">
              <WidgetTab />
            </Tab>
            <Tab selected={isSelected('information')} label={<LabelWithHelp title="Information" code="helpInformation"/>} data-ui-id="information-tab">
              <InformationTab />
            </Tab>
            <Tab selected={isSelected('recommendations')} label={<LabelWithHelp title={"Recommendations (" + dashboard.counts.recommendations + ")"} code="helpRecommendations"/>} data-ui-id="recommendations-tab">
              <RecommendationsTab />
            </Tab>
            <Tab selected={isSelected('exceptions')} label={<LabelWithHelp title={"Exceptions (" + dashboard.counts.exceptions + ")"} code="helpExceptions"/>} data-ui-id="exceptions-tab">
              <ExceptionsTab />
            </Tab>
            <Tab selected={isSelected('extensions')} label={<LabelWithHelp title={"Extensions (" + dashboard.counts.extensions + ")"} code="helpExceptions"/>} data-ui-id="extensions-tab">
              <ExtensionsTab />
            </Tab>
            {
              hasClientAccess('magento-commerce-cloud') && <Tab selected={isSelected('alerts')} label={<LabelWithHelp title={"Alerts (" + dashboard.counts.alerts + ")"} code="helpAlerts"/>} data-ui-id="alerts-tab">
                <AlertsTab />
              </Tab>
            }
            <Tab selected={isSelected('patches')} label={<LabelWithHelp title={"Patches (" + dashboard.counts.patches + ")"} code="helpPatches"/>} data-ui-id="patches-tab">
              <PatchesTab />
            </Tab>
            {
              hasRole("internalUser") && <Tab selected={isSelected('outages')} label={<LabelWithHelp title={"Outages (" + dashboard.counts.outages + ")"} code="helpOutages"/>} data-ui-id="outages-tab">
                <OutagesTab />
              </Tab>
            }
            {
              hasRole("internalUser") && <Tab selected={isSelected('supportCases')} label={<LabelWithHelp title={"Support Cases"} code="helpCases"/>}>
                <SupportCasesTab />
              </Tab>
            }
            {
              isVisible("commerce_services") && <Tab selected={isSelected('commerceServices')} label={<LabelWithHelp title={"Commerce Services"} code="helpCommerceServices"/>}>
                <CommerceServicesTab />
              </Tab>
            }
            {
              hasRole("internalUser")  && <Tab selected={isSelected('cloudReportsTab')} label={<LabelWithHelp title={"Pre-Launch Checklist"} code="cloudReportsTab"/>}>
                <CloudReportsTab />
              </Tab>
            }
          </TabView>
        </Main>
      </Flex>
      <Footer />
      <Gainsight/>
    </>
};

export default Base;
