import { useState } from "react";
import { submitSupportCase } from "../queries";

function useFormSubmit() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const [response, setResponse] = useState(null);

  async function submitForm(data) {
    try {
      setIsLoading(true);
      const response = await submitSupportCase(data);
      setResponse(response.data);
    } catch (error) {
      setResponseError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return [isLoading, responseError, response, submitForm];
}

export default useFormSubmit;
