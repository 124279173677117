import React from 'react';
import PatchesGrid from './components/patchesGrid';

const PatchesTab = () => {
  return (
    <div className={'patchesTab'}>
      <PatchesGrid/>
    </div>
  );
}

export default PatchesTab;
