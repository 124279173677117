import {getConfig} from 'config';
import {getData} from '../dataProvider';

export const acceptSplash = () => {
  return getData(`${getConfig('acceptSplash')}`);
};

export const cancelSplash = () => {
  return getData(`${getConfig('cancelSplash')}`);
};
