import {SET_PRODUCT_SEARCH_RESULTS, SET_IS_LOADING} from "./actions";

export const INITIAL_STATE = {
  productSearchResults: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRODUCT_SEARCH_RESULTS:
      return {
        ...state,
        productSearchResults: action.payload,
      };
      case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}
