import React from "react";
import DatePickerController from "../inputFields/datePicker";
import PickerController from "../inputFields/picker";

const minimumStartDate = new Date(new Date().setDate(new Date().getDate() + 2));

function createResizeStartTimes() {
  const times = [];
  for (let i = 0; i < 24; i++) {
    const time = i < 10 ? "0" + i : i;
    times.push({
      name: `${time}:00 UTC`,
      value: `restart_time_${time}_00_utc`,
    });
  }
  return times;
}

const resizeStartTimes = createResizeStartTimes();

function SurgeTemporaryCapacityRequestField({
  errors,
  control,
  setValue,
  infrastructureContactReason,
  fields,
}) {
  const requestStartDate = fields.requestStartDate;
  const requestStartTime = fields.requestStartTime;
  const requestEndDate = fields.requestEndDate;

  if (
    infrastructureContactReason ===
      "com_cr_infra_holiday_surge_capacity_request" ||
    infrastructureContactReason ===
      "com_cr_infra_temporary_cloud_project_upsize_vcpu"
  ) {
    return (
      <>
        <DatePickerController
          required={true}
          label={requestStartDate.label}
          validationState={errors[requestStartDate.name] ? "invalid" : null}
          minDate={minimumStartDate}
          name={requestStartDate.name}
          control={control}
          setValue={setValue}
        />
        <PickerController
          required={true}
          label={requestStartTime.label}
          validationState={errors[requestStartTime.name] ? "invalid" : null}
          name={requestStartTime.name}
          items={resizeStartTimes}
          control={control}
          setValue={setValue}
        />
        <DatePickerController
          required={true}
          label={requestEndDate.label}
          validationState={errors[requestEndDate.name] ? "invalid" : null}
          minDate={minimumStartDate}
          name={requestEndDate.name}
          control={control}
          setValue={setValue}
        />
      </>
    );
  }
  return null;
}

export default SurgeTemporaryCapacityRequestField;
