import React, {useState, useEffect} from 'react';
import {TableView} from '@react/react-spectrum/TableView';
import ListDataSource from "@react/react-spectrum/ListDataSource";
import {Flex, ProgressCircle, View} from "@adobe/react-spectrum";
import './assets/alertsGrid.css';
import useAlerts from "state/alerts/hooks/useAlerts";
import FilterAlertsButton from "./filterAlertsButton";
import AlertDetails from "./alertDetails";
import useDashboard from "../../../state/dashboard/hooks/useDashboard";

class AlertsDataSource extends ListDataSource {
  items = [];
  initialItems = [];
  async load(sortDescriptor) {
    if (!this.initialItems.length) {
      this.initialItems = this.items
    }
    if (sortDescriptor && sortDescriptor.column) {
      this.items.sort((a, b) => (a[sortDescriptor.column.key] > b[sortDescriptor.column.key]) ? sortDescriptor.direction : -sortDescriptor.direction)
    }

    return this.items;
  }

  getItems() {
    return this.items;
  }

  filterAlerts(selectedFilters) {
    let filters = [];
    filters['status'] = [];
    selectedFilters.forEach(filter => {
      const f = filter.split("|");
      filters[f[0]].push(f[1]);
    })
    this.items = this.initialItems.filter(function (item) {
      const hasStatus = filters['status'].includes(item.finished_at?'closed':'open')
        || filters['status'].length === 0;
      return (hasStatus);
    });
    this.reloadData();
    this.filterCallback(this.items.length);
  }
}

const columns = [
  {
    title: 'Incident Id',
    key: 'incident_id',
    minWidth: 100,
    sortable: true,
    divider: false
  },
  {
    title: 'Alert name',
    key: 'alert_description',
    minWidth: 500,
    sortable: false,
    divider: false
  },
  {
    title: 'Opened',
    key: 'started_at',
    minWidth: 200,
    sortable: true,
    divider: false
  },
  {
    title: 'Closed',
    key: 'finished_at',
    minWidth: 200,
    sortable: true,
    divider: false
  },
  {
    title: 'Status',
    key: 'status',
    minWidth: 100,
    sortable: true,
    divider: false
  }
];

const alertsDataSource = new AlertsDataSource();

const AlertsGrid = () => {
  const [alerts, oacLink, isLoading, request] = useAlerts();
  const [selectedAlert, setSelectedAlert] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [totalAlertCount, setTotalAlertCount] = useState(0);
  const [dashboard,,] = useDashboard();
  useEffect(() => {
    request();
  }, [dashboard.client]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate/>;
  }

  alertsDataSource.items = alerts;

  alertsDataSource.load().then(results => {
    if (results && results.length && selectedAlert === false) {
      setSelectedAlert(results[0]);
      setAlertCount(results.length);
      setTotalAlertCount(results.length);
      alertsDataSource.filterCallback = setAlertCount;
    }
  })

  const renderCell = (column, data)  => {
    let text = data[column.key];
    let cssClass = "";
    let paddingRight = "0px";
    let maxDescriptionLength = 150;

    if (column.key === "started_at" && text) {
      text = new Date(text).toUTCString();
    } else if (column.key === "finished_at" && text) {
      text = new Date(text).toUTCString();
    } else if (column.key === 'description') {
      paddingRight = "10px";
      if (text.length > maxDescriptionLength) {
        text = text.slice(0, maxDescriptionLength) + '...';
      }
    } else if (column.key === "status") {
      text = ''; //cleanup text to display only bullet
      if (data['finished_at']) {
        cssClass = "status-applied";
      } else {
        cssClass = "status-not-available";
      }
    }

    return <View paddingRight={paddingRight}>
      {cssClass && <span className={cssClass}> </span>}
      <span>{text}</span>
    </View>;
  };

  const onCellClick = (alertsDataSource)  => {
    return (column, rowIndex) => {
      setSelectedAlert(alertsDataSource.getItems()[rowIndex])
    }
  };

  const tableView = <TableView
    allowsSelection={false}
    className="alerts-grid"
    rowHeight={14}
    columns={columns}
    defaultSortDescriptor={{column: columns[2], direction: TableView.SORT_DESCENDING}}
    onCellClick={onCellClick(alertsDataSource)}
    dataSource={alertsDataSource}
    renderCell={renderCell}
    style={{borderBottom: "none"}}
    quiet={true}
  />;

  return <>
    {alerts && (alerts.length !== 0 ?
        <>
          <View marginBottom={"size-150"}>
            <Flex direction="row">
              {alerts.length === 20 && <span>
                Showing the latest 20 New Relic Alerts.  To view all the New Relic Alerts, go to the New Relic app.
              </span>}
              <span>You might also check <a target={'_blank'} href={oacLink}>Observation for Adobe Commerce.</a></span>
            </Flex>
          </View>
          <FilterAlertsButton alertCount={alertCount} totalAlertCount={totalAlertCount}/>
          <Flex direction="row" gap="size-200" minHeight="700px" style={{justifyContent: "center"}}>
            <View flexBasis="60%">{tableView}</View>
            <View flexBasis="40%"><AlertDetails alert={selectedAlert}/></View>
          </Flex>
        </>:
        <View minHeight={'700px'}><div className="alerts-callout alerts-callout-info">No alerts present at this time</div></View>
    )}
  </>
};

export default AlertsGrid;
export {alertsDataSource};
