import {Accordion, AccordionItem} from "@react/react-spectrum/Accordion";
import {Flex, Text, View} from "@adobe/react-spectrum";
import ClickableTooltip from "views/base/components/clickableTooltip";
import HelpOutline from "@spectrum-icons/workflow/HelpOutline";
import React from "react";

const AccordionHead = ({title, tooltipMessage}) => {
  return <Flex direction="row" justifyContent="space-between" width="100%" alignItems="center">
    {title}
    <ClickableTooltip
      color="#6e6e6e"
      iconColor="#B3B3B3"
      margin="0"
      tooltipMessage={tooltipMessage}
    >
      <HelpOutline size="XS"/>
    </ClickableTooltip>
  </Flex>;
};

const Collapsible = ({children, title, tooltipMessage, isOpen, minHeight = "0"}) => {
  if (isOpen) {
    isOpen = 0;
  }
  return <div className="collapsible" style={{ minHeight: minHeight }}>
    <Flex direction="row" height="size-8000" justifyContent="space-between" columnGap="16px" width="100%">
      <View width="100%">
        <Accordion defaultSelectedIndex={isOpen}>
          <AccordionItem header={<AccordionHead title={title} tooltipMessage={tooltipMessage}/>}>
            {children}
          </AccordionItem>
        </Accordion>
      </View>
    </Flex>
  </div>
};

export default Collapsible;
