import React from 'react';
import {Accordion, AccordionItem} from "@react/react-spectrum/Accordion";
import {Divider, Text, View, Heading, Flex} from '@adobe/react-spectrum';
import {Cell, Column, Row, TableView, TableBody, TableHeader} from '@adobe/react-spectrum'

const MostActiveIps = ({incidentInfo, outageIssues}) => {
  let incidentMostActiveIps = null;

  if(outageIssues[incidentInfo[0]] === undefined || outageIssues[incidentInfo[0]]['outageMostActiveIps'] === undefined) {
    return (
      <View width={'100%'} marginBottom={'size-300'}>
        <Heading level={3}>Most active ip addresses</Heading>
        <Divider size={'M'} marginBottom={'size-100'}/>
        <Text>No data available for this specific incident</Text>
      </View>
    );
  }

  let issueData = outageIssues[incidentInfo[0]]['outageMostActiveIps'];

  incidentMostActiveIps = issueData.map((item) =>
    <Row>
      <Cell>{item['facet'][0]}</Cell>
      <Cell>{item['count']}</Cell>
      <Cell>{item['overallPercentage']}%</Cell>
      <Cell>{item['facet'][1]}</Cell>
    </Row>
  );

  return (
    <Accordion>
      <AccordionItem header={'Most Active IPs'}>
        <View marginBottom={'size-300'}>
          <Text>The following IPs where the most active during the hour leading to the outage.</Text>
          <Flex UNSAFE_style={{overflow: 'hidden'}} direction={'column'}>
            <TableView isQuiet={true} UNSAFE_style={{margin: '0 -1rem'}}>
              <TableHeader>
                <Column width={'15%'}>IP ADDRESS</Column>
                <Column align={'center'} width={'10%'}>RPH</Column>
                <Column align={'center'} width={'15%'}>Overall</Column>
                <Column width={'60%'}>User Agent</Column>
              </TableHeader>
              <TableBody>
                {incidentMostActiveIps}
              </TableBody>
            </TableView>
          </Flex>
        </View>
      </AccordionItem>
    </Accordion>
  );
};

export default MostActiveIps;
